import Vue from 'vue'
import Vuex from 'vuex'
import { fetchPermission } from "../api/index"
import router, { DynamicRoutes } from "../router/index"
import dynamicRouter from "../router/dynamic-router"
import { recursionRouter, setDefaultRoute } from "../utils/recursion-router"


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isSidebarNavCollapse: "1", //存放的键值对就是所要管理的状态(全局可以访问的state对象)
  },
  mutations: {
    // 修改状态的方法
    toggleNavCollapse (state, msg) {
      // //自定义改变state初始值的方法，这里面的参数除了state之外还可以再传额外的参数(变量或对象);
      state.isSidebarNavCollapse = !state.isSidebarNavCollapse;
    },
    SET_PERMISSION (state, routes) {
      state.permissionList = routes;
    },
    CLEAR_PERMSSION (state) {
      state.permissionList = null;
    },
    SET_MENU (state, menu) {
      state.sidebarMenu = menu;
    },
    CLEAR_MENU (state) {
      state.sidebarMenu = []
    },
    LOGIN_IN (state, token) {
      state.UserToken = token;
    },
    LOGIN_OUT (state) {
      state.UserToken = ""
    }
  },
  getters: {},
  // 异步访问
  actions: {
    // async FETCH_PERMISSION ({ commit, state }) {
    //   let permissionList = await fetchPermission();
    //   // 筛选
    //   let routes = recursionRouter(permissionList.data, dynamicRouter);
    //   let MainContainer = DynamicRoutes.find(v => v.path === "");
    //   let children = MainContainer.children;
    //   children.push(...routes)
    //   // 生成菜单
    //   commit("SET_MENU", children);
    //   // 设置默认路由
    //   console.log(MainContainer);
    //   setDefaultRoute([MainContainer]);
    //   // 初始化路由
    //   let initialRoutes = router.options.routes;
    //   router.addRoutes(DynamicRoutes);
    //   commit("SET_PERMISSION", [...initialRoutes, ...DynamicRoutes])
    // }
    //   }
  }
})


// export default new Vuex.Store({ state, mutations, actions })
