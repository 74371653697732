<template>
  <div>
    <div class="useterm_list">
      <p>感谢您访问 zzyjzkj.com（以下简称“本网站”）。</p>
      <p>
        为切实保护本网站用户隐私权，优化用户体验，本网站根据现行法规及政策，制定本《个人信息保护政策》。
        本《个人信息保护政策》将详细说明本网站在获取、管理及保护用户个人信息方面的政策及措施。本《个人信息保护政策》适用于本网站向您提供的所有服务，无论您是通过计算机设备、移动终端或其他设备获得的本网站服务。
      </p>
    </div>
    <div class="useterm_list">
      <p class="useterm_tit">个人信息的收集</p>
      <p class="useterm_txt">
        您已知悉且同意，在您注册本网站帐号或使用平台提供的服务时，本网站将记录您提供的相关个人信息，如：昵称、邮箱、手机号码等，上述个人信息是您获得平台提供服务的基础。
      </p>
    </div>

    <div class="useterm_list">
      <p class="useterm_tit">个人信息的管理</p>
      <p class="useterm_txt">
        为了向您提供更好的服务或产品，本网站会在下述情形使用您的个人信息:
      </p>
      <p>（1）根据相关法律法规的要求；</p>
      <p>（2）根据您的授权；</p>
      <p>
        （3）根据本网站相关服务条款、应用许可使用协议的约定。
        未经您本人允许，本网站不会向任何第三方披露您的个人信息，下列情形除外：
      </p>
      <p>（1）本网站已经取得您或您监护人的授权；</p>
      <p>（2）司法机关或行政机关给予法定程序要求本网站披露的；</p>
      <p>（3）本网站为维护自身合法权益而向用户提起诉讼或仲裁时；</p>
      <p>（4）根据您与本网站相关服务条款、应用许可使用协议的约定；</p>
      <p>（5）法律法规规定的其他情形。</p>
    </div>

    <div class="useterm_list">
      <p class="useterm_tit">个人信息的管理</p>
      <p>
        本网站将尽一切合理努力保护其获得的用户个人信息。为防止用户个人信息在意外的、未经授权的情况下被非法访问、复制、修改、传送、遗失、破坏、处理或使用，本网站已经并将继续采取以下措施保护您的个人信息：
      </p>
      <p>（1）以适当的方式对用户的个人信息进行加密处理；</p>
      <p>（2）在适当的位置使用密码对用户个人信息进行保护；</p>
      <p>（3）限制对用户个人信息的访问；</p>
      <p>（4）其他的合理措施。</p>
      <p>
        尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，本网站仍然无法保证您的个人信息通过不安全途径进行交流时的安全性。因此，用户个人应采取积极措施保证个人信息的安全，如：定期修改帐号密码，不将自己的帐号密码等个人信息透露给他人。
        您知悉：本网站提供的个人信息保护措施仅适用于本网站，一旦您离开本网站，浏览或使用其他网站、服务及内容资源，本网站即没有能力及义务保护您在平台以外的网站提交的任何个人信息，无论您登录或浏览上述网站是否基于本网站的链接或引导。
      </p>
      <div class="useterm_list">
        <p class="useterm_tit">个人信息的访问</p>
        <p>
          当您完成本网站的帐号注册后，您可以查阅或修改您提交给本网站的个人信息。一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别的考虑，您可能无法修改注册时提供的某些初始注册信息及验证信息。
        </p>
      </div>

      <div class="useterm_list">
        <p class="useterm_tit">对未成年人个人信息的特别保护</p>
        <p>
          本网站非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用本网站的服务前，应确保事先取得监护人的同意，如您在本网站上申请注册账号，本网站将默认为您已得到前述同意。本网站将根据国家相关法律法规及本《个人信息保护政策》的规定保护未成年人的个人信息。
        </p>
      </div>
      <div class="useterm_list">
        <p class="useterm_tit">隐私保护政策的修改</p>
        <p>
          本网站有权随时修改《个人信息保护政策》的任何条款，一旦《个人信息保护政策》的内容发生变动，本网站将会直接在PC端系统公告上公布修改之后的《个人信息保护政策》，该公布行为视为本网站已经通知您修改内容。本网站也可通过其他适当方式向用户提示修改内容。如果您不同意本网站对本《个人信息保护政策》相关条款所做的修改，您有权停止使用平台服务。如果您继续使用平台服务，则视为您接受本网站对本协议相关条款所做的修改。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>