<template>
  <div id="ques_dialog">
    <el-dialog
      custom-class="questionDialog"
      :title="projectCode"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="720px"
      :before-close="handleClose"
    >
      <div class="question_box" v-if="liaisonData.length > 0">
        <div class="add_question pointer" @click="addquestion()" v-if="addFlag">
          + 新增问题
        </div>
        <ul class="question_licon" style="max-height: 580px">
          <li v-for="(item, index) in liaisonData" :key="index">
            <div class="question_head">
              <div class="question_titbox">
                <p class="question_tit">
                  <span>编号</span>：{{ item.problemCode!=null&&item.problemCode!=""?item.problemCode:'暂无' }} |
                  <span>分类</span>：{{
                    item.problemTypeStr != null && item.problemTypeStr != ""
                      ? item.problemTypeStr
                      : "暂无"
                  }}
                </p>
                <p
                  class="que_operate pointer"
                  v-if="item.state != 1"
                  @click="delLiaproblem(item.problemId)"
                >
                  <img
                    src="../../../assets/images/outsidepro/edit.png"
                    alt=""
                  />删除
                </p>
              </div>

              <div class="question_tit" style="float: none">
                <span>图号</span>：{{
                  item.drawingNum != null && item.drawingNum != ""
                    ? item.drawingNum
                    : "暂无"
                }}
              </div>

              <div class="question_con">
                <!-- <p class="question_txt">
                  {{ item.content }}
                </p> -->

                <el-tooltip
                  :disabled="isShowTooltip"
                  effect="light"
                  :content="item.content"
                  placement="right"
                  popper-class="ques_popover"
                >
                  <div
                    @mouseover="onMouseOver(item.content)"
                    class="question_txt"
                  >
                    <!-- <span :ref="item.content"> 问题：{{ item.content }}</span> -->

                    <p :ref="item.content">
                      <span>问题：</span>{{ item.content }}
                    </p>
                  </div>
                </el-tooltip>

                <div class="question_imgbox">
                  <p v-for="(img, index2) in item.filePojoList" :key="index2">
                    <el-image
                      :src="imageUrl + img.fileUrl"
                      alt=""
                      :preview-src-list="getSrcList(index2, item.filePojoList)"
                    />
                  </p>
                  <!-- <p><img src="" alt="" /></p>
                  <p><img src="" alt="" /></p> -->
                </div>
              </div>
            </div>

            <div class="reply_txt" v-if="item.state == 0">
              <p class="problem_state">· 待回复</p>
            </div>

            <div class="reply_box" v-else-if="item.state == 1">
              <div class="reply_box_left">
                <el-tooltip
                  :disabled="isShowTooltip"
                  effect="light"
                  :content="item.replyContent"
                  placement="right"
                  popper-class="ques_popover"
                >
                  <p
                    @mouseover="onMouseOver(item.replyContent)"
                    class="replycontent"
                  >
                    <span :ref="item.replyContent">
                      回复：{{ item.replyContent }}</span
                    >
                  </p>
                </el-tooltip>
                <p
                  class="problemdownfile pointer"
                  v-if="item.replyFileList.length > 0"
                  @click="downfile(item.replyFileList)"
                >
                  下载项目附件
                </p>
              </div>
              <div class="problempic_box">
                <div
                  v-for="(imgres, index2) in item.replyFileList"
                  :key="index2"
                >
                  <p
                    class="problem_img"
                    v-if="
                      imgres.fileType == 'jpg' ||
                      imgres.fileType == 'png' ||
                      imgres.fileType == 'jpeg'
                    "
                  >
                    <el-image
                      :src="imageUrl + imgres.fileUrl"
                      alt=""
                      :preview-src-list="getSrcList(index2, item.replyFileList)"
                    />
                  </p>

                  <div class="problem_file" v-else>
                    <img
                      src="../../../assets/images/outsidepro/wjj.png"
                      alt=""
                    />
                    <p class="fileName">{{ imgres.fileName }}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="add_question_box" v-else-if="addFlag">
        <p class="add_question_but pointer" @click="addquestion()">
          + 新增问题
        </p>
      </div>

      <div class="add_question_box" v-if="!addFlag && liaisonData.length == 0">
        <p class="nodata_problem">暂无答疑</p>
      </div>
    </el-dialog>

    <el-dialog
      :title="problemtit"
      custom-class="addquestionlog"
      :visible.sync="addQuesDialog"
      :close-on-click-modal="false"
      width="560px"
      :before-close="handleClose2"
    >
      <div class="question_form" style="">
        <el-form ref="form" :model="form" label-width="84px">
          <el-form-item :label="'图\xa0\xa0\xa0\xa0\xa0\xa0\xa0号'">
            <el-input v-model="form.drawingNum"></el-input>
          </el-form-item>
          <el-form-item label="详细信息">
            <el-input type="textarea" v-model="form.content"></el-input>
          </el-form-item>

          <div class="upload_con">
            <div class="form_label" style="width: 84px; float: left">
              上传图片:
            </div>
            <div class="upload_con" style="margin-left: 90px">
              <div style="float: left; margin-right: 12px">
                <el-upload
                  action="#"
                  :limit="3"
                  :on-change="handleChangeimg"
                  :class="{ uploaddisabled: uploadDisabled }"
                  list-type="text"
                  accept=".jpg, .png, jpeg"
                  ref="uploadImage"
                  :auto-upload="false"
                  :http-request="uploadProjectImage"
                >
                </el-upload>
              </div>
              <div class="file_box">
                <div
                  v-for="(item, index) in fileList"
                  :key="index"
                  class="filebox_list"
                >
                  <div class="">
                    <div class="file_img">
                      <el-image
                        style="width: 98px; height: 98px"
                        :src="item.url"
                        :preview-src-list="preview(item.url)"
                      >
                      </el-image>
                    </div>
                    <div class="file_del pointer" @click="handleRemove(item)">
                      删除
                    </div>
                  </div>
                </div>
              </div>
              <div style="clear: both"></div>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addQuesDialog = false">取 消</el-button>
        <el-button class="confirm_but" type="primary" @click="comproblem()"
          >完成</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Global from "@/assets/js/global";
export default {
  name: "Home",
  props: {
    proQuestionShow: {
      default: false,
      type: Boolean,
    },
    projectTaskId: {
      type: Number,
    },
    addFlag: {
      type: Number,
    },
  },
  data() {
    return {
      dialogVisible: true,
      addQuesDialog: false,
      imageUrl: Global.baseURL,
      form: {
        drawingNum: "",
        content: "",
      },
      fileimgList: [],
      dialogImageUrl: false,
      dialogVisible2: false,
      filelength: 0,
      liaisonData: [],
      fileList: [],
      updateimg: [],
      uploadtype: 1,
      problemId: 0,
      problemtit: "新增问题",
      projectCode: "",
      isShowTooltip: false,
    };
  },
  mounted() {
    //显示弹窗
    this.show = this.proQuestionShow;
  },
  computed: {
    uploadDisabled: function () {
      return this.fileList.length > 2;
    },
  },
  created() {},

  methods: {
    getSrcList(index, fileList) {
      var urlList = [];
      fileList.forEach((item) => {
        let url = this.imageUrl + item.fileUrl;
        urlList.push(url);
      });
      return urlList.slice(index).concat(urlList.slice(0, index));
    },
    getProliasionMethod() {
      this.$http
        .get(
          "Api/yw/liaisonProblem/queryByTaskId?projectTaskId=" +
            this.projectTaskId
        )
        .then((res) => {
          this.liaisonData = res.data.data.datas;
          this.projectCode =
            res.data.data.projectCode + "&" + res.data.data.realmStr;
        });
    },
    onMouseOver(str) {
      // 内容超出，显示文字提示内容
      const tag = this.$refs[str][0];
      const parentWidth = tag.parentElement.offsetHeight; // 获取元素父级可视宽度
      const contentWidth = tag.offsetHeight; // 获取元素可视宽度

      this.isShowTooltip = contentWidth <= parentWidth;
    },

    handleClose() {
      //关闭弹窗
      this.show = false;
      this.$emit("closeDialog");
    },
    handleClose2() {
      this.addQuesDialog = false;
    },
    addquestion() {
      this.addQuesDialog = true;
      this.uploadtype = 1;
      this.problemtit = "新增问题";
      this.fileList = [];
      this.updateimg = [];
      this.form.drawingNum = "";
      this.form.content = "";
      this.$nextTick(() => {
        this.$refs.uploadImage.clearFiles();
      });
    },

    handleChangeimg(file) {
      var img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error("只能上传图片！");
        return false;
      } else {
        file.url = URL.createObjectURL(file.raw);
        this.fileList.push(file);
      }
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible2 = true;
    },

    uploadProjectImage(file) {
      this.fileData.append("files", file.file);
    },
    handleRemove(file) {
      this.fileList.forEach((v, index) => {
        if (file.name === v.name && file.url === v.url) {
          this.fileList.splice(index, 1);
        }
      });

      if (this.$refs.uploadImage.uploadFiles.length > 0) {
        this.$refs.uploadImage.uploadFiles.forEach((v, index) => {
          if (file.name === v.name) {
            this.$refs.uploadImage.uploadFiles.splice(index, 1);
          }
        });
      }

      if (this.updateimg.length > 0) {
        this.updateimg.forEach((v, index) => {
          if (file.name === v.name) {
            this.updateimg.splice(index, 1);
          }
        });
      }
    },
    async comproblem() {
      if (this.form.drawingNum == "") {
        this.$message.error("请输入图号！");
        return false;
      }

      if (this.form.content == "") {
        this.$message.error("请输入详细信息！");
        return false;
      }

      var liaisonjson = {};
      liaisonjson.drawingNum = this.form.drawingNum;
      liaisonjson.content = this.form.content;
      var url = "";
      if (this.uploadtype == 1) {
        url = "/Api/yw/liaisonProblem/save";
        liaisonjson.projectTaskId = this.projectTaskId;
      } else {
        liaisonjson.problemId = this.problemId;
        url = "/Api/yw/liaisonProblem/update";
      }

      this.fileData = new FormData(); // new formData对象
      this.$refs.uploadImage.submit();

      this.fileData.append("json", JSON.stringify(liaisonjson));
      for (var i = 0; i < this.updateimg.length; i++) {
        this.fileData.append("files", this.updateimg[i]);
      }

      // for (var i = 0; i < this.fileList.length; i++) {
      //   this.fileData.append("files", this.fileList[i]);
      // }

      await this.$http({
        method: "post",
        url: url,
        data: this.fileData,
      }).then((res) => {
        if (res.data.result) {
          this.$message.success("添加成功");
          // this.problemform = this.$options.data().problemform;
          this.$refs.uploadImage.clearFiles();
          this.filelength = 0;
          this.addQuesDialog = false;
          this.getProliasionMethod();
        } else {
          this.$message.error(res.data.msg);
        }
        // this.problemload = false;
      });
      // });
    },

    delLiaproblem(problemId) {
      this.$confirm("项目答疑问题是否删除？").then(() => {
        this.$http
          .delete("Api/yw/liaisonProblem/delete?id=" + problemId)
          .then((res) => {
            if (res.data.data) {
              this.$message.success("删除成功");
              this.getProliasionMethod();
            } else {
              this.$message.error("删除失败");
            }
          });
      });
    },

    editLiaproblem(data) {
      this.uploadtype = 2;
      this.problemtit = "修改问题";
      this.fileList = [];
      this.updateimg = [];
      this.form.content = data.content;
      this.form.drawingNum = data.drawingNum;
      this.problemId = data.problemId;
      this.addQuesDialog = true;
      // for (let i = 0; i < data.filePojoList.length; i++) {
      //   //创建对象，并将路径进行ip地址拼接
      //   console.log(this.imageUrl + data.filePojoList[i].fileUrl);
      //   this.fileimgList.push(this.imageUrl + data.filePojoList[i].fileUrl);
      // }
      var replyFileList = [];
      for (var j = 0; j < data.filePojoList.length; j++) {
        var file = {};
        file.url = this.imageUrl + data.filePojoList[j].fileUrl;
        file.name = data.filePojoList[j].fileName;
        this.newFile(data.filePojoList[j]);

        replyFileList.push(file);
      }
      this.fileList = replyFileList;
    },

    newFile(file) {
      let type;
      if (file.fileType == "excel") {
        type = "application/vnd.ms-excel";
      } else if (file.fileType == "image") {
        type = "image/jpeg";
      } else if (file.fileType == "docx") {
        type = "application/msword";
      }
      fetch(this.imageUrl + file.fileUrl)
        .then((res) => res.blob())
        .then((blob) => {
          let tempfile = null; //新建一个变量
          tempfile = new File([blob], file.fileName, {
            type: type, //传参
            lastModified: Date.now(),
          });
          this.updateimg.push(tempfile);
        });
    },
    preview(fileurl) {
      let srcList = [];
      srcList.push(fileurl);
      return srcList;
    },
    downfile(files) {
      files.forEach((file) => {
        fetch(this.imageUrl + file.fileUrl)
          .then((res) => res.blob())
          .catch(function () {
            // 出错了;等价于 then 的第二个参数,但这样更好用更直观 :
            this.$alert("未找到该图片", {
              confirmButtonText: "确定",
            });
          })
          .then((blob) => {
            // 将链接地址字符内容转变成blob地址
            let link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = file.fileName; // 下载文件的名字
            document.body.appendChild(link);
            link.click();
          });
      });
    },
  },
};
</script>

<style scoped>
</style>
