<template>
  <aside class="aside__top">
    <div style="line-height: 60px">
      <div class="head_logo pointer" @click="goMyProject()">
        <img src="../../../../assets/images/outsidepro/head_logo.png" alt="" />
      </div>
      <ul class="navigation">
        <li
          v-if="
            (token != null && this.$route.path == '/message') ||
            (token != null && this.$route.path == '/OutsideProcard')
          "
          class="pointer"
          @click="goMyProject()"
        >
          首页
        </li>
        <li v-if="token == null">
          <a
            style="color: inherit; text-decoration: none"
            Target="_blank"
            href="https://zzyjzkj.com:8080"
            >官网</a
          >
        </li>
        <li v-if="token == null">
          <a
            style="color: inherit; text-decoration: none"
            Target="_blank"
            href="https://space.bilibili.com/1176654026"
            >小翼图集动画</a
          >
        </li>
      </ul>
      <div v-if="token == null" class="top_login pointer" @click="login()">
        登录
      </div>

      <div
        class="pointer"
        v-if="token != null"
        style="float: right; margin-right: 40px"
      >
        <el-dropdown placement="bottom-start">
          <span class="el-dropdown-link">
            <p class="top_userimg">
              <img
                :src="userImg + '?t=' + new Date().getTime()"
                class=""
                alt=""
              />
            </p>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              ><span @click="loginOut()">退出</span></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div v-if="token == null" class="top_ruzhu pointer" @click="goRegister()">
        工程师入驻
      </div>
      <div
        v-if="token != null"
        class="message pointer"
        @click="clicknotice()"
        @mouseenter="doshownotice(systemtype)"
      >
        <span
          id="noticenum"
          class="message_num"
          v-if="unreadlength > 0 && unreadlength < 100"
          >{{ unreadlength }}</span
        >
        <span class="message_num" v-else-if="unreadlength > 100">99+</span>
        <div class="notice_shadow">
          <img
            alt="Vue logo"
            style=""
            src="../../../../assets/images/message.png"
          />
          <p v-if="unreadMessageNum > 0" class="numicon">
            {{ unreadMessageNum }}
          </p>
        </div>
      </div>

      <div
        v-if="token != null"
        class="pointer"
        style="width: 105px; float: right; font-size: 14px; margin-top: 2px"
        @click="goOtherView('orderNotice')"
      >
        项目须知
      </div>
    </div>
    <!-- v-show="shownotice" -->
    <span v-if="token != null" @mouseleave="leavenotice()">
      <el-card class="box-card" v-show="shownotice">
        <div class="card_box">
          <el-badge
            :value="unreadMessageNum"
            class="item"
            :hidden="unreadMessageNum == 0"
          >
            <div
              class="card_nav pointer"
              :class="{ card_nav_active: systemtype == 2 }"
              @click="newssystem(2)"
            >
              项目通知
            </div>
          </el-badge>
          <el-badge
            :value="systemtNoCount"
            :max="99"
            class="item"
            :hidden="systemtNoCount == 0"
          >
            <div
              class="card_nav pointer"
              :class="{ card_nav_active: systemtype == 1 }"
              @click="newssystem(1)"
            >
              系统通知
            </div>
          </el-badge>
          <p class="topall_read pointer" @click="read_but()">全部已读</p>
        </div>

        <div>
          <div
            style="height: 40%; padding-top: 35%; text-align: center"
            v-if="noticelist.length == 0"
          >
            <img
              src="../../../../assets/images/message2.png"
              width="30px"
              alt=""
            />
            <p class="nodata_txt" v-if="systemtype == 1">暂无系统通知</p>
            <p class="nodata_txt" v-else-if="systemtype == 2">
              {{ tongzhi }}
            </p>
          </div>
          <div
            v-for="(item, index) in noticelist"
            :key="index"
            class="notice_card_list"
          >
            <p class="card_icon">
              <img
                :src="
                  item.msgType == 1
                    ? require('/src/assets/images/home/notice_icon_3.png')
                    : item.msgType == 2
                    ? require('/src/assets/images/home/notice_icon_1.png')
                    : item.msgType == 3
                    ? require('/src/assets/images/home/notice_icon_2.png')
                    : ''
                "
                alt=""
              />
            </p>
            <div class="card_txtcon" v-if="item.readStatus == 0">
              <p class="card_tit">{{ item.pushTypeText }}</p>
              <p class="card_txt">
                {{ item.pushContent }}
              </p>
              <p class="card_time">{{ item.crtDateStr }}</p>
            </div>

            <div class="card_txtcon readnotice" v-else>
              <p class="card_tit">{{ item.pushTypeText }}</p>
              <p class="card_txt">
                {{ item.pushContent }}
              </p>
              <p class="card_time">{{ item.crtDateStr }}</p>
            </div>

            <p style="clear: both"></p>
          </div>
          <div class="card_but" v-if="noticelist.length > 0">
            <!-- <div class="card_but_left pointer" @click="read_but()">
              当前标记为已读
            </div> -->
            <div class="card_but_right pointer" @click="clicknotice()">
              查看全部 >
            </div>
          </div>
        </div>
      </el-card>
    </span>
  </aside>
</template>

<script>
import { mapState } from "vuex";
import Global from "@/assets/js/global";
export default {
  created() {
    this.unreadMessage();
  },
  props: {
    unreadMessageNum: {
      type: Number,
    },
  },
  data() {
    return {
      userImg: Global.baseuserURL + window.sessionStorage.getItem("userImg"),
      systemtype: 2,
      homenotice: true,
      homeprivate: false,
      shownotice: false,
      sysnotice: false,
      noticelists: [],
      systemlists: [],
      noticeclass: "actnotice",
      privateclass: "",
      systemclass: "",
      unreadlist: "",
      unreadlength: "",
      unreadnotice: "",
      unreadsys: "",
      loading: true,
      noticeindex: 100,
      sysindex: 100,
      arrow: false,
      noticelist: [],
      systemtNoCount: 0,
      tongzhi: "暂无项目通知",
      time: 5,
      token: window.sessionStorage.getItem("token"),
      queryLimit: {
        page: 1,
        limit: 5,
        searchText: "",
        type: 2,
        read: 0,
      },
    };
  },
  computed: {
    ...mapState(["isSidebarNavCollapse"]),
  },
  methods: {
    toggleNavCollapse() {
      this.$store.commit("toggleNavCollapse");
    },
    getuserImg() {
      this.userImg = "";
      this.userImg =
        Global.baseuserURL + window.sessionStorage.getItem("userImg");
    },
    loginOut() {
      // this.$store.commit("LOGIN_OUT");
      // this.$store.commit("CLEAR_PERMSSION");
      window.sessionStorage.clear();
      /* 防止切换角色时addRoutes重复添加路由导致出现警告 */
      window.location.reload();
    },
    login() {
      this.$router.push("/login");
    },
    goRegister() {
      this.$router.push("/register");
    },

    goOtherView(viewUrl) {
      const { href } = this.$router.resolve({
        path: `/` + viewUrl,
      });

      window.open(href, "_blank");
    },
    goMyProject() {
      // console.log(this.$route.path.split("/")[2]);

      var path = this.$route.path.split("/")[2];

      if (path !== "projectDetail") {
        this.$router.replace({ path: "/leftNavigation/projectDetail" });
        this.$parent.routeract();
      }
    },
    clicknotice() {
      this.$router.push("/message");
    },
    doshownotice(type) {
      this.queryLimit.limit = 3;
      this.queryLimit.type = type;
      this.shownotice = true;
      this.$http.get("/Api/socket", { params: this.queryLimit }).then((res) => {
        this.noticelist = res.data.data.items;
      });
    },
    leavenotice() {
      this.shownotice = false;
    },
    read_but() {
      let ids = [];
      this.noticelist.forEach((ele) => {
        ids.push(ele.offlineId);
      });
      this.$http.post("/Api/socket", ids).then((res) => {
        this.doshownotice(this.systemtype);
        this.unreadMessage();
        this.getnoticelist();
      });
    },

    newssystem(systemtype) {
      this.doshownotice(systemtype);
      this.systemtype = systemtype;
    },
    unreadMessage() {
      this.$emit("unreadMessage");
    },
    getnoticelist() {
      this.$http
        .get('/"Api/socket', { params: this.queryLimit })
        .then((res) => {
          this.noticelist = res.data.data.items;
          this.totalCount = res.data.data.total;
        });
    },
  },
};
</script>

<style scoped>
.aside__top {
  border-bottom: 1px solid #e5e5e5;
  height: 60px;
  /* line-height: 60px; */
  position: fixed;
  left: 0px;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 1000;
  transition: left 0.25s;
}

.head_logo {
  float: left;
  margin-left: 42px;
  margin-top: 10px;
  user-select: none;
}

.aside__top--right {
  position: absolute;
  right: 10px;
  top: -1px;
  bottom: 0px;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all 0.6s;
}

.breadcrumb-leave-active {
  position: absolute;
}

.aside__top--right div {
  float: left;
}

.navigation li {
  float: left;
  font-size: 16px;
  color: #000000;
  line-height: 60px;
  margin-left: 68px;
}

.top_login {
  width: 60px;
  height: 24px;
  /* background: #f0f4fe; */
  border: 1px solid #7856fd;
  border-radius: 4px;
  font-size: 12px;
  color: #7856fd;
  line-height: 24px;
  float: right;
  margin-top: 18px;
  margin-right: 30px;
}
.top_login:hover {
  background: #f1eeff;
}
.top_ruzhu {
  float: right;
  margin-top: 18px;
  margin-right: 20px;
  width: 78px;
  height: 24px;
  font-size: 12px;
  color: #ffffff;
  line-height: 24px;
  background: linear-gradient(117deg, #9e88fe, #7856fd);
  border-radius: 4px;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.top_ruzhu:hover {
  animation: zoomIn 0.5s ease;
}

.top_register {
  width: 60px;
  height: 24px;
  background: #f0f4fe;
  border-radius: 4px;
  font-size: 12px;
  color: #7856fd;
  line-height: 24px;
  float: right;
  margin-top: 18px;
  margin-right: 30px;
}

.message,
.user {
  width: 36px;
  height: 36px;
  float: right;
  border-radius: 100%;
}

.message {
  position: relative;
  margin-right: 10px;
}

.message_num {
  position: absolute;
  height: 16px;
  line-height: 16px;
  left: 12px;
  top: -14px;
  background-color: #ff0000;
  padding: 0px 5px !important;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  color: #fff;
}
.notice_shadow {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  text-align: center;

  margin-top: 12px;
}
.notice_shadow img {
  position: absolute;
  top: 24px;
  left: 9px;
}
.notice_shadow:hover {
  background: #f7f9fa;
}
.navigat_active {
  font-weight: bold;
}
.navigat_list {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 64px;
  margin-left: 42px;
  float: left;
}
.box-card {
  width: 430px;
  position: absolute;
  z-index: 22;
  right: 124px;
  top: 60px;
}
.card_box {
  width: calc(100% - 40px);
  height: 68px;
  border-bottom: 1px solid #e3e9ed;
  text-align: left;
  padding: 0px 20px;
}
.card_nav {
  height: 66px;
  width: 90px;
  line-height: 66px;
  font-size: 14px;
  color: #000000;
  float: left;
  text-align: left;
  text-indent: 8px;
}

.card_nav_active {
  /* border-bottom: 2px solid #7856fd; */
}
/* .card_nav_active::before {
  width: 20px;
  height: 2px;
  background: #7856fd;
} */

.card_nav_active::after {
  position: absolute;
  width: 36px;
  height: 2px;
  border-bottom: 2px solid #7856fd;
  bottom: -1px;
  content: "";
  left: 20%;
  mix-blend-mode: multiply;
  right: -1px;
}

.notice_card_list {
  width: 375px;
  /* background: #eff7fa; */
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 20px;
  /* padding-bottom: 16px; */
}
/* .notice_card_list:hover {
  background: #f1eeff;
} */

.notice_head {
  height: 40px;
  position: relative;
}
.el-card .noread_icon {
  width: 4px;
  height: 4px;
  background: #d9001b;
  border-radius: 50%;
  position: absolute;
  top: 24px;
  left: 8px;
}

.el-card .noread_state {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  position: absolute;
  left: 18px;
}

.el-card .noread_txt {
  font-size: 12px;
  color: #000000;
  line-height: 24px;
  padding: 0px 18px;
  text-align: left;
}
.noread_time {
  font-size: 12px;
  color: #666666;
  position: absolute;
  top: 40px;
  right: 18px;
}
.nodata_txt {
  font-size: 12px;
  color: #8c9299;
  line-height: 24px;
  text-align: center;
}
.helpnotes {
  border-radius: 20px;
  width: 296px;
  height: 100px;
  background-image: url("../../../../assets/images/helpcen_boxbg1.png");
  position: absolute;
  top: 45px;
  right: 75px;
  z-index: 100;
  display: none;
}
.helpnotes_tit {
  font-size: 14px;
  color: #ffffff;
  margin: 30px 0px 0px 28px;
  text-align: left;
}
.helpnotes_txt {
  font-size: 12px;
  color: #ffffff;
  margin: 10px 0px 0px 28px;
  text-align: left;
}
.card_icon {
  width: 24px;
  height: 24px;
  background: #7856fd;
  border-radius: 50%;
  float: left;
}
.card_txtcon {
  float: right;
  width: calc(100% - 30px);
}
.card_tit {
  font-size: 14px;
  color: #000000;
  line-height: 24px;
}
.card_txt {
  font-size: 14px;
  color: #636977;
  margin-top: 6px;
}
.card_time {
  font-size: 12px;
  color: #91959f;
  line-height: 24px;
}
.card_but_right {
  /* float: right; */
  position: absolute;
  bottom: 28px;
  right: 24px;
  font-size: 12px;
  color: #162a8f;
  line-height: 24px;
}
.numicon {
  font-size: 12px;
  color: #ffffff;
  width: 14px;
  height: 14px;
  background: #ff2b00;
  border-radius: 50%;
  position: absolute;
  top: 14px;
  right: 2px;
  line-height: 14px;
  text-align: center;
  font-size: 10px;
}
.topall_read {
  float: right;
  font-size: 12px;
  color: #8f73fd;
  line-height: 68px;
}
.readnotice .card_tit {
  color: #8d8d8d;
}

.readnotice .card_txt,
.readnotice .card_time {
  color: #acafb5;
}
.top_userimg {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  vertical-align: middle;
  background: #fff;
  display: inline-block;
  text-align: center;
}
.top_userimg img {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
</style>
