<template>
  <div class="outsidePro" style="background: #f5f6fa">
    <div class="outside_head"></div>

    <div id="outsidecon">
      <div><newPro ref="newProlist" /></div>
    </div>

    <div class="engineerpath" @click="pathengineer()">
      <img src="../../assets/images/auth/engAuth_icon.png" alt="" />
    </div>

    <div class="announce">
      <el-dialog
        custom-class="isAuthenticDialog"
        :visible.sync="dialogAnnounce"
        width="595px"
        :before-close="handleClose2"
        :close-on-click-modal="false"
      >
        <div class="isAuth_icon announce_icon"></div>
        <div class="isAuth_close anno_close" @click="handleClose2()"></div>
        <div class="announce_bg" style=""></div>

        <div class="announce_box">
          <p class="isAuth_tit announce_tit">平台公告</p>
          <div class="announce_txt">
            <p>亲爱的用户，您好:</p>
            <p style="text-indent: 30px">
              感谢您对我们平台的支持，我们将在近期进行一次重要的更新。本次更新旨在优化交互模式、修复已知问题，并引入一些新的功能和改进。我们将尽力在更新过程中为您提供最好的服务，同时尽可能减少不便。感谢您的理解和支持！如果您有任何疑问或反馈，请随时联系我们的客户服务团队。
            </p>
          </div>
          <div
            style="margin-top: 50px"
            class="authBut pointer"
            @click="handleClose2()"
          >
            知道了
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/http";
import newPro from "./component/viewNewpro";
export default {
  components: {
    newPro,
  },
  name: "Home",
  data() {
    return {
      proshow: 1,
      residueCount: 0,
      inCount: 0,
      dialogAnnounce: false,
      isAnnounce: 0,
    };
  },
  created() {
    // this.getproCount();
    this.isAnnounce = window.sessionStorage.getItem("userAnnounce");

    if (this.isAnnounce == 0) {
      console.log("222");
      this.dialogAnnounce = true;
    } else {
      this.dialogAnnounce = false;
    }
  },
  methods: {
    // getproCount() {
    //   this.$http.get("/Api/yw/projectTaskOutside/getMyCount").then((res) => {
    //     this.residueCount = res.data.data.residueCount;
    //     this.inCount = res.data.data.inCount;
    //   });
    // },
    handleClose2() {
      window.sessionStorage.setItem("userAnnounce", 1);
      this.dialogAnnounce = false;
      // window.sessionStorage.removeItem("showCredentialsFlag");
    },
    pathengineer() {
      this.$router.push("/engineerAuth");
    },
    gettapPro(type) {
      this.proshow = type;
      if (type == 1) {
        this.$refs.newProlist.newProMethod();
      } else {
        this.$refs.myProlist.myProMethod();
      }
    },

    gomyproMethod() {
      this.proshow = 2;
      this.$refs.myProlist.myProMethod();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "@/assets/css/outsidePro.css";
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  list-style-type: none;
  /* margin: 0 10px; */
}
.engineerpath {
  position: absolute;
  right: 42px;
  top: 80px;
  cursor: pointer;
}

/* a {
  color: #42b983;
} */
</style>
