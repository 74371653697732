<template>
  <div class="foot_box">
    <div class="foot_con">
      <div class="foot_logo">
        <p class="foot_logo_img">
          <img src="../../../../assets/images/home/foot_logo.png" alt="" />
        </p>
        <p class="foot_logo_txt">
          众之翼建科 <span>·</span> 让每一项建设精准高效
        </p>
      </div>

      <div class="foot_con_left">
        <ul>
          <li>关于我们</li>
          <li>
            <a target="_blank" href="https://zzyjzkj.com:8080/aboutus.html#introduce"
              >公司简介</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://zzyjzkj.com:8080/aboutus.html#service_process"
              >企业文化</a
            >
          </li>
          <li>
            <a target="_blank" href="https://zzyjzkj.com:8080/aboutus.html#honors"
              >公司资质</a
            >
          </li>
          <li>
            <a target="_blank" href="https://zzyjzkj.com:8080/aboutus.html#course"
              >发展历程</a
            >
          </li>
        </ul>
        <!-- <ul>
          <li>产品与服务</li>
          <li>钢结构深化设计</li>
          <li>建筑漫游动画制作</li>
          <li>机电管廊BIM应用</li>
          <li>BIM技术平台应用研发</li>
          <li>BIM技术应用人才代培</li>
        </ul> -->
        <ul>
          <li>我有技术</li>
          <li ><a target="_blank" class="pointer" @click="goOtherView('register')">入驻平台</a></li>
          <li ><a target="_blank" class="pointer" @click="goOtherView('orderNotice')">项目须知</a></li>
          <li ><a target="_blank" class="pointer" >工程师手册</a></li>
        </ul>
        <ul>
          <li>帮助中心</li>
          <li><a href="">新手指南</a></li>
          <li><a href="">常见问题</a></li>
          <li><a href="">意见及反馈</a></li>
        </ul>
      </div>

      <div class="foot_con_right">
        <div class="service_box">
          <p class="service_tit">服务与支持</p>
          <p class="service_phone">0359-2560707</p>
          <p class="service_time">周一至周五09:00-17:00</p>
          <p class="service_wx">官方微信：zzyjk2560707</p>
          <p class="service_wx">官方邮箱：zzyjzkj@163.com</p>
        </div>

        <div style="margin-top: 20px">
          <div class="vs_code">
            <div class="wechat_codebox">
              <div class="qrcode">
                <div class="codelist">
                  <p><img src="../../../../assets/images/gzh.png" alt="" /></p>
                </div>
              </div>
              <div class="ov1"></div>
              <div class="ov2"></div>
            </div>
          </div>

          <div class="vs_code vs_code2">
            <div class="wechat_codebox biliwechat">
              <div class="qrcode">
                <div class="codelist">
                  <p>
                    <img
                      src="../../../../assets/images/wechat_bili.png"
                      alt=""
                    />
                  </p>
                </div>
              </div>
              <div class="ov1"></div>
              <div class="ov2"></div>
            </div>
          </div>

          <div class="vs_code vs_code3">
            <div class="wechat_codebox tiktokcode">
              <div class="qrcode">
                <div class="codelist">
                  <p>
                    <img
                      src="../../../../assets/images/zzyto_tiktok.jpg"
                      alt=""
                    />
                  </p>
                </div>
                <div class="codelist">
                  <p>
                    <img
                      src="../../../../assets/images/xiaoyi_tiktok.jpg"
                      alt=""
                    />
                  </p>
                </div>
              </div>
              <div class="ov1"></div>
              <div class="ov2"></div>
            </div>
          </div>

          <div class="vs_code vs_code4">
            <div class="wechat_codebox tiktokcode gzhwechat">
              <div class="qrcode">
                <div class="codelist">
                  <p>
                    <img
                      src="../../../../assets/images/wechat_video.png"
                      alt=""
                    />
                  </p>
                  <!-- <p>众之翼建科</p> -->
                </div>
                <div class="codelist">
                  <p>
                    <img
                      src="../../../../assets/images/wechat_atlas.png"
                      alt=""
                    />
                  </p>
                  <!-- <p>小翼图集</p> -->
                </div>
              </div>
              <div class="ov1"></div>
              <div class="ov2"></div>
            </div>
          </div>
        </div>
      </div>
      <p style="clear: both"></p>
    </div>
    <!-- <div class="recordNo">
      <div class="recordNo_text">
        <span
          ><img
            src="../../../../assets/images/record_icon.png"
            alt=""
            style="vertical-align: middle"
        /></span>
        <span
          ><a target="_blank" class="icphome" href="https://beian.miit.gov.cn/"
            >晋ICP备2022000048号</a
          ></span
        >
        <span>|</span>
        <span
          ><img
            src="../../../../assets/images/copyright.png"
            alt=""
            style="vertical-align: middle"
        /></span>
        <span>zzyjzkj 2021 - {{ year }} 山西众之翼建筑科技有限公司</span>
      </div>
    </div> -->

    <!-- <div class="login_bottom"> -->
    <div class="recordNo">
      <div class="login_bottom_text">
        <span
          ><img
            src="../../../../assets/images/bottom/copyright.png"
            alt=""
            style="vertical-align: middle"
        /></span>
        <span>zzyjzkj 2021 - {{ year }} 山西众之翼建筑科技有限公司</span>
        <span>|</span>
        <span style="margin-top: -1px"
          ><img
            src="../../../../assets/images/bottom/record_icon.png"
            alt=""
            style="vertical-align: middle"
        /></span>
        <span
          ><a target="_blank" class="icphref" style="text-decoration-line: none" href="https://beian.miit.gov.cn/"
            >晋ICP备2022000048号</a
          ></span
        >
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import Global from "@/assets/js/global";
// import Weather from "@/components/Weather.vue";
export default {
  name: "Header",
  //  components: {
  //   "v-weather":Weather
  // },
  data() {
    return { year: "" };
  },
  props: {},
  created() {
    this.getNowDate();
  },

  methods: {
    goOtherView(viewUrl) {
      const { href } = this.$router.resolve({
        path: `/`+viewUrl,
      });

      window.open(href, "_blank");
    },
    getNowDate() {
      const timeOne = new Date();
      this.year = timeOne.getFullYear();
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/foot.css";
.foot_con_left {
  text-align: left;
}
</style>
