<template>
  <div class="authenticat" style="background: #f5f6fa">
    <div class="authenticat_box">
      <div class="authenticat_right" style="">
        <ul class="authen_nav">
          <li
            :class="authenType == 1 ? 'authenactive' : ''"
            @click="changeauthenType(1)"
          >
            基本信息
          </li>
          <li
            :class="authenType == 2 ? 'authenactive' : ''"
            @click="changeauthenType(2)"
          >
            认证信息
          </li>
          <li
            :class="authenType == 3 ? 'authenactive' : ''"
            @click="changeauthenType(3)"
          >
            修改密码
          </li>
        </ul>
        <div>
          <div class="basicInfor_box">
            <div v-if="authenType == 1">
              <p class="basicInfor_tit">基本信息</p>
              <div class="picture_box">
                <p class="picture_img"><img :src="picimg" alt="" /></p>
                <!-- <p class="editpic">修改头像</p> -->

                <div class="userpic_but_box">
                  <div class="userpic_but">修改头像</div>
                  <div class="userpic_butfile pointer">
                    <input
                      class="pointer"
                      style="width: 78px"
                      type="file"
                      @change="changeFile"
                      accept="image/*"
                    />
                  </div>
                </div>
              </div>

              <div>
                <el-form
                  :inline="true"
                  :model="ownerInfo"
                  ref="ownerInfo"
                  class=""
                  style="text-align: left"
                >
                  <div class="form_inline">
                    <p class="input_label">昵称</p>
                    <el-form-item label="" prop="phone">
                      <div class="pass_input pointer" style="margin-top: 10px">
                        <el-input
                          type="text"
                          v-model="ownerInfo.userStageName"
                          maxlength="10"
                          show-word-limit
                          autocomplete="off"
                          placeholder="请输入您的昵称"
                          @input="changeuserinfo()"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </div>

                  <div class="form_inline">
                    <p class="input_label">民族</p>
                    <el-form-item label="">
                      <div class="pass_input" style="margin-top: 10px">
                        <el-select
                          v-model="ownerInfo.nationality"
                          filterable
                          placeholder="请选择民族"
                          class="add-nation-input"
                        >
                          <el-option
                            v-for="item in nationList"
                            :key="item.value"
                            :label="item.info"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                  </div>
                  <div class="form_inline">
                    <p class="input_label">毕业院校</p>
                    <el-form-item label="" prop="phone">
                      <div class="pass_input pointer" style="margin-top: 10px">
                        <el-input
                          type="text"
                          v-model="ownerInfo.university"
                          show-word-limit
                          autocomplete="off"
                          placeholder="请输入您的毕业院校"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </div>

                  <div class="form_inline">
                    <p class="input_label">性别</p>
                    <el-form-item label="">
                      <div class="pass_input" style="margin-top: 10px">
                        <el-select
                          v-model="ownerInfo.sex"
                          placeholder="请选择性别"
                          @change="changeuserinfo()"
                        >
                          <el-option label="男" :value="1"></el-option>
                          <el-option label="女" :value="2"></el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                  </div>

                  <div class="form_inline" style="width: 700px">
                    <p class="input_label">年龄</p>
                    <el-form-item label="" prop="phone">
                      <div class="pass_input pointer" style="margin-top: 10px">
                        <el-input
                          type="text"
                          v-model="ownerInfo.age"
                          show-word-limit
                          autocomplete="off"
                          placeholder="请输入您的年龄"
                          @input="changeuserinfo()"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </div>

                  <div class="form_inline">
                    <p class="input_label">所属行业</p>
                    <el-form-item label="">
                      <div class="pass_input" style="margin-top: 10px">
                        <el-select
                          v-model="ownerInfo.profession"
                          filterable
                          placeholder="请选择所属行业"
                          class="add-nation-input"
                        >
                          <el-option
                            v-for="item in professionlist"
                            :key="item.key"
                            :label="item.val"
                            :value="item.key"
                          ></el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                  </div>
                  <div class="form_inline">
                    <p class="input_label">所在地区</p>
                    <el-form-item prop="nativePlace">
                      <div class="pass_input" style="margin-top: 10px">
                        <el-cascader
                          v-model="ownerInfo.city"
                          @change="nativePlaceChange($event)"
                          style="width: 100%"
                          placeholder="如:北京市丰台区"
                          clearable
                          :options="nativePlaceOptions"
                        >
                        </el-cascader>
                      </div>
                    </el-form-item>
                  </div>

                  <div class="form_inline sel_software" style="width: 800px">
                    <p class="input_label">常用软件</p>
                    <el-form-item label="">
                      <div class="pass_input" style="margin-top: 10px">
                        <el-select
                          v-model="ownerInfo.commonSoftwareList"
                          multiple
                          placeholder="请选择常用软件"
                          class="add-nation-input"
                        >
                          <el-option
                            v-for="item in softWarelist"
                            :key="item.key"
                            :label="item.val"
                            :value="item.key"
                          ></el-option>
                        </el-select>
                      </div>
                    </el-form-item>
                  </div>

                  <div class="form_inline introduction">
                    <p class="input_label">自我介绍</p>
                    <el-form-item>
                      <div class="pass_input" style="margin-top: 10px">
                        <el-input
                          type="textarea"
                          v-model="ownerInfo.selfIntroduction"
                        ></el-input>
                      </div>
                    </el-form-item>
                  </div>

                  <p style="clear: both"></p>
                </el-form>
              </div>
              <!-- v-show="userinforbut" -->
              <div class="saveBasic pointer" @click="saveBasicinfo()">保存</div>
            </div>

            <div v-if="authenType == 2">
              <p class="basicInfor_tit">认证信息</p>
              <div class="idcardauth">
                <p class="label_tit">身份证</p>
                <div
                  class="isIdcard_auth pointer"
                  @click="goIdcardauth()"
                  v-if="usercard.isUserCard == 0"
                >
                  <p class="">
                    <img
                      src="../../assets/images/auth/noauth_icon.png"
                      alt=""
                    />身份证信息待认证
                  </p>
                  <p></p>
                  <span
                    style="float: right; line-height: 44px; margin-right: 10px"
                    ><img src="../../assets/images/auth/auth_back.png" alt=""
                  /></span>
                </div>

                <div class="iscard_auth_suc isIdcard_auth" v-else>
                  <p class="">
                    <img
                      src="../../assets/images/auth/auth_icon.png"
                      alt=""
                    />身份证信息已认证成功
                  </p>
                </div>
              </div>

              <div class="idcardauth">
                <p class="label_tit">手机号</p>
                <div class="editphone_box">
                  <p class="phone_num">{{ phone }}</p>
                  <p class="phone_editbut pointer" @click="changeUserPhone()">
                    修改
                  </p>
                </div>
              </div>

              <div class="idcardauth">
                <p class="label_tit">银行卡信息</p>
                <div class="editphone_box" v-if="usercard.isBankCard == 0">
                  <p class="phone_tip">请绑定银行卡</p>
                  <p class="phone_editbut pointer" @click="goBankcardauth()">
                    绑定
                  </p>
                </div>
                <div class="editphone_box" v-else>
                  <p class="bankNumber">{{ hidebank }}</p>
                  <p class="phone_editbut pointer" @click="editBankcardauth()">
                    修改
                  </p>
                </div>
              </div>
            </div>

            <div v-if="authenType == 3">
              <p class="basicInfor_tit">修改密码</p>

              <div>
                <el-form
                  :inline="true"
                  :model="passdata"
                  :rules="rules"
                  ref="passdata"
                  class=""
                  style="text-align: left; padding-top: 20px"
                >
                  <p class="input_label">原密码</p>
                  <el-form-item label="" prop="curpass">
                    <div class="pass_input pointer" style="margin-top: 10px">
                      <el-input
                        :type="passwordType"
                        v-model="passdata.curpass"
                        autocomplete="off"
                        placeholder="请输入原密码"
                      ></el-input>
                      <img
                        :src="imgsrc"
                        alt=""
                        class="eyeicon"
                        @mouseover="showPassword"
                        @mouseleave="mouseLeave"
                      />
                    </div>
                  </el-form-item>

                  <p class="input_label">新密码</p>

                  <el-form-item
                    label=""
                    prop="password1"
                    :class="[newpasserror.length > 0 ? 'is-error' : '']"
                  >
                    <div class="pass_input pointer" style="margin-top: 10px">
                      <el-input
                        :type="passwordType2"
                        v-model="passdata.password"
                        autocomplete="off"
                        placeholder="请输入新密码"
                        @input="getnewpass()"
                        @blur="getnewpassError()"
                      ></el-input>
                      <img
                        :src="imgsrc2"
                        alt=""
                        class="eyeicon"
                        @mouseover="showPassword2"
                        @mouseleave="mouseLeave2"
                      />
                    </div>
                  </el-form-item>
                  <div class="pass_error">{{ newpasserror }}</div>

                  <div class="grade" style="margin-top: 10px">
                    <div>
                      <p class="default" :class="ruoclass"></p>
                      <span>弱</span>
                    </div>
                    <div>
                      <p class="default" :class="zhongclass"></p>
                      <span>中</span>
                    </div>
                    <div>
                      <p class="default" :class="qiangclass"></p>
                      <span>强</span>
                    </div>
                  </div>

                  <p class="input_label">重复密码</p>
                  <el-form-item label="" prop="password2">
                    <div class="pass_input pointer" style="margin-top: 10px">
                      <el-input
                        :type="passwordType3"
                        v-model="passdata.password2"
                        autocomplete="off"
                        placeholder="请重复输入密码"
                      ></el-input>
                      <img
                        :src="imgsrc3"
                        alt=""
                        class="eyeicon"
                        @mouseover="showPassword3"
                        @mouseleave="mouseLeave3"
                      />
                    </div>
                  </el-form-item>
                </el-form>
                <div class="saveBasic pointer" @click="changepassword()">
                  保存
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p style="clear: both"></p>
    </div>
    <identityauth
      :phone="phone"
      :usercard="usercard"
      @getUserCredinfo="getUserCredinfo"
      ref="identity"
    />
  </div>
</template>

<script>
import Global from "@/assets/js/global";
import identityauth from "./component/authenticatdialog";
import { regionData, codeToText, TextToCode } from "element-china-area-data";
export default {
  name: "Home",
  components: {
    identityauth,
  },

  data() {
    var validateconPass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入确认密码"));
      } else if (value !== this.passdata.password) {
        return callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };

    return {
      imgsrc: require("@/assets/images/c-eye.png"),
      imgsrc2: require("@/assets/images/c-eye.png"),
      imgsrc3: require("@/assets/images/c-eye.png"),
      passwordType: "password",
      passwordType2: "password",
      passwordType3: "password",
      passdata: {
        curpass: "",
        password: "",
        password2: "",
      },
      ownerInfo: {
        userStageName: "",
        sex: "1",
        nationality: "",
        age: "",
        city: "",
        cityid: "",
        university: "",
        profession: "",
        commonSoftwareList: "",
        selfIntroduction: "",
      },

      compassword: "",
      authenType: 1,
      usercard: {},
      userInfo: {},
      picimg: "",
      hidebank: "",
      phone: "",
      ruoclass: "",
      zhongclass: "",
      qiangclass: "",
      rules: {
        curpass: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        password2: [{ validator: validateconPass, trigger: "blur" }],
      },
      newpasserror: "",
      isCredentials: 0,
      ownerInfodata: {},
      userinforbut: false,
      nativePlaceOptions: regionData,
      softWarelist: [],
      professionlist: [],
      nationList: [
        {
          id: 1,
          info: "汉族",
          value: "汉族",
        },
        {
          id: 2,
          info: "壮族",
          value: "壮族",
        },
        {
          id: 3,
          info: "满族",
          value: "满族",
        },
        {
          id: 4,
          info: "回族",
          value: "回族",
        },
        {
          id: 5,
          info: "苗族",
          value: "苗族",
        },
        {
          id: 6,
          info: "维吾尔族",
          value: "维吾尔族",
        },
        {
          id: 7,
          info: "土家族",
          value: "土家族",
        },
        {
          id: 8,
          info: "彝族",
          value: "彝族",
        },
        {
          id: 9,
          info: "蒙古族",
          value: 9,
        },
        {
          id: 10,
          info: "藏族",
          value: "藏族",
        },
        {
          id: 11,
          info: "布依族",
          value: "布依族",
        },
        {
          id: 12,
          info: "侗族",
          value: "侗族",
        },
        {
          id: 13,
          info: "瑶族",
          value: "瑶族",
        },
        {
          id: 14,
          info: "朝鲜族",
          value: "朝鲜族",
        },
        {
          id: 15,
          info: "白族",
          value: "白族",
        },
        {
          id: 16,
          info: "哈尼族",
          value: "哈尼族",
        },
        {
          id: 17,
          info: "哈萨克族",
          value: "哈萨克族",
        },
        {
          id: 18,
          info: "黎族",
          value: "黎族",
        },
        {
          id: 19,
          info: "傣族",
          value: "傣族",
        },
        {
          id: 20,
          info: "畲族",
          value: "畲族",
        },
        {
          id: 21,
          info: "傈僳族",
          value: "傈僳族",
        },
        {
          id: 22,
          info: "仡佬族",
          value: "仡佬族",
        },
        {
          id: 23,
          info: "东乡族",
          value: "东乡族",
        },
        {
          id: 24,
          info: "高山族",
          value: "高山族",
        },
        {
          id: 25,
          info: "拉祜族",
          value: "拉祜族",
        },
        {
          id: 26,
          info: "水族",
          value: "水族",
        },
        {
          id: 27,
          info: "佤族",
          value: "佤族",
        },
        {
          id: 28,
          info: "纳西族",
          value: "纳西族",
        },
        {
          id: 29,
          info: "羌族",
          value: "羌族",
        },
        {
          id: 30,
          info: "土族",
          value: "土族",
        },
        {
          id: 31,
          info: "仫佬族",
          value: "仫佬族",
        },
        {
          id: 32,
          info: "锡伯族",
          value: "锡伯族",
        },
        {
          id: 33,
          info: "柯尔克孜族",
          value: "柯尔克孜族",
        },
        {
          id: 34,
          info: "达斡尔族",
          value: "达斡尔族",
        },
        {
          id: 35,
          info: "景颇族",
          value: "景颇族",
        },
        {
          id: 36,
          info: "毛南族",
          value: "毛南族",
        },
        {
          id: 37,
          info: "撒拉族",
          value: "撒拉族",
        },
        {
          id: 38,
          info: "布朗族",
          value: "布朗族",
        },
        {
          id: 39,
          info: "塔吉克族",
          value: "塔吉克族",
        },
        {
          id: 40,
          info: "阿昌族",
          value: "阿昌族",
        },
        {
          id: 41,
          info: "普米族",
          value: "普米族",
        },
        {
          id: 42,
          info: "鄂温克族",
          value: "鄂温克族",
        },
        {
          id: 43,
          info: "怒族",
          value: "怒族",
        },
        {
          id: 44,
          info: "京族",
          value: "京族",
        },
        {
          id: 45,
          info: "基诺族",
          value: "基诺族",
        },
        {
          id: 46,
          info: "德昂族",
          value: "德昂族",
        },
        {
          id: 47,
          info: "保安族",
          value: "保安族",
        },
        {
          id: 48,
          info: "俄罗斯族",
          value: "俄罗斯族",
        },
        {
          id: 49,
          info: "裕固族",
          value: "裕固族",
        },
        {
          id: 50,
          info: "乌孜别克族",
          value: "乌孜别克族",
        },
        {
          id: 51,
          info: "门巴族",
          value: "门巴族",
        },
        {
          id: 52,
          info: "鄂伦春族",
          value: "鄂伦春族",
        },
        {
          id: 53,
          info: "独龙族",
          value: "独龙族",
        },
        {
          id: 54,
          info: "塔塔尔族",
          value: "塔塔尔族",
        },
        {
          id: 55,
          info: "赫哲族",
          value: "赫哲族",
        },
        {
          id: 56,
          info: "珞巴族",
          value: "珞巴族",
        },
      ],
    };
  },
  created() {
    if (this.$route.query.authenType) {
      this.authenType = this.$route.query.authenType;
    } else {
      this.authenType = 1;
    }
    this.$parent.changenavact();
    this.isCredentials = window.sessionStorage.getItem("isCredentials");
    this.getUserCred();
    this.getSoftware();
    this.getProfession();
    this.getUserInfo();
  },
  methods: {
    changeFile(e) {
      var that = this;
      // 获取文件信息 e.target.files
      that.disabled = false;
      that.file = e.target.files[0];
      var render = new FileReader();
      render.readAsDataURL(this.file); //转化base64
      render.onload = function () {
        // that.ownerInfo.imgUrl = that.file.name;
        that.ownerInfo.imgBaseStr = render.result;
        that.picimg = render.result;
      };
      this.userinforbut = true;
    },
    nativePlaceChange(item) {
      if (item) {
        this.ownerInfo.city = item[2]; //获取id

        //获取文字
        // this.ownerInfo.city =
        //   codeToText[item[0]] +
        //   "/" +
        //   codeToText[item[1]] +
        //   "/" +
        //   codeToText[item[2]];
      }
      console.log(this.ownerInfo);
    },

    getSoftware() {
      this.$http.get("/Api/user/user/softWareCombox").then((res) => {
        this.softWarelist = res.data.data;
      });
    },
    getProfession() {
      this.$http.get("/Api/user/user/professionCombox").then((res) => {
        this.professionlist = res.data.data;
      });
    },

    saveBasicinfo() {
      if (this.ownerInfo.userStageName == "") {
        this.$message.error("请输入昵称！");
        return false;
      }

      let reg = /^(?:[1-9][0-9]?|1[01][0-9]|120)$/;
      var ageerr = reg.test(this.ownerInfo.age);
      if (!ageerr) {
        this.$message.error("请输入正确的年龄！");
        return false;
      }

      this.$http.post("Api/user/user/update", this.ownerInfo).then((res) => {
        if (res.data.result == true) {
          this.$message.success("保存成功！");
          this.userinforbut = false;
          this.getUserInfo();
        } else {
          this.$message.error("保存失败！");
        }
      });
    },
    changepassword() {
      this.getnewpass();
      this.$refs.passdata.validate((valid) => {
        if (!valid) return;
        if (this.newpasserror.length > 0) return;
        this.$http.post("Api/user/user/pwd", this.passdata).then((res) => {
          if (res.data.result == true) {
            window.sessionStorage.clear();
            /* 防止切换角色时addRoutes重复添加路由导致出现警告 */
            window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },

    changeauthenType(type) {
      this.$nextTick(() => {
        this.$refs["passdata"].resetFields(); //清空表单
      });
      this.newpasserror = "";
      var that = this;
      if (type == 3) {
        that.passdata = {
          curpass: "",
          password: "",
          password2: "",
        };
      }
      that.ruoclass = "";
      that.zhongclass = "";
      that.qiangclass = "";
      that.newpasserror = "";
      that.showpasserror = false;
      that.authenType = type;
    },
    // 身份认证
    goIdcardauth() {
      this.$refs.identity.idcardauthMethod();
    },
    goBankcardauth() {
      if(this.usercard.isUserCard==0){
        this.$message.error("请先进行实名认证！");
        return false;
      }
      this.$refs.identity.bankcardauthMethod();
    },
    editBankcardauth() {
      if(this.usercard.isUserCard==0){
        this.$message.error("请先进行实名认证！");
        return false;
      }
      this.$refs.identity.editbankcardMethod();
    },

    getUserCredinfo() {
      this.authenType = 2;
      this.getUserCred();
      this.$parent.changeuserinfor();
    },

    getUserCred() {
      this.$http.get("/Api/user/user/getUserCred").then((res) => {
        this.usercard = res.data.data;
        this.phone = this.usercard.phone;
        this.hidebank = this.usercard.bankNumber;
      });
    },

    getUserInfo() {
      this.$http.get("/Api/user/user/getUserInfo").then((res) => {
        this.ownerInfo.userStageName = res.data.data.userStageName;
        this.ownerInfo.sex = res.data.data.sex;

        this.ownerInfo.nationality = res.data.data.nationality;
        this.ownerInfo.university = res.data.data.university;
        this.ownerInfo.age = res.data.data.age;

        this.ownerInfo.profession = res.data.data.profession;
        this.ownerInfo.commonSoftwareList = res.data.data.commonSoftwareList;
        this.ownerInfo.city = res.data.data.city;
        this.ownerInfo.selfIntroduction = res.data.data.selfIntroduction;
        this.ownerInfodata = JSON.parse(JSON.stringify(this.ownerInfo));

        this.picimg = Global.baseuserURL + res.data.data.imageUrl;
        if (
          window.sessionStorage.getItem("userImg") != res.data.data.imageUrl
        ) {
          window.sessionStorage.setItem("userImg", res.data.data.imageUrl);
        }
        if (
          window.sessionStorage.getItem("userStageName") !=
          res.data.data.userStageName
        ) {
          window.sessionStorage.setItem(
            "userStageName",
            res.data.data.userStageName
          );
        }
        this.$parent.changeuserinfor();
      });
    },

    changeuserinfo() {
      if (
        this.ownerInfo.userStageName !== this.ownerInfodata.userStageName ||
        this.ownerInfo.sex !== this.ownerInfodata.sex
      ) {
        this.userinforbut = true;
      } else {
        this.userinforbut = false;
      }
    },
    getnewpassError() {
      var value = this.passdata.password;
      if (value === "") {
        this.newpasserror = "请输入密码";
        this.showpasserror = true;
      } else {
        var ls = 0;
        if (value !== "") {
          if (value.match(/([a-z])+/)) {
            ls++;
          }

          if (value.match(/([0-9])+/)) {
            ls++;
          }

          if (value.match(/([A-Z])+/)) {
            ls++;
          }
          if (value.match(/([\W])+/) && !value.match(/(![\u4E00-\u9FA5])+/)) {
            ls++;
          }
          if (value.length < 6 || value.length > 16) {
            this.newpasserror = "要求6-16位字符";
            ls = 0;
          }
          if (value.match(/([\u4E00-\u9FA5])+/)) {
            this.newpasserror = "不能包含中文字符";
            ls = 0;
          }
          switch (ls) {
            case 0:
              this.newpasserror = "密码至少是字母（区分大小写）+数字,6-16位";
              break;
            case 1:
              this.newpasserror = "密码至少是字母（区分大小写）+数字,6-16位";
              break;
            case 2:
              this.newpasserror = "";
              break;
            case 3:
              this.newpasserror = "";
              break;
            case 4:
              this.newpasserror = "";
              break;
            default:
              this.passwordPercent = 0;
              break;
          }
        }
      }
    },
    getnewpass() {
      var value = this.passdata.password;
      this.zhongclass = "";
      this.qiangclass = "";
      this.ruoclass = "";
      if (value === "") {
        this.newpasserror = "请输入密码";
        this.showpasserror = true;
      } else {
        var ls = 0;
        if (value !== "") {
          if (value.match(/([a-z])+/)) {
            ls++;
          }

          if (value.match(/([0-9])+/)) {
            ls++;
          }

          if (value.match(/([A-Z])+/)) {
            ls++;
          }
          if (value.match(/([\W])+/) && !value.match(/(![\u4E00-\u9FA5])+/)) {
            ls++;
          }
          switch (ls) {
            case 0:
              this.ruoclass = "red";
              this.zhongclass = "";
              this.qiangclass = "";
              break;
            case 1:
              this.ruoclass = "red";
              this.zhongclass = "";
              this.qiangclass = "";
              break;
            case 2:
              this.ruoclass = "red";
              this.zhongclass = "";
              this.qiangclass = "";
              this.newpasserror = "";
              break;
            case 3:
              this.zhongclass = "yellow";
              this.qiangclass = "";
              this.ruoclass = "";
              this.newpasserror = "";
              break;
            case 4:
              this.qiangclass = "blue";
              this.ruoclass = "";
              this.zhongclass = "";
              this.newpasserror = "";
              break;
            default:
              this.passwordPercent = 0;
              break;
          }
        }
      }
    },

    changeUserPhone() {
      this.$refs.identity.changeUserPhoneMethod();
    },

    showPassword() {
      this.passwordType = "text";
      this.imgsrc = require("@/assets/images/eye.png");
    },
    mouseLeave() {
      this.passwordType = "password";
      this.imgsrc = require("@/assets/images/c-eye.png");
    },
    showPassword2() {
      this.passwordType2 = "text";
      this.imgsrc2 = require("@/assets/images/eye.png");
    },
    mouseLeave2() {
      this.passwordType2 = "password";
      this.imgsrc2 = require("@/assets/images/c-eye.png");
    },
    showPassword3() {
      this.passwordType3 = "text";
      this.imgsrc3 = require("@/assets/images/eye.png");
    },
    mouseLeave3() {
      this.passwordType3 = "password";
      this.imgsrc3 = require("@/assets/images/c-eye.png");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "@/assets/css/authenticat.css";

.eyeicon {
  position: absolute;
  top: 20px;
  right: 15px;
}

.pass_error {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}
</style>

