<template>
  <div class="viewnewpro">
    <div class="projectQuestion_con">
      <div class="filecenter_form head_sel">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="" style="width: 260px">
            <div>
              <el-input
                v-model="content"
                placeholder="根据项目编号查询"
                suffix-icon="el-icon-search"
                @input="changeList()"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <div v-if="dataListlen > 0" v-loading="loading" style="min-height: 700px">
        <ul class="proquestion_ul">
          <li v-for="(item, index) in dataList" :key="index">
            <p v-if="item.state == 0" class="ques_state_1">待回复</p>
            <p v-else class="ques_state_2">已回复</p>

            <div class="ques_head">
              <p class="ques_tit">{{ item.projectCode }}</p>
              <el-popover
                placement="bottom"
                width="40"
                trigger="click"
                v-if="item.state == 0"
              >
                <div
                  class="del_ques pointer"
                  @click="delLiaproblem(item.problemId)"
                >
                  删除
                </div>
                <p class="ques_opera pointer" slot="reference">
                  <img src="../../assets/images/home/more.png" alt="" />
                </p>
              </el-popover>

              <!--              <p class="ques_code">编号：{{ item.projectCode }}</p>-->
              <div class="ques_content">
                <img
                  class="ques_icon"
                  src="../../assets/images/home/问.png"
                  alt=""
                />

                <el-tooltip
                  :disabled="isShowTooltip"
                  effect="light"
                  :content="item.content"
                  placement="right"
                  popper-class="ques_popover"
                >
                  <p @mouseover="onMouseOver(item.content)" class="ques_txt">
                    <span :ref="item.content"> {{ item.content }}</span>
                  </p>
                </el-tooltip>

                <p style="clear: both"></p>
              </div>
              <p class="ques_time">提出时间：{{ item.crtDateStr }}</p>
              <div class="ques_viewimg pointer" v-if="item.fileUrlList.length > 0">
                <img src="../../assets/images/home/viewimg.png" alt="" />
                <viewer :images="item.fileUrlList">
                  <img
                    v-for="(src, index) in item.fileUrlList"
                    :src="src"
                    :key="index"
                  />
                </viewer>
              </div>
            </div>
            <div class="ques_reply_con">
              <div class="ques_content">
                <img
                  class="ques_icon"
                  :src="
                    item.state == 0
                      ? require('../../assets/images/home/答.png')
                      : require('../../assets/images/home/答-1.png')
                  "
                  alt=""
                />
                <p class="ques_txt" v-if="item.state == 0">
                  <span style="color: #636977">问题已发送，火速答复中~</span>
                </p>

                <el-tooltip
                  v-else
                  :disabled="isShowTooltip"
                  effect="light"
                  :content="item.replyContent"
                  placement="right"
                  popper-class="ques_popover"
                >
                  <p
                    @mouseover="onMouseOver(item.replyContent)"
                    class="ques_txt"
                  >
                    <span :ref="item.replyContent">{{
                      item.replyContent
                    }}</span>
                  </p>
                </el-tooltip>

                <p style="clear: both"></p>
              </div>
              <div>
                <p style="float: left" class="ques_time" v-if="item.state == 1">
                  回复时间：{{ item.replyDateStr }}
                </p>
                <p
                  class="ques_viewAnswerimg pointer"
                  style="float: right; margin-top: 5px"
                  v-if="item.replyFileUrlList.length > 0"
                >
                  <img src="../../assets/images/home/viewimg2.png" alt="" />
                  <viewer :images="item.replyFileUrlList">
                    <img
                      v-for="(src, index) in item.replyFileUrlList"
                      :src="src"
                      :key="index"
                    />
                  </viewer>
                </p>
                <p style="clear: both"></p>
              </div>
            </div>
          </li>
          <p style="clear: both"></p>
        </ul>
        <p
          class="quesmore pointer"
          v-if="form.page < pages"
          @click="clickNextPage"
        >
          查看更多 <img src="../../assets/images/more.png" alt="" />
        </p>
      </div>
      <div v-else class="history_nodata">
        <p style="text-align: center; padding-top: 300px">
          <img src="../../assets/images/nodata/quepro_nodata.png" alt="" />
        </p>
        <p style="text-align: center; font-size: 14px">暂无答疑项目</p>
      </div>
    </div>

    <!-- <el-dialog
      :visible.sync="viewimgdialog"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-carousel
        :interval="4000"
        style="width: 100%; margin-top: 1px"
        trigger="click"
        height="600px"
      >
        <el-carousel-item v-for="(item, index) in imgUrlList" :key="index">
          <el-image
            style="width: 100%; height: 100%"
            :src="item"
            fit="cover"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </el-dialog> -->
  </div>
</template>

<script>
import global from "@/assets/js/global";
import Global from "@/assets/js/global";

export default {
  computed: {
    global() {
      return global;
    },
  },
  data() {
    return {
      content: "",
      form: {
        page: 1,
        limit: 9,
        searchText: "",
      },
      currentIndex: 0,
      isShowTooltip: false,
      loading: true,
      pages: 0,
      baseUrl: global.baseURL,
      dataList: [],
      dataListlen: 1,
      srcList: ["../../assets/images/home/viewimg.png"],
      imgsrc: require("@/assets/images/home/viewimg.png"),
      imgsrc2: require("@/assets/images/home/viewimg2.png"),
      imageUrl: Global.baseURL,
      imgUrlList: [],
      viewimgdialog: false,
    };
  },

  created() {
    this.getList();
  },

  methods: {
    viewimgarr(imgarr) {
      this.imgUrlList = imgarr;
      this.viewimgdialog = true;
    },

    delLiaproblem(problemId) {
      this.$confirm("项目答疑问题是否删除？").then(() => {
        this.$http
          .delete("Api/yw/liaisonProblem/delete?id=" + problemId)
          .then((res) => {
            if (res.data.data) {
              this.$message.success("删除成功");
              this.form.page = 1;
              this.dataList = [];
              this.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
      });
    },

    getList() {
      this.$http
        .get("/Api/yw/liaisonProblem/getLiaisonProblemPage", {
          params: this.form,
        })
        .then((res) => {
          var resultData = res.data.data;

          this.loading = false;
          resultData.forEach((item) => {
            var fileUrl = [];
            item.fileUrlList.forEach((item1) => {
              fileUrl.push(this.baseUrl + item1);
            });
            item.fileUrlList = fileUrl;

            var fileUrl2 = [];
            item.replyFileUrlList.forEach((item2) => {
              fileUrl2.push(this.baseUrl + item2);
            });
            item.replyFileUrlList = fileUrl2;
          });
          this.dataList = this.dataList.concat(resultData);
          this.dataListlen = this.dataList.length;
          this.pages = res.data.pages;
        });
    },
    changeList() {
      this.form.searchText = this.content;
      this.form.page = 1;
      this.$http
        .get("/Api/yw/liaisonProblem/getLiaisonProblemPage", {
          params: this.form,
        })
        .then((res) => {
          var resultData = res.data.data;
          resultData.forEach((item) => {
            var fileUrl = [];
            item.fileUrlList.forEach((item1) => {
              fileUrl.push(this.baseUrl + item1);
            });
            item.fileUrlList = fileUrl;
          });
          this.dataList = resultData;
          this.pages = res.data.pages;
        });
    },

    clickNextPage() {
      this.form.page++;
      this.getList();
    },
    onMouseOver(str) {
      // 内容超出，显示文字提示内容
      const tag = this.$refs[str][0];
      const parentWidth = tag.parentElement.offsetHeight; // 获取元素父级可视宽度
      const contentWidth = tag.offsetHeight; // 获取元素可视宽度
      this.isShowTooltip = contentWidth <= parentWidth;
    },
    nextImage(imageUrls) {
      this.currentIndex = (this.currentIndex + 1) % imageUrls.length;
    },
    prevImage(imageUrls) {
      this.currentIndex =
        (this.currentIndex - 1 + imageUrls.length) % imageUrls.length;
    },
  },
};
</script>

<style  scoped>
@import "@/assets/css/projectQuestion.css";
.newpro_left {
  width: 352px;
  background: #ffffff;
  float: left;
  padding-bottom: 20px;
  border-radius: 8px;
}
.el-range-editor .el-range-input {
  background-color: RGB(245, 246, 250) !important;
  line-height: 1 !important;
}
.head_sel {
  width: 1120px;
  height: 60px;
}
.sel_left {
  float: left;
}
.filecenter_form {
  /* background: #fff; */
  text-align: left;
  /* padding: 0px 20px; */
}
.ques_viewimg div,
.ques_viewAnswerimg div {
  width: 104px;
  height: 20px;
  position: absolute;
  top: 0px;
  opacity: 0;
  overflow: hidden;
}
.ques_viewimg div img,
.ques_viewAnswerimg div img {
  width: 104px;
  height: 20px;
}
</style>