<template>
  <div class="myProblem">
    <!--    <div class="head_sel">-->
    <!--      <div class="sel_left">-->
    <!--        <el-input-->
    <!--          placeholder="搜索项目"-->
    <!--          suffix-icon="el-icon-search"-->
    <!--          v-model="content"-->
    <!--          @input="prosearch()"-->
    <!--        >-->
    <!--        </el-input>-->
    <!--      </div>-->
    <!--    </div>-->
    <div v-if="proshow == 1">
      <ul
        class="myProblem_con"
        style="margin-top: 20px"
        v-loading="loading"
        v-if="currentlength > 0"
      >
        <li
          class="myProblem_list"
          v-for="(item, index) in currentList"
          :key="index"
        >
          <div class="myProblem_list_con">
            <div class="infor_con">
              <div class="fixed_4">
                <p class="infor_con_name">
                  <img
                    src="../../../assets/images/outsidepro/pro_icon.png"
                    alt=""
                    v-if="item.projectTaskPojo.stepState == 1"
                  />
                  <img
                    src="../../../assets/images/outsidepro/pro_icon2.png"
                    alt=""
                    v-else
                  />
                  {{ item.projectTaskPojo.projectCode }}
                </p>
                <!-- v-if="item.estimateTime!=null&&item.estimateTime!='' -->
                <!-- v-if="item.estimateTime != null && item.estimateTime != ''" -->
                <div
                  class="infor_type"
                  v-if="item.estimateTime != null && item.estimateTime != ''"
                  style="width: 130px"
                >
                  <p>预计工作时长：{{ item.estimateTime }}h</p>
                </div>
                <div class="infor_type" v-else>
                  <p>预计工作时长：暂无</p>
                </div>
                <div class="infor_type" style="padding-left: 18px">
                  <p>
                    结构类型：{{ item.parentRealmStr + "-" + item.realmStr }}
                  </p>
                </div>

                <!-- <p class="release_time">
                <span
                  v-text="$date.Djs_timeList2(item.notarizeDate, serverTime)"
                ></span
                >发布
              </p> -->
              </div>
              <!-- 
              <div class="fixed_3">
                <div class="dateStr">
                  <p style="color: #000000; font-weight: bold; font-size: 14px">
                    {{ item.exPectDateStr }}
                  </p>
                  <p class="exPectdate_tip">最迟交付时间</p>
                </div>
              </div> -->

              <div class="fixed_3">
                <p
                  class="quantity"
                  style="color: rgb(0, 0, 0); font-weight: bold"
                >
                  {{ item.exPectDateStr }}
                </p>
                <p class="quantity_txt">最迟交付时间</p>

                <div class="downfile_box" v-if="item.requireList.length > 0">
                  <div class="downfile">
                    <!-- <p class="downfile_bg">下载项目要求附件</p> -->
                    <el-popover
                      popper-class="tab_popover"
                      placement="right"
                      width="302"
                      trigger="click"
                    >
                      <div style="padding: 20px 20px" class="require_popper">
                        <p>项目要求</p>
                        <!-- <ul
                        v-for="(item, index) in item.requireList"
                        :key="index"
                      > -->
                        <ul>
                          <li
                            v-for="(item, index) in item.requireList"
                            :key="index"
                          >
                            {{ index + 1 }}、{{ item }}
                          </li>
                        </ul>
                      </div>

                      <p class="downfile_txt pointer" slot="reference">
                        <img
                          src="../../../assets/images/outsidepro/required.png"
                          alt=""
                        />
                        项目要求
                      </p>
                    </el-popover>
                  </div>
                </div>

                <div v-else class="norequired">
                  <img
                    src="../../../assets/images/outsidepro/norequired.png"
                    alt=""
                  />无项目要求
                </div>
              </div>

              <div class="fixed_3">
                <div class="unitrice" style="margin-top: 28px">
                  <span>￥{{ item.outsidePrice }}</span>
                </div>

                <p class="quantity_txt">预估收益</p>

                <div class="downfile_box">
                  <div class="downfile">
                    <!-- <p class="downfile_bg">下载项目要求附件</p> -->
                    <el-popover
                      popper-class="tab_popover"
                      placement="right"
                      width="600"
                      trigger="click"
                    >
                      <el-table :data="item.fileList" border>
                        <el-table-column
                          property="fileName"
                          label="名称"
                          width="200"
                        ></el-table-column>
                        <el-table-column
                          property="fileSizeStr"
                          label="大小"
                          width="100"
                        ></el-table-column>
                        <el-table-column
                          property="crtDateStr"
                          label="时间"
                          width="200"
                        ></el-table-column>
                        <el-table-column label="操作" width="">
                          <template slot-scope="scope">
                            <span
                              class="pointer down"
                              style="margin-left: 10px"
                              @click="download(scope.row)"
                              >下载</span
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                      <p class="downfile_txt pointer" slot="reference">
                        <img
                          src="../../../assets/images/outsidepro/file.png"
                          alt=""
                        />
                        项目附件
                      </p>
                    </el-popover>
                  </div>
                </div>
              </div>

              <!-- 
            <p class="infor_con_name">
              <img
                src="../../../assets/images/outsidepro/pro_icon.png"
                alt=""
                v-if="item.projectTaskPojo.stepState == 1"
              />
              <img
                src="../../../assets/images/outsidepro/pro_icon2.png"
                alt=""
                v-else
              />
              {{ item.projectAlias }}
            </p>
            <p class="infor_type">
              <span>编号：{{ item.projectTaskPojo.projectCode }} </span
              ><span>结构类型：{{ item.realmStr }}</span>
            </p>
            <div class="downfile_box">
              <div class="downfile">
                <p class="downfile_bg">下载项目要求附件</p>

                <el-popover
                  popper-class="tab_popover"
                  placement="right"
                  width="500"
                  trigger="click"
                >
                  <el-table :data="item.fileList" border>
                    <el-table-column
                      width="200"
                      property="fileName"
                      label="名称"
                    ></el-table-column>
                    <el-table-column
                      property="crtDateStr"
                      label="时间"
                      width="200"
                    ></el-table-column>
                    <el-table-column label="操作" width="">
                      <template slot-scope="scope">
                        <span
                          class="pointer"
                          style="margin-left: 10px"
                          @click="
                            downfile(scope.row.fileUrl, scope.row.fileName)
                          "
                          >下载</span
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                  <p class="downfile_txt pointer" slot="reference">
                    下载附件
                    <img
                      src="../../../assets/images/outsidepro/go_icon.png"
                      alt=""
                    />
                  </p>
                </el-popover>
              </div>
              <div class="quantity">
                工程量：<span
                  >{{ item.projectTaskPojo.projectTaskQuantity }}t</span
                >
                <el-popover
                  popper-class="tab_popover"
                  placement="right"
                  width="300"
                  trigger="click"
                >
                  <el-table :data="item.quantityUpdateHistoryList" border>
                    <el-table-column
                      width="80"
                      property="modifiedValue"
                      label="工程量"
                    ></el-table-column>

                    <el-table-column
                      property="time"
                      label="时间"
                    ></el-table-column>
                  </el-table>
                  <i slot="reference" class="el-icon-caret-bottom pointer"></i>
                </el-popover>
              </div>
              <div class="dateStr">
                截止时间：<span>{{ item.exPectDateStr }}</span>
              </div>

              <div class="unitrice">
                预估收益：<span>￥{{ item.outsidePrice }}</span>
                <el-popover
                  popper-class="tab_popover"
                  placement="right"
                  width="300"
                  trigger="click"
                >
                  <el-table :data="item.priceUpdateHistoryList" border>
                    <el-table-column
                      width="80"
                      property="modifiedValue"
                      label="价格"
                    ></el-table-column>

                    <el-table-column
                      property="time"
                      label="时间"
                    ></el-table-column>
                  </el-table>
                  <i slot="reference" class="el-icon-caret-bottom pointer"></i>
                </el-popover>
              </div>
            </div> -->
            </div>
            <div class="connect">
              <p
                class="interact pointer"
                @click="getProquestion(item.projectTaskPojo.projectTaskId)"
              >
                <img
                  src="../../../assets/images/outsidepro/interact.png"
                  alt=""
                />项目答疑
              </p>

              <p
                class="statestr"
                style="width: 100px"
                v-if="item.state == 3 && item.outsideEventEntities.length > 0"
              >
                <span v-bind:style="getstateColor(item.notEventSize)">·</span>
                <span
                  v-bind:style="getstateColor(item.notEventSize)"
                  v-text="getstateStr(item.notEventSize)"
                ></span>
              </p>

              <p
                v-if="item.state == 3 && item.outsideEventEntities.length == 0"
                class="examine pointer"
                @click="subExamine(item.eventId)"
              >
                提交审核
              </p>
            </div>
          </div>

          <div
            class="myProblem_evolve"
            v-if="item.outsideEventEntities.length > 0"
          >
            <div class="evolve_box">
              <el-timeline>
                <el-timeline-item
                  placement="top"
                  v-for="(item2, index2) in item.outsideEventlist"
                  :key="index2"
                  :class="index2 == 0 ? 'timeline_act' : ''"
                >
                  <div class="timeline_con">
                    <div class="timeline_state">
                      <p class="timeline_state_icon">
                        <!-- <img
                            :src="
                            item2.eventType == 0
                              ? require('../../../assets/images/outsidepro/交付.png')
                              : item2.eventType == 1
                              ? require('../../../assets/images/outsidepro/change.png')
                              : item2.eventType == 2
                              ? require('../../../assets/images/outsidepro/xd.png')
                              : item2.eventType == 3
                              ? require('../../../assets/images/outsidepro/examine.png')
                              : ''
                          "
                            alt=""
                        /> -->
                      </p>
                      <p class="timeline_state_txt">{{ item2.eventTypeStr }}</p>
                    </div>

                    <div class="timeline_infor">
                      <p class="timeline_infor_tit" v-text="item2.content"></p>
                      <p class="timeline_infor_con">
                        <span v-if="item2.eventType != 0"
                          >任务创建时间：{{ item2.crtDateStr }}</span
                        >
                        <span
                          v-if="
                            item2.commitDateStr != null &&
                            item2.commitDateStr != ''
                          "
                          ><span v-if="item2.eventType != 0"> |</span>
                          文件交付时间：{{ item2.commitDateStr }}</span
                        >
                      </p>
                    </div>

                    <div
                      class="timeline_infor"
                      v-if="item2.modelingPlotRequire !== null"
                    >
                      <el-tooltip
                        :disabled="isShowTooltip"
                        effect="light"
                        :content="item2.modelingPlotRequire"
                        placement="right"
                        popper-class="ques_popover"
                      >
                        <p
                          class="timeline_infor_tit"
                          @mouseover="onMouseOver(item2.modelingPlotRequire)"
                        >
                          <span :ref="item2.modelingPlotRequire">{{
                            item2.modelingPlotRequire
                          }}</span>
                        </p>
                      </el-tooltip>
                      <p class="timeline_infor_con">
                        <span>深化内容及要求</span>
                      </p>
                    </div>

                    <!-- <div
                      class="timeline_problem pointer"
                      @click="
                        getViewQualityList(item2.contactId, item2.eventState)
                      "
                      v-if="item2.eventType == 3"
                    >
                      <p class="problem_num">{{ item2.qualityDetailsCount }}</p>
                      <p class="problem_tit">问题条数</p>
                    </div> -->

                    <div class="problem_operate">
                      <!--  -->
                      <div
                        v-if="item2.eventType == 3"
                        class="viewQuality pointer"
                        @click="
                          getViewQualityList(item2.contactId, item2.eventState)
                        "
                      >
                        查看
                      </div>
                      <div
                        v-if="item2.eventState == 0"
                        class="timeline_operate pointer"
                        @click="
                          secondSubexa(
                            item2.contactId,
                            item2.eventType,
                            item2.id
                          )
                        "
                      >
                        {{ item2.eventType == 3 ? "再次提交审核" : "提交审核" }}
                      </div>
                    </div>

                    <el-popover
                      popper-class="tab_popover"
                      placement="right"
                      width="600"
                      trigger="click"
                      v-if="item2.fileList.length > 0"
                    >
                      <el-table :data="item2.fileList" border>
                        <el-table-column
                          width="200"
                          property="fileName"
                          label="名称"
                        ></el-table-column>
                        <el-table-column
                          property="fileSizeStr"
                          label="大小"
                          width="100"
                        ></el-table-column>
                        <el-table-column
                          property="crtDateStr"
                          label="时间"
                          width="200"
                        ></el-table-column>
                        <el-table-column label="操作" width="">
                          <template slot-scope="scope">
                            <span
                              class="pointer down"
                              style="margin-left: 10px"
                              @click="
                                downfile(scope.row.fileUrl, scope.row.fileName)
                              "
                              >下载</span
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                      <!-- <i
                      slot="reference"
                      class="el-icon-caret-bottom pointer"
                    ></i> -->

                      <div class="thisdelivery" slot="reference">
                        <img
                          src="../../../assets/images/outsidepro/wj-1.png"
                          alt=""
                        />文件
                      </div>

                      <!-- <el-button slot="reference">click 激活</el-button> -->
                    </el-popover>

                    <div v-else class="nodelivery">
                      <img
                        src="../../../assets/images/outsidepro/wj-2.png"
                        alt=""
                      />文件
                    </div>

                    <!-- <el-popover
                      popper-class="tab_popover"
                      placement="right"
                      width="600"
                      trigger="click"
                      v-if="
                        (item2.eventType == 1 || item2.eventType == 2) &&
                        item2.sendFileList.length > 0
                      "
                    >
                      <el-table :data="item2.sendFileList" border>
                        <el-table-column
                          width="200"
                          property="fileName"
                          label="名称"
                        ></el-table-column>
                        <el-table-column
                          property="fileSizeStr"
                          label="大小"
                          width="100"
                        ></el-table-column>
                        <el-table-column
                          property="crtDateStr"
                          label="时间"
                          width="200"
                        ></el-table-column>
                        <el-table-column label="操作" width="">
                          <template slot-scope="scope">
                            <span
                              class="pointer down"
                              style="margin-left: 10px"
                              @click="
                                downfile(scope.row.fileUrl, scope.row.fileName)
                              "
                              >下载</span
                            >
                          </template>
                        </el-table-column>
                      </el-table>

                      <p
                        v-if="item2.eventType == 1"
                        class="revisefile"
                        slot="reference"
                      >
                        <img
                          src="../../../assets/images/outsidepro/change2.png"
                          alt=""
                        />变更文件
                      </p>
                      <p
                        v-if="item2.eventType == 2"
                        class="revisefile"
                        slot="reference"
                      >
                        <img
                          src="../../../assets/images/outsidepro/revise.png"
                          alt=""
                        />修订文件
                      </p>
                    </el-popover> -->
                  </div>
                  <!-- <el-card>
                  <el-table :data="item.rows" border>
                    <el-table-column
                      v-for="(item, index) in item.dictList"
                      :prop="item.indexCode"
                      :label="item.label"
                      :key="index"
                      align="center"
                    ></el-table-column>
                  </el-table>
                </el-card> -->
                </el-timeline-item>
              </el-timeline>
            </div>
            <p
              class="viewmore pointer"
              v-if="
                item.outsideEventEntities.length > 2 &&
                item.outsideEventEntities > item.outsideEventlist
              "
              @click="getmoredata(index)"
            >
              查看更多 <img src="../../../assets/images/more.png" alt="" />
            </p>
          </div>
        </li>
      </ul>
      <div v-else class="history_nodata">
        <p style="text-align: center; padding-top: 300px">
          <img src="../../../assets/images/nodata/mypro_nodata.png" alt="" />
        </p>
        <p style="text-align: center; font-size: 14px">暂无我的项目</p>
      </div>
    </div>
    <div v-if="proshow == 2">
      <historyPro ref="historychild" />
    </div>
    <el-dialog
      title="提交审核"
      :visible.sync="dialogExamine"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="deeprequire">
        <el-popover
          popper-class="tab_popover"
          placement="left"
          width="400"
          trigger="click"
        >
          <div class="ques_tipstxt">
            <p style="color: #91959f">
              此处填与深化内容，及客户出图要求，样例如下:
            </p>
            <p>1.状态54、71</p>
            <p>2.状态5，棕托</p>
            <p>
              3.状态101 备注:直拉条车丝70，斜拉条车丝60
              HJ1a、HJ2a、HJ3a中间起拱，起拱约65mm
              HJ4a、HJ5a、HJ6a中间起拱，起拱约60mm
            </p>
          </div>

          <div class="requiretips" slot="reference">
            <img src="../../../assets/images/outsidepro/tips.png" alt="" />
          </div>
        </el-popover>

        <div class="deepreq_input">
          <el-form>
            <el-form-item>
              <el-input
                type="textarea"
                placeholder="深化内容及要求:请填写深化内容及需要注意的图纸出图要求"
                v-model="des"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="upload_box">
        <div class="upload_txt">
          <p class="upload_txt_1">
            <img
              src="../../../assets/images/outsidepro/upload_icon.png"
              alt=""
            />选择文件
          </p>
          <p class="upload_txt_2">请上传后缀名为zip、7z、rar类型文件</p>
        </div>

        <div class="upload_butcon">
          <el-upload
            class="upload-demo"
            action=""
            accept=".zip, .7z, .rar"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :before-remove="beforeRemove"
            :http-request="uploadFile"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button type="primary">选择文件</el-button>
          </el-upload>
        </div>
      </div>

      <div class="file_con" v-if="fileList.length > 0">
        <p class="filecon_del" @click="filedel()">
          <img src="../../../assets/images/outsidepro/file_del.png" alt="" />
        </p>
        <p class="file_icon">
          <img src="../../../assets/images/outsidepro/file_icon.png" alt="" />
        </p>
        <div class="filename_con">
          <p class="filename">{{ fileList[0].name }}</p>
          <p class="file_progress" v-if="progressshow">
            <el-progress
              type="line"
              status="success"
              :stroke-width="10"
              :text-inside="true"
              :percentage="uploadPercentage"
            ></el-progress>
          </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button
          class="confirm_but"
          :disabled="examineabled"
          type="primary"
          @click="goexamine(1)"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="提交审核"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="problem_box">
        <div
          class="problem_list"
          v-for="(item, index) in qualitylist"
          :key="index"
        >
          <div class="problem_type">
            <p class="type_txt">
              <span></span>类型：{{ item.qualityTypeName }}
            </p>
            <p class="modify">
              <el-checkbox
                v-model="item.checked"
                :lable="item.qualityId"
                @change="changemodify(index)"
                >无需修改</el-checkbox
              >
            </p>
            <p style="clear: both"></p>
          </div>

          <p class="problem_txt">标题：{{ item.title }}</p>
          <p class="problem_txt">内容：{{ item.content }}</p>

          <div class="problem_con">
            <p class="problem_icon">
              <el-image
                v-for="(item2, index) in item.imageArray"
                :key="index"
                :src="imageUrl + item2"
                :preview-src-list="getSrcList(index, item.imageArray)"
                alt=""
              />
            </p>
          </div>

          <el-form
            style="width: 420px; margin-left: 20px; margin-top: 10px"
            v-if="item.isshow"
          >
            <el-form-item>
              <el-input
                type="text"
                placeholder="请输入原因~"
                v-model="item.reason"
                @input="inpChange($event)"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>

        <!-- <div class="problem_list"></div> -->
      </div>

      <div class="upload_box">
        <div class="upload_txt">
          <p class="upload_txt_1">
            <img
              src="../../../assets/images/outsidepro/upload_icon.png"
              alt=""
            />选择文件
          </p>
          <p class="upload_txt_2">请上传后缀名为zip、7z、rar类型文件</p>
        </div>

        <div class="upload_butcon">
          <el-upload
            class="upload-demo"
            action=""
            accept=".zip, .7z, .rar"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :before-remove="beforeRemove"
            :http-request="uploadFile"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button type="primary">选择文件</el-button>
          </el-upload>
        </div>
      </div>

      <div class="file_con" v-if="fileList.length > 0">
        <p class="filecon_del" @click="filedel()">
          <img src="../../../assets/images/outsidepro/file_del.png" alt="" />
        </p>
        <p class="file_icon">
          <img src="../../../assets/images/outsidepro/file_icon.png" alt="" />
        </p>
        <div class="filename_con">
          <p class="filename">{{ fileList[0].name }}</p>
          <p class="file_progress" v-if="progressshow">
            <el-progress
              type="line"
              status="success"
              :stroke-width="10"
              :text-inside="true"
              :percentage="uploadPercentage"
            ></el-progress>
          </p>
        </div>
      </div>

      <!-- <div class="uploadbox">
        <div class="upload_input">
          <p class="upload_tit" v-if="fileList.length > 0">
            {{ fileList[0].name }}
          </p>
          <p class="upload_tit" v-else>附件标题</p>
          <p class="upload_but">
            <el-upload
              class="upload-demo"
              action=""
              accept=".zip, .7z, .rar"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-upload="beforeUpload"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :http-request="uploadFile"
              :on-exceed="handleExceed"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">选择文件</el-button>
            </el-upload>
          </p>
        </div>

        <progress
          v-if="progressshow"
          class="custom-progress-bar"
          :value="uploadPercentage"
          max="100"
          style="width: 410px"
        >
          {{ uploadPercentage }}%
        </progress>
      </div> -->

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button
          class="confirm_but"
          :disabled="examineabled"
          type="primary"
          @click="goexamine(2)"
          >提交</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="品控问题"
      :visible.sync="viewQualityProblem"
      width="490px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="problem_box" style="max-height: 620px">
        <div
          class="problem_list"
          v-for="(item, index) in viewQualitylist"
          :key="index"
        >
          <div class="problem_type">
            <p class="type_txt">
              <span></span>类型：{{ item.qualityTypeName }}
            </p>
            <p style="clear: both"></p>
          </div>

          <p class="problem_txt">标题：{{ item.title }}</p>
          <p class="problem_txt">内容：{{ item.content }}</p>

          <div class="problem_con">
            <p class="problem_icon">
              <el-image
                v-for="(item2, index) in item.imageArray"
                :key="index"
                :src="imageUrl + item2"
                :preview-src-list="getSrcList(index, item.imageArray)"
                alt=""
              />
            </p>
          </div>
        </div>
      </div>
    </el-dialog>

    <proQuestion
      ref="proques"
      v-if="proQuestionShow"
      :proQuestionShow="proQuestionShow"
      :projectTaskId="projectTaskId"
      :addFlag="1"
      @closeDialog="closeDialog"
    ></proQuestion>
  </div>
</template>

<script>
import proQuestion from "./proQuestion.vue";
import Global from "@/assets/js/global";
import streamSaver from "streamsaver";

export default {
  components: {
    proQuestion,
  },
  data() {
    return {
      currentList: [],
      currentlength: 1,
      des: "",

      gridData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      proshow: 1,
      dataList: [
        {
          sj: "2022-09-01",
          dictList: [
            {
              indexCode: "name",
              label: "姓名",
            },
            {
              indexCode: "sex",
              label: "性别",
            },
            {
              indexCode: "age",
              label: "年龄",
            },
          ],
          rows: [
            {
              name: "小明",
              sex: "女",
              age: "18",
            },
          ],
        },
      ],
      checked: true,
      dialogVisible: false,
      centerDialogVisible: true, //弹框显隐状态
      viewQualityProblem: false, //查看品控单问题
      dialogExamine: false,
      fileList: [],
      // serverTime: "",
      eventId: 0,
      file: {},
      viewQualitylist: [],
      qualitylist: [],
      proQuestionShow: false,
      projectTaskId: 0,
      imageUrl: Global.baseURL,
      content: "",
      searchText: "",
      statestyle: {
        color: "red",
      },
      loading: true,
      uploadPercentage: 20,
      progressshow: false,
      examineabled: false,
      chunks: [],
      // 分片下载大小 5MB
      chunkSize: 1024 * 1024 * 5,
      // 文件总大小(需要请求后端获得)
      fileSize: 0,
      chunksNum: 0,
      reviewcon: "",
      isShowTooltip: false,
    };
  },
  created() {
    // this.serverTime = new Date().getTime();
    this.content = "";
    // this.getCurrentEvent();
  },
  mounted() {
    // this.Djs_time();
  },
  methods: {
    getSrcList(index, fileList) {
      var urlList = [];
      fileList.forEach((item) => {
        let url = this.imageUrl + item;
        urlList.push(url);
      });
      return urlList.slice(index).concat(urlList.slice(0, index));
    },
    myProMethod() {
      this.getCurrentlist();
    },

    tuidan(projectTaskId) {
      this.$http
        .post(
          "/Api/yw/projectTaskOutside/chargeBack?projectTaskId=" + projectTaskId
        )
        .then((res) => {
          if (res.data.result == true) {
            this.getCurrentlist();
            this.$message.success("退单成功");
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getCurrentEvent() {
      this.content = "";
      this.getCurrentlist();
    },

    getCurrentlist() {
      this.proshow = 1;
      this.$http
        .get(
          "/Api/yw/projectTaskOutside/getOnLoadingProject2?searchText=" +
            this.searchText
        )
        .then((res) => {
          this.loading = false;
          this.currentList = res.data.data;
          this.currentlength = this.currentList.length;
          this.getEventlist();
        });
    },

    getEventlist() {
      for (var i = 0; i < this.currentList.length; i++) {
        this.currentList[i].outsideEventlist = [];

        if (this.currentList[i].outsideEventEntities.length > 2) {
          this.currentList[i].outsideEventlist = this.currentList[
            i
          ].outsideEventEntities.slice(0, 2);
        } else {
          this.currentList[i].outsideEventlist =
            this.currentList[i].outsideEventEntities;
        }
      }
    },
    prosearch() {
      if (this.proshow == 1) {
        this.searchText = this.content;
        this.getCurrentlist();
      } else {
        this.$nextTick(() => {
          this.$refs.historychild.hisProsearch(this.content);
        });
      }
    },
    getmoredata(index) {
      this.currentList[index].outsideEventlist =
        this.currentList[index].outsideEventEntities;
    },

    subExamine(eventId) {
      this.des = "";

      this.dialogExamine = true;
      this.fileList = [];
      this.eventId = eventId;
    },

    // Djs_time: function () {
    //   var _this = this;
    //   this.timer = setInterval(() => {
    //     _this.serverTime = _this.serverTime + 1000;
    //   }, 1000);
    // },
    onMouseOver(str) {
      // 内容超出，显示文字提示内容
      const tag = this.$refs[str][0];
      const parentWidth = tag.parentElement.offsetHeight; // 获取元素父级可视宽度
      const contentWidth = tag.offsetHeight; // 获取元素可视宽度
      this.isShowTooltip = contentWidth <= parentWidth;
    },
    getstateStr(size) {
      if (size > 0) {
        return "进行中";
      } else {
        return "平台审核中";
      }
    },
    getstateColor(size) {
      if (size > 0) {
        return "";
      } else {
        return { color: "#E15A55" };
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogExamine = false;
      this.viewQualityProblem = false;
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {},
    handleExceed(files, fileList) {},

    // 上传文件之前
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ["zip", "7z", "rar"];

      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 zip、7z、rar格式");
        return false;
      }

      const isLt2M = file.size / 1024 / 1024 < 100;

      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过100MB");
        return false;
      }
      this.fileList = [];
      this.fileList.push(file);
    },

    filedel() {
      this.fileList = [];
      this.uploadPercentage = 0;
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    uploadFile(file) {
      this.file = file;
    },
    changemodify(index) {
      this.$forceUpdate();
      if (this.qualitylist[index].checked) {
        this.qualitylist[index].isshow = true;
      } else {
        this.qualitylist[index].isshow = false;
      }
    },
    goexamine(type) {
      var dataarr = [];
      if (type == 1) {
        if (this.des.length == 0 || this.des.length == "") {
          this.$message.error("出图要求为必填项！");
          return false;
        }
      }

      this.examineabled = true;
      for (var i = 0; i < this.qualitylist.length; i++) {
        var jsondata = {};
        if (this.qualitylist[i].checked) {
          jsondata.id = this.qualitylist[i].qualityDetailsId;
          jsondata.reason = this.qualitylist[i].reason;
          dataarr.push(jsondata);
        }
      }

      if (
        dataarr.length < this.qualitylist.length &&
        this.fileList.length == 0
      ) {
        this.$message.error("请上传附件！");
        this.examineabled = false;
        this.progressshow = false;
        return false;
      }

      let FormDatas = new FormData();
      if (this.fileList.length > 0) {
        var test = this.file.file.name.substring(
          this.file.file.name.lastIndexOf(".") + 1
        );
        if (test !== "zip" && test !== "rar") {
          this.$message({
            message: "上传文件只能是 zip、rar格式!",
            type: "warning",
          });
          this.examineabled = false;
          this.progressshow = false;
          return false;
        }

        FormDatas.append("file", this.file.file);
      }
      var json = JSON.stringify(dataarr);
      FormDatas.append("des", this.des);
      FormDatas.append("eventId", this.eventId);
      FormDatas.append("json", json);

      this.$http
        .post("/Api/yw/outsideEvent/upload", FormDatas, {
          onUploadProgress: (progressEvent) => {
            if (this.fileList.length > 0) {
              this.progressshow = true;
            } else {
              this.progressshow = false;
            }

            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          },
        })
        .then((res) => {
          if (res.data.result == true) {
            this.$message.success(res.data.data);
            this.dialogExamine = false;
            this.dialogVisible = false;

            this.getCurrentlist();
          } else {
            this.$message.error(res.data.msg);
          }
          this.progressshow = false;
          this.examineabled = false;
        });
    },

    getViewQualityList(qualityId, state) {
      let flag = state == 1 ? -3 : 0;
      this.viewQualityProblem = true;
      this.$http
        .get(
          "/Api/yw/qualityDetails/queryByQualityId?qualityId=" +
            qualityId +
            "&flag=" +
            flag
        )
        .then((res) => {
          this.viewQualitylist = res.data.data;
        });
    },

    // 再次提交审核

    secondSubexa(qualityId, eventType, eventId) {
      this.eventId = eventId;
      this.des = "";
      this.fileList = [];
      if (eventType == 1 || eventType == 2) {
        this.dialogExamine = true;
      } else {
        this.dialogVisible = true;
      }
      this.progressshow = false;
      this.$http
        .get(
          "/Api/yw/qualityDetails/queryByQualityId?qualityId=" +
            qualityId +
            "&flag=0"
        )
        .then((res) => {
          this.qualitylist = res.data.data;
          console.log(this.qualitylist);

          for (var i = 0; i < this.qualitylist.length; i++) {
            this.qualitylist[i].checked = false;
            this.qualitylist[i].reason = "";
            this.qualitylist[i].isshow = false;
          }
        });
    },

    // 获取历史记录

    getHistorypro() {
      this.proshow = 2;
      this.content = "";
      this.searchText = "";
      this.$nextTick(() => {
        this.$refs.historychild.hisProMethod();
      });
    },

    getProquestion(projectTaskId) {
      this.projectTaskId = projectTaskId;
      this.proQuestionShow = true;
      this.$nextTick(() => {
        this.$refs.proques.getProliasionMethod();
      });
    },
    closeDialog() {
      //关闭“弹窗组件”
      this.proQuestionShow = false;
    },

    downfile(fileurl, fileName) {
      fetch(this.imageUrl + fileurl)
        .then((res) => res.blob())
        .catch(function () {
          // 出错了;等价于 then 的第二个参数,但这样更好用更直观 :
          this.$alert("未找到该图片", {
            confirmButtonText: "确定",
          });
        })
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          let link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName; // 下载文件的名字
          document.body.appendChild(link);
          link.click();
        });
    },
    inpChange(e) {
      this.$forceUpdate();
    },
    download(row) {
      // 定义 存储所有的分片的数组
      this.chunks = [];
      // 分片下载大小 5MB
      this.chunkSize = 1024 * 1024 * 5;
      // 文件总大小(需要请求后端获得)
      this.fileSize = row.fileSize;
      // 计算分片数量
      this.chunksNum = Math.ceil(this.fileSize / this.chunkSize);
      this.downloadChunkFile(0, row);
    },
    async downloadChunkFile(chunkIdx, row) {
      // if (chunkIdx >= this.chunksNum) {
      //   alert('分片索引不可超过分片数量')
      //   return
      // }
      //
      let start = chunkIdx * this.chunkSize;
      let end = Math.min(start + this.chunkSize - 1, this.fileSize - 1);
      const range = `bytes=${start}-${end}`;
      streamSaver.mitm = Global.serveUrl + "mitm.html?version=2.0.0";
      // 使用 Fetch API 请求文件
      fetch(
        Global.baseURL2 +
          "Api/yw/profile/download?rangeHeader=" +
          range +
          "&path=" +
          row.fileUrl +
          "&fileName=" +
          row.fileName
      ).then((res) => {
        this.chunks.push(res.data);
        // 创建写入流 filename为下载的文件名
        const fileStream = streamSaver.createWriteStream(row.fileName, {
          size: res.headers.get("content-length"),
        });
        const readableStream = res.body;
        if (window.WritableStream && readableStream.pipeTo) {
          return readableStream.pipeTo(fileStream).then(() => {});
        }
        window.writer = fileStream.getWriter();
        const reader = res.body.getReader();
        const pump = () =>
          reader
            .read()
            .then((res) =>
              res.done
                ? window.writer.close()
                : window.writer.write(res.value).then(pump)
            );
        pump();
      });

      // this.$http({
      //   url: "Api/yw/profile/download2",
      //   method: 'GET',
      //   params: {
      //     rangeHeader: range,
      //     path: row.fileUrl,
      //     fileName: row.fileName,
      //   },
      //   responseType: 'arraybuffer'
      // }).then(response => {
      //
      //   this.chunks.push(response.data)
      //   if (chunkIdx == this.chunksNum - 1) {
      //     // 下载好了
      //     console.log(this.chunks, 'chunks');
      //     // 组合chunks到单个文件
      //     const blob = new Blob(this.chunks);
      //     console.log(blob, 'blob');
      //     const link = document.createElement('a');
      //     link.href = window.URL.createObjectURL(blob);
      //     link.download = 'demo.mp4';
      //     link.click();
      //     return
      //   } else {
      //     ++chunkIdx
      //     this.downloadChunkFile(chunkIdx,row)
      //   }
      // })
    },
  },
};
</script>

<style scoped>
.head_sel {
  width: 1120px;
  margin: 10px auto;
  height: 60px;
}
</style>