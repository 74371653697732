var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#e9e9eb","margin-top":"20px"}},[_c('div',{staticClass:"cliennew_box"},[_c('div',{staticClass:"cliennew_head"},[_c('ul',{staticClass:"cliennew_tab"},[_c('li',{class:_vm.headerClass1,on:{"click":function($event){return _vm.messageHeader(2)}}},[_vm._v(" 项目消息 "),(_vm.noreadlength > 0)?_c('span',{staticClass:"noreadicon"}):_vm._e()]),_c('li',{class:_vm.headerClass2,on:{"click":function($event){return _vm.messageHeader(1)}}},[_vm._v("系统消息")])]),_c('div',{staticClass:"cliennew_search"},[_c('el-input',{staticClass:"lia_proname",attrs:{"placeholder":"根据项目编号查询","suffix-icon":"el-icon-search"},on:{"input":function($event){return _vm.changestuType()}},model:{value:(_vm.queryLimit.searchText),callback:function ($$v) {_vm.$set(_vm.queryLimit, "searchText", $$v)},expression:"queryLimit.searchText"}})],1),_c('div',{staticClass:"cliennew_but"},[_c('div',{staticClass:"allread pointer",on:{"click":_vm.allreadbut}},[_c('img',{attrs:{"src":require("../../assets/images/home/allread.png"),"alt":""}}),_vm._v("全部已读 ")]),_c('div',{staticClass:"newsclear pointer",on:{"click":_vm.delallnotice}},[_c('img',{attrs:{"src":require("../../assets/images/home/clear.png"),"alt":""}}),_vm._v("清空消息 ")])])]),(_vm.noticelist.length > 0)?_c('div',[_c('div',{staticClass:"cliennew_con"},_vm._l((_vm.noticelist),function(item,index){return _c('div',{key:index,staticClass:"cliennew_list"},[_c('p',{staticClass:"cliennew_icon"},[_c('img',{attrs:{"src":item.msgType == 1
                  ? require('../../assets/images/home/notice_icon_3.png')
                  : item.msgType == 2
                  ? require('../../assets/images/home/notice_icon_1.png')
                  : item.msgType == 3
                  ? require('../../assets/images/home/notice_icon_2.png')
                  : '',"alt":""}})]),(item.readStatus == 0)?_c('div',{staticClass:"cliennew_time_box pointer",on:{"click":function($event){return _vm.read_but(item.offlineId)}}},[_c('p',{staticClass:"cliennew_state"},[_vm._v(_vm._s(item.pushTypeText))]),_c('p',{staticClass:"cliennew_txt"},[_vm._v(" "+_vm._s(item.pushContent)+" ")]),_c('p',{staticClass:"cliennew_time"},[_vm._v(_vm._s(item.crtDateStr))])]):_c('div',{staticClass:"cliennew_time_box clienne_read"},[_c('p',{staticClass:"cliennew_state"},[_vm._v(_vm._s(item.pushTypeText))]),_c('p',{staticClass:"cliennew_txt"},[_vm._v(" "+_vm._s(item.pushContent)+" ")]),_c('p',{staticClass:"cliennew_time"},[_vm._v(_vm._s(item.crtDateStr))])]),_c('p',{staticStyle:{"clear":"both"}})])}),0),_c('div',{staticClass:"pagingbox"},[_c('el-pagination',{attrs:{"current-page":_vm.queryLimit.page,"page-sizes":_vm.pageSizes,"page-size":_vm.queryLimit.limit,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.totalCount},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)]):_c('div',[_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cliennew_con"},[_c('p',{staticStyle:{"padding-top":"320px","text-align":"center"}},[_c('img',{attrs:{"src":require("../../assets/images/home/nonotice_data.png"),"alt":""}})]),_c('p',{staticClass:"nonotice"},[_vm._v("暂无消息")])])
}]

export { render, staticRenderFns }