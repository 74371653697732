<template>
  <div class="outsidePro" style="background: #f5f6fa">
    <div id="outsidecon">
      <myPro @gomyproMethod="gomyproMethod" ref="myProlist" />
    </div>
  </div>
</template>

<script>
import myPro from "./component/myProblem";
export default {
  components: {
    myPro,
  },
  name: "Home",
  data() {
    return {
      proshow: 1,
      residueCount: 0,
      inCount: 0,
    };
  },
  created() {
    this.getproCount();
    this.gomyproMethod();
  },
  methods: {
    getproCount() {
      this.$http.get("/Api/yw/projectTaskOutside/getMyCount").then((res) => {
        this.residueCount = res.data.data.residueCount;
        this.inCount = res.data.data.inCount;
      });
    },

    gettapPro(type) {
      this.proshow = type;
      if (type == 1) {
        this.$nextTick(() => {
          this.$refs.newProlist.newProMethod();
        });
      } else {
        this.$nextTick(() => {
          this.$refs.myProlist.myProMethod();
        });
      }
    },

    gomyproMethod() {
      this.$parent.changenavact();
      this.proshow = 2;
      this.$nextTick(() => {
        this.$refs.myProlist.myProMethod();
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "@/assets/css/outsidePro.css";
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  list-style-type: none;
  /* margin: 0 10px; */
}
/* a {
  color: #42b983;
} */
</style>
