<template>
  <div style="position: fixed; height: 100%">
    <div class="login_bg">
      <div class="login" v-if="!loginrole">
        <div class="login_right">
          <div class="login_header">
            <div class="login_tit">登录</div>
            <!-- <div
              class="login_nav pointer"
              :class="[logintype == 1 ? 'login_nactive' : '']"
              @click="logingettype(1)"
            >
              手机验证码登录
            </div> -->

            <ul class="login_navlist">
              <li
                :class="
                  logintype == 1 ? 'login_navlist_act pointer' : 'pointer'
                "
                @click="changeLogintype('1')"
              >
                密码登录
              </li>
              <li
                :class="
                  logintype == 2 ? 'login_navlist_act pointer' : 'pointer'
                "
                @click="changeLogintype('2')"
              >
                验证码登录
              </li>
            </ul>
          </div>
          <p class="errorMsg" style="color: red" v-if="errorStr !== ''">
            温馨提示：<span>{{ errorStr }}</span>
          </p>

          <div style="width: 360px; margin: 0 auto">
            <el-form
              :inline="true"
              :model="ownerInfo"
              :rules="rules"
              ref="ownerInfo"
              class=""
            >
              <p class="input_label">手机号</p>
              <el-form-item label="" prop="phone">
                <div
                  class="pass_input user_input pointer"
                  style="margin-top: 10px"
                >
                  <img
                    src="../../assets/images/phone.png"
                    alt=""
                    class="passicon"
                  />
                  <el-input
                    type="text"
                    v-model="ownerInfo.phone"
                    autocomplete="off"
                    placeholder="请输入您的手机号"
                  ></el-input>
                </div>
              </el-form-item>

              <div v-if="logintype == 1">
                <p class="input_label">输入密码</p>
                <el-form-item label="" prop="password">
                  <div
                    class="pass_input user_input pointer"
                    style="margin-top: 10px"
                  >
                    <img
                      src="../../assets/images/mm.png"
                      alt=""
                      class="passicon"
                    />
                    <el-input
                      :type="passwordType"
                      v-model="ownerInfo.password"
                      auto-complete="new-password"
                      placeholder="请输入密码"
                      @keyup.enter.native="onSubmit()"
                    ></el-input>
                    <img
                      :src="imgsrc"
                      alt=""
                      class="eyeicon"
                      @mouseover="showPassword"
                      @mouseleave="mouseLeave"
                    />
                  </div>
                </el-form-item>
              </div>

              <div v-if="logintype == 2">
                <p class="input_label">验证码</p>
                <el-form-item prop="code">
                  <div style="margin-top: 10px">
                    <div class="code_input" style="width: 200px; float: left">
                      <el-input
                        type="text"
                        placeholder="请输入验证码"
                        v-model="ownerInfo.code"
                        autocomplete="off"
                        maxlength="6"
                        @keyup.enter.native="onSubmit()"
                      >
                      </el-input>
                    </div>
                    <div
                      slot="suffix"
                      style="color: #7856fd"
                      class="code pointer"
                      @click="sendCode()"
                    >
                      {{ codeStr }}
                    </div>
                  </div>
                </el-form-item>
              </div>
              <p class="protoAgree" style="margin-top: 220px">
                <el-checkbox v-model="checked"></el-checkbox>
                我已阅读并同意<span class="pointer" @click="goprotoAgree(1)"
                  >《用户服务协议》</span
                >和<span class="pointer" @click="goprotoAgree(2)"
                  >《个人信息保护政策》</span
                >
              </p>
              <el-form-item style="margin-top: 12px">
                <Vcode :show="isShow" @success="success" @close="close" />
                <el-button
                  type="primary"
                  class="login_default"
                  @click="onSubmit"
                  >登录
                </el-button>
              </el-form-item>
              <!-- <el-form-item style="margin-top: 0px"
                ><el-checkbox v-model="checked">同意用户协议</el-checkbox>
              </el-form-item> -->
              <div class="register_but">
                没有帐号,<span class="pointer" @click="register()">去注册</span>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="login_bottom">
      <div class="recordNo">
        <div class="login_bottom_text">
          <span
            ><img
              src="../../assets/images/bottom/copyright.png"
              alt=""
              style="vertical-align: middle"
          /></span>
          <span>zzyjzkj 2021 - {{ year }} 山西众之翼建筑科技有限公司</span>
          <span>|</span>
          <span style="margin-top: -1px"
            ><img
              src="../../assets/images/bottom/record_icon.png"
              alt=""
              style="vertical-align: middle"
          /></span>
          <span
            ><a
              target="_blank"
              class="icphref"
              style="text-decoration-line: none"
              href="https://beian.miit.gov.cn/"
              >晋ICP备2022000048号</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vcode from "vue-puzzle-vcode";
export default {
  data() {
    var phoneReg =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    var validatePhone = (rule, value, callback) => {
      let phone = value.trim();
      if (!phone) {
        return callback(new Error("号码不能为空"));
      }
      if (!phoneReg.test(phone)) {
        callback(new Error("号码格式有误"));
        this.isCode = false;
      } else {
        callback();
        this.isCode = true;
      }
    };
    return {
      isExpired: false,
      scene: "",
      imgUrl: "",
      ownerInfo: {
        phone: "",
        code: "",
        password: "",
      },
      text: "二维码已过期",
      rules: {
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
      },
      timer: "",
      timer2: "",
      isCode: true,
      errorStr: "",
      isShow: false,
      logintype: 1,
      loginrole: false,
      count: 0, //发送验证码次数
      rolelist: [],
      ownerRoleId: "",
      year: "",
      checked: false,
      codeStr: "获取验证码",
      passwordType: "password",
      imgsrc: require("@/assets/images/c-eye.png"),
    };
  },
  computed: {
    maskStyle() {
      return {
        display: this.isExpired ? "block" : "none",
      };
    },
  },
  components: {
    Vcode,
  },
  created() {
    const timeOne = new Date();
    this.year = timeOne.getFullYear();
  },
  beforeDestroy() {
    clearInterval(this.timer2);
    clearInterval(this.timer);
  },
  methods: {
    //登录
    onSubmit() {
      let _this = this;
      _this.$refs.ownerInfo.validate((valid) => {
        if (valid) {
          this.loginsubmit();
        }
      });
    },
    setCookie(name, value, daysTolive) {
      let cookie = name + "=" + value;
      if (typeof daysTolive === "number") {
        cookie += ";max-age=" + daysTolive * 60 * 60 * 24;
        document.cookie = cookie;
      }
    },

    userInit(data) {
      window.sessionStorage.setItem("token", "Bearer " + data.accessToken);
      window.sessionStorage.setItem("isCredentials", data.isCredentials);
      window.sessionStorage.setItem("skillCertState", data.skillCertState);
      window.sessionStorage.setItem("userImg", data.imageUrl);
      window.sessionStorage.setItem("showCredentialsFlag", true);
      window.sessionStorage.setItem("userStageName", data.userStageName);

      window.sessionStorage.setItem("userAnnounce", 0);

      this.$router.push("/leftNavigation");
    },
    loginsubmit() {
      if (!this.checked) {
        this.$message.error(
          " 请先阅读并同意《用户服务协议》和《个人信息保护政策》"
        );
        return false;
      }

      var url = "";
      if (this.logintype == 1) {
        url =
          "user/login?phone=" +
          this.ownerInfo.phone +
          "&password=" +
          this.ownerInfo.password;
      } else {
        url =
          "user/loginByPhone?phone=" +
          this.ownerInfo.phone +
          "&code=" +
          this.ownerInfo.code;
      }
      this.$http({
        method: "post",
        url: url,
      }).then((res) => {
        if (!res.data.result) {
          this.$message.error(res.data.msg);
          return false;
        } else {
          this.setCookie("client", JSON.stringify(res.data.data), 1);
          this.userInit(res.data.data);
        }
      });
    },
    onSubmit2() {
      this.loginsubmit();
    },
    scanLogin(phone) {
      this.$http({
        method: "post",
        url: "Api/client/scan?phone=" + phone,
      }).then((res) => {
        if (!res.data.result) {
          this.$message.error(res.data.msg);
          return false;
        }

        if (res.data.data.type == 1) {
          this.loginrole = false;
          if (
            res.data.code == 105 ||
            res.data.code == 106 ||
            res.data.code == 101 ||
            res.data.code == 401
          ) {
            _this.errorStr = res.data.msg;
          } else {
            this.setCookie("client", JSON.stringify(res.data.data), 1);
            this.userInit(res.data.data);
          }
        } else {
          this.loginrole = true;
          this.rolelist = res.data.data.roleList;
        }
      });
    },
    logingettype(type) {
      this.logintype = type;
    },
    //发送短信
    sendCode() {
      let _this = this;
      if (_this.isCode && _this.count == 0) {
        _this.count = 1;
        _this.newSendCode();
      } else if (_this.isCode && _this.count == 1) {
        _this.isShow = true;
      }
    },

    newSendCode() {
      let _this = this;
      let num = 60;

      if (_this.ownerInfo.phone == "") {
        this.$message.error("请输入手机号码");
        return false;
      }

      const regMobile =
        /^(0|86|17951)?(13[0-9]|14[0-9]|16[0-9]|15[0-9]|17[0-9]|18[0-9]|19[0-9]|)[0-9]{8}$/;

      if (!regMobile.test(_this.ownerInfo.phone)) {
        this.$message.error("请输入正确的手机号码");
        return false;
      }
      // _this.count = 1;
      _this.isCode = false;
      _this.errorStr = "";
      _this
        .$http({
          method: "post",
          url:
            "Api/user/user/sendSms?phone=" + _this.ownerInfo.phone + "&flag=1",
        })
        .then((res) => {
          if (res.data.result) {
            let dsq = setInterval(function () {
              _this.codeStr = num + "s";
              if (num == 0) {
                window.clearInterval(dsq);
                _this.codeStr = "重新获取验证码";
                _this.isCode = true;
                return;
              }
              num--;
            }, 1000);
          } else {
            if (
              res.data.code == 100 ||
              res.data.code == 101 ||
              res.data.code == 102
            ) {
              this.$message.error(res.data.msg);
              _this.isCode = true;
              _this.errorStr = res.data.msg;
            }
          }
        });
    },
    success(msg) {
      this.isShow = false; // 通过验证后，需要手动隐藏模态框
      if (this.isCode && this.count > 0) {
        this.newSendCode();
      }
    },
    // 用户点击遮罩层，应该关闭模态框
    close() {
      this.isShow = false;
    },
    selectrole(ownerRoleId) {
      this.ownerRoleId = ownerRoleId;
      this.ownerInfo.roleId = ownerRoleId;
    },
    register() {
      this.$router.push("/register");
    },

    goprotoAgree(type) {
      const { href } = this.$router.resolve({
        path: `/webUseterm`,
        query: {
          type: type,
        },
      });
      window.open(href, "_blank");
    },

    show() {
      window._MEIQIA("showPanel");
      _MEIQIA("product", {
        title: "聊天",
        description: "支持pc端",
        productUrl: "http://astro.sina.com.cn/test/",
        salesCount: 11,
        success: (data) => {},
        error: (error) => {},
      });
    },

    showPassword() {
      this.passwordType = "text";
      this.imgsrc = require("@/assets/images/eye.png");
    },

    mouseLeave() {
      this.passwordType = "password";
      this.imgsrc = require("@/assets/images/c-eye.png");
    },

    changeLogintype(type) {
      this.$nextTick(() => {
        this.$refs["ownerInfo"].resetFields(); //清空表单
      });
      this.logintype = type;
      this.ownerInfo.phone = "";
      this.ownerInfo.password = "";
      this.ownerInfo.code = "";
    },
  },
};
</script>
<style src="@/assets/css/login.css" scoped></style>
