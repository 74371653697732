<template>
  <div style="width: 100%; background: #fff">
    <div class="useterm_box" style="min-height: 600px">
      <div class="useterm_con">
        <div class="orderNotice_list">
          <p class="shouldknow_tit">交付</p>
          <p class="shouldknow_txt">
            1.结合设计图和要求检查模型，确保按照要求模型深化完成。
          </p>
          <p class="shouldknow_txt">
            2.提交审核的模型需要完成校核和修正操作。
          </p>
          <p class="shouldknow_txt">
            3.提交审核的模型需要完成模型的碰撞校核操作。
          </p>
          <p class="shouldknow_txt">
            4.提交审核的模型零件前缀、构件前缀，编号规则按照要求填写。
          </p>
          <p class="shouldknow_txt">
            5.提交审核的模型需要完成编号操作，不能出现只有数字的流水号。
          </p>
          <p class="shouldknow_txt">
            6.提交审核的模型需要根据要求，增加前缀和整理好状态。
          </p>
          <p class="shouldknow_txt">
            7.提交审核模型时，填写详细的出图要求，包含出图状态、内容、起拱值、拉条车丝长度等。
          </p>
        </div>

        <div class="orderNotice_list">
          <p class="shouldknow_tit">延迟</p>
          <p class="shouldknow_txt">
            如因工程师过失行为、不作为、过失或违约造成延迟，工程师需承担相应的延迟处罚，并应尽快完成弥补。
          </p>
        </div>

        <div class="orderNotice_list">
          <p class="shouldknow_tit">责任限制</p>
          <p class="shouldknow_tit">平台：</p>

          <p class="shouldknow_txt">
            1.提供的钢结构专业设计蓝图（或电子版蓝图）、初始的模型文件。
          </p>
          <p class="shouldknow_txt">
            2.平台有权监督和检查钢结构模型绘制进度，工程师应及时通报工作进度。
          </p>
          <p class="shouldknow_txt">
            3.平台按约定，及时足额支付钢结构模型绘制服务费用。
          </p>
        </div>

        <div class="orderNotice_list">
          <p class="shouldknow_tit">工程师：</p>
          <p class="shouldknow_txt">
            1.工程师根据设计图纸，在提供的初始模型文件中完成模型。
          </p>
          <p class="shouldknow_txt">
            2.工程师负责解决各个环节对模型中所提出的问题并做出解释。
          </p>
          <p class="shouldknow_txt">
            3.工程师应在规定的时间内提交满足要求的模型，并承担工期和模型质量责任。
          </p>
          <p class="shouldknow_txt">
            4.若发生重大设计变更，工程师应将损失降至最低。
          </p>
        </div>

        <div class="orderNotice_list">
          <p class="shouldknow_tit">终止</p>
          <p class="shouldknow_txt">
            平台方无法联系工程师，并发出信息通知，若3天后仍未取得回复可以终止本协议。工程师收到终止通知后，将不承担后续任何义务，并无法获得任何相关报酬。
          </p>
        </div>

        <div class="orderNotice_list">
          <p class="shouldknow_tit">技术服务范围</p>
          <p class="shouldknow_txt">
            根据平台方提供的钢结构专业设计蓝图（或电子版蓝图）进行钢结构模型绘制。
          </p>
          <p class="shouldknow_txt">交付成果：TEKLA 钢结构模型。</p>
        </div>

        <div class="orderNotice_list">
          <p class="shouldknow_tit">结算原则</p>
          <p class="shouldknow_txt">
            1.本项目为固定单价包干项。综合单价包含详图转化、技术服务、一般性修改（总量7%）、赶工、技术支持与服务费、管理费、利润、税金、不可预见风险、不确定因素等所有为完成深化设计所需的全部费用。
          </p>
          <p class="shouldknow_txt">
            2.发生设计变更：若设计变更造成已完成的深化内容进行修改：变更量≤该单体工程总量7%时，无偿修改；若变更量＞该单体工程总量7%时，平台应支付变更部分设计费用。变更是指因设计变更或平台原因导致已完成的钢结构施工详图绘制发生变更。
          </p>
          <p class="shouldknow_txt">
            3.未按照约定期限完成模型交付，平台有权终止项目合同，并拒绝支付费用。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
};
</script>
<style scoped>
@import "@/assets/css/webUseterm.css";
.orderNotice_tip {
  height: 60px;
  width: 100%;
  line-height: 60px;
  border-bottom: 2px solid #e7eaee;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  text-align: left;
}
.orderNotice_list {
  margin-top: 36px;
}
.shouldknow_tit {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
}
.shouldknow_txt {
  font-size: 14px;
  color: #5a5a5a;
  margin-top: 10px;
}
</style>