<template>
  <div>
    <div class="useterm_list">
      <p>更新日期：2024年3月10日</p>
      <p>生效日期：2024年3月10日</p>
      <p>
        欢迎您注册成为蓝途云工厂用户！蓝途云工厂服务协议（以下简称“本协议”）是由山西众之翼建筑科技有限公司，以下简称“蓝途云工厂”或者“我们”）和您签订。
      </p>
    </div>
    <div class="useterm_list">
      <p class="useterm_tit">第一条蓝途云工厂用户的确认</p>
      <p class="useterm_txt">
        1、您在注册、登陆使用蓝途云工厂提供的各项服务之前，请您仔细阅读并理解本协议全部内容，做出您认为适当的选择。当您点击“注册”或者“登录”或其他方式确认即表示您已经仔细阅读并完全理解、同意本协议项下的全部条款。
      </p>
      <p class="useterm_txt">
        2、如果我们对本协议进行修改，我们将在山西众之翼建筑科技有限公司的网站（https://zzyjzkj.com:8080）提前予以公布，更变公示期（7日）届满时生效。若您不同意修改后的协议，您有权停止使用蓝途云工厂服务或者注销您的蓝途云工厂的账户；双方协商一致的，也可另行变更协议内容。您如果继续“登录”或其他方式确认，使用蓝途云工厂提供的服务，即表示已经仔细阅读并完全理解、同意修改后的协议。
      </p>
    </div>

    <div class="useterm_list">
      <p class="useterm_tit">第二条 账户的注册、使用、注销</p>
      <p class="">
        1、 在您开始注册使用蓝途云工厂服务前，您应当具备中华人民共和国法律规定的与您的行为相适应的民事行为能力。　
      </p>
      <p class="">
        2、 您同意本协议的全部条款并按照登陆页面上的提示提供您本人真实、准确、有效信息并完成全部注册程序后，才能成为蓝途云工厂用户。
      </p>
      <p class="">
        3、 您应妥善保管自己的帐号和密码，您应当对以其账户进行的所有活动和事件负法律责任。
      </p>
      <p class="">
        4、 您在使用蓝途云工厂提供的服务过程中，应当遵守国家法律法规以及所有与蓝途云工厂服务有关的网络协议、规定、产品规则或程序，不得有下列任何行为，否则蓝途云工厂有权不通知您自行采取措施，包括但不限于删除用户发布的内容，限制、暂停用户使用，或终止合作永久封禁账户等措施：
      </p>
      <p class="">
        （1）提供虚假信息注册的（包括但不限于冒充其他人、单位）或者仿冒、混淆他人账号对外展示名称、头像或者发布让人容易产生混淆、误解的内容的行为；
      </p>
      <p class="">
        （2）在蓝途云工厂上骚扰、辱骂、歧视、威胁他人或存在其他不正当行为；
      </p>
      <p class="">
        （3）通过任何技术手段侵入我们的软件、网站、数据库等系统或者干扰蓝途云工厂产品和/或服务正常运行的；
      </p>
      <p class="">
        （4）任何利用自有或第三方的插件、程序抓取、读取、收集蓝途云工厂展示的任何信息的；
      </p>
      <p>
        （5）侵犯蓝途云工厂或第三方的专利权、著作权、商标权、名誉权或其他任何合法权益的行为；
      </p>
      <p>（6）未经蓝途云工厂许可，在蓝途云工厂发布广告或营销行为；</p>
      <p>
        （7）利用蓝途云工厂发布、传输任何新闻信息，包括但不限于有关政治、经济、军事、外交等社会公共事务的报道、评论，以及有关社会突发事件的报道、评论；
      </p>
      <p>
        （8）其他违反国家法律法规以及所有与蓝途云工厂服务有关的网络协议、规定、产品规则或程序的行为。
      </p>
      <p>
        5、 您知晓并同意在拨打蓝途云工厂电话时均可能会被录音，蓝途云工厂电话包括但不限于客服电话、销售电话等。
      </p>
      <p>
        6、 蓝途云工厂有权拒绝为与本单位经营同类业务、有业务竞争关系或者其他利害关系的单位及个人提供服务。
      </p>
      <p>
        7、 如需要注销的蓝途云工厂账户，您可以在蓝途云工厂上申请注销您的蓝途云工厂账户。在注销账户之后，我们将停止为您提供所有的产品和服务，并依据您的要求，删除您的个人信息，但法律法规另有规定的除外。
      </p>
      <p>8、 您可以拨打我们的客户服务热线0359-2560707。</p>
    </div>
    <div class="useterm_list">
      <p class="useterm_tit">第三条 用户承诺</p>
      <p>
        1、您应遵守中华人民共和国相关法律法规、所居住或者开展经营活动或者业务国家或者地区的法律法规，不得将我们的网络存储平台和/或服务用于非法目的，也不得以非法方式使用我们的网络存储平台和/或服务。
      </p>
      <p>
        2、您承诺在注册或者认证过程中，提供的资料合法、真实、有效。如提交的资料有任何变动，应该及时、准确更新。如因提交的资料不真实或者更新不及时而引发的问题，您应承担相应责任。
      </p>
      <p>
        3、您应按照平台相关规则及您与平台的相关约定合理、合法使用在蓝途云工厂获取、下载的信息及文件（包括但不限于个人主页信息、相关介绍视频等）。未经平台及文件相关权利人授权，您不得将平台服务过程中获取的任何信息或文件用于服务目的以外的其他目的，亦不得改编相关文件，不得将在平台服务过程中获取的个人主页信息/文件/肖像上传、提供或分享至与服务无关的任何第三方/平台/应用或商业使用。
      </p>
      <p>
        4、蓝途云工厂将会尽其商业上的合理努力保障您在蓝途云工厂及服务中的数据存储安全，并在法律要求的期限内保留您的个人信息。蓝途云工厂不对您在深化服务中相关数据的删除或储存失败、超出法律规定及平台承诺的储存期限以外的数据负责，您应当根据自身需求自行备份蓝途云工厂及服务中的相关数据。
      </p>
      <p>
        5、您承诺在蓝途云工厂上传、发表、转载或者传输的内容合法、真实、有效，不得夸张描述或编造虚假信息，并对内容承担全部责任。您不得在蓝途云工厂的任何页面发布、转载、传送含有下列任何内容的信息，否则蓝途云工厂有权不通知您自行采取措施，包括但不限于删除用户发布的内容，限制、暂停使用，或终止合作永久封禁账户等措施：
      </p>
      <p>（1）违反宪法确定的基本原则的；</p>
      <p>（2）危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；</p>
      <p>（3）损害国家荣誉和利益的；</p>
      <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
      <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
      <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
      <p>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
      <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
      <p>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
      <p>（10）含有歧视内容的，如民族歧视、地域歧视、性别歧视、宗教歧视等；</p>
      <p>
        （11）虚假、胁迫、猥亵、骚扰、中伤、粗俗、侵害他人隐私或其他有悖道德、令人反感的内容；
      </p>
      <p>
        （12）侵犯蓝途云工厂或任何第三方的合法权利的，包括但不限于知识产权等权利；
      </p>
    </div>
    <div class="useterm_list">
      <p class="useterm_tit">第四条 用户违规行为的处理</p>
      <p>
        1、您如违反法律法规或本协议中的第二条、第三条任一条款的，蓝途云工厂有权要求改正或直接采取一切必要的措施。必要的措施包括暂停或终止用户使用蓝途云工厂服务，由此产生的纠纷、赔偿处罚等相应法律责任您应承担。被终止使用蓝途云工厂服务的用户已缴纳的费用不予以退还，若其账户中存在余额，则同步清零，不予退还。 
      </p>
      <p>
        2、除因蓝途云工厂存在过错外，您自行承担使用蓝途云工厂服务产生的风险。您如因本人过错或行为而遭受人身损害、财产损害，造成第三方人身损害、财产损害或由第三人造成您人身、财产损害的，应当依法向责任主体追究法律责任，蓝途云工厂不承担任何责任。
      </p>
      <p>
        3、用户如违反法律法规或本协议约定，导致蓝途云工厂或其关联公司遭受损失（损失包括但不限于直接经济损失、行政罚款、维权成本），用户应赔偿蓝途云工厂或其关联公司的全部损失。
      </p>
      <p>
        4、蓝途云工厂未行使或延迟行使其在本协议权利并不构成对这些权利的放弃，单独或部分行使其在本协议项下的任何权利不妨碍任何其它权利的行使。蓝途云工厂随时有权要求用户继续履行义务并承担相应的违约责任
      </p>
    </div>

    <div class="useterm_list">
      <p class="useterm_tit">第五条 用户的个人信息保护</p>
      <p>我们重视对您的信息保护，具体详情请参阅《个人信息保护政策》。</p>
    </div>

    <div class="useterm_list">
      <p class="useterm_tit">第六条  版权声明</p>
      <p>
        我们平台特有的标识、版面设计、编排方式等知识产权均属蓝途云工厂享有，未经蓝途云工厂许可，不得任意复制、转载或使用。
      </p>
    </div>

    <div class="useterm_list">
      <p class="useterm_tit">第七条 不可抗力以及免责</p>
      <p>
        1、因遇不可抗力造成的蓝途云工厂无法为用户提供服务的，蓝途云工厂不承担任何法律责任，不可抗力包括但不限于：政府行为、自然灾害、战争、罢工、电脑病毒、黑客攻击、电信管理部门因技术调整导致网络中断等。
      </p>
      <p>
        2、蓝途云工厂无法保证网络服务在任何条件下均不会中断，故蓝途云工厂对网络服务的及时性、安全性、准确性不作担保。同时，蓝途云工厂对服务器受病毒侵袭及其他故障侵扰免责。用户应做好数据备份，若发生使用本平台时的数据丢失的情况，蓝途云工厂免责。
      </p>
    </div>

    <div class="useterm_list">
      <p class="useterm_tit">第八条 通知、送达</p>
      <p>
        1、蓝途云工厂对于用户所有的通知均可通过【推送通知、电子邮件、短信、公告】，该等通知于发送之日视为已送达收件人。
      </p>
      <p>
        2、用户对于蓝途云工厂的通知应当通过蓝途云工厂如下正式公布的通信地址、电子邮件地址等联系信息进行送达。
      </p>
      <p>3、蓝途云工厂的联系信息</p>
      <p>地址：山西省运城市运城经济技术开发区涑水东街涑水商务6楼</p>
      <p>电话：0359-2560707</p>
    </div>

    <div class="useterm_list">
      <p class="useterm_tit">第九条 法律适用、管辖与其他</p>
      <p>
        1、本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
      </p>
      <p>
        2、如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向山西省运城市人民法院提起诉讼。
      </p>
      <p>
        3、本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>