<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
};
</script>

<style >
@import "assets/css/globle.css";
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f5f6fa;
  overflow-x: hidden;
  min-height: 100vh;
  /* min-height: 100vh; */
  /* margin-top: 60px; */
}
p {
  text-align: left;
}
</style>
