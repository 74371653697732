<template>
  <div>
    <div class="head_sel">
      <div class="sel_left">
        <el-cascader
          placeholder="结构类型"
          v-model="realmIds"
          :options="realmlist"
          :show-all-levels="false"
          filterable
          clearable
          @change="search"
        >
        </el-cascader>
        <el-date-picker
          type="daterange"
          range-separator="至"
          v-model="rangeDate"
          value-format="yyyy-MM-dd"
          clearable
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          unlink-panels
          :picker-options="pickerOptions0"
          @change="search()"
        >
        </el-date-picker>
        <el-input
          placeholder="根据项目编号查询"
          suffix-icon="el-icon-search"
          v-model="content"
          @input="prosearch()"
          style="margin-left: 10px"
        >
        </el-input>
      </div>
      <div class="sel_right">
        <el-select
          v-model="form.sortState"
          placeholder="收益"
          clearable
          @change="search()"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div v-if="currentList2len > 0" v-loading="loading">
      <ul class="myProblem_con" style="">
        <li
          class="myProblem_list"
          v-for="(item, index) in currentList2"
          :key="index"
        >
          <div class="myProblem_list_con">
            <div class="infor_con">
              <div class="fixed_4">
                <p class="infor_con_name">
                  <img
                    src="../../../assets/images/outsidepro/pro_icon.png"
                    alt=""
                    v-if="item.projectTaskPojo.stepState == 1"
                  />
                  <img
                    src="../../../assets/images/outsidepro/pro_icon2.png"
                    alt=""
                    v-else
                  />
                  {{ item.projectTaskPojo.projectCode }}
                </p>
                <div class="infor_type">
                  <p>
                    结构类型：{{ item.parentRealmStr + "-" + item.realmStr }}
                  </p>
                </div>

                <!-- <p class="release_time">
                <span
                  v-text="$date.Djs_timeList2(item.notarizeDate, serverTime)"
                ></span
                >发布
              </p> -->
              </div>

              <!-- <div class="fixed_3">
                <div class="dateStr">
                  <p style="color: #000000; font-weight: bold; font-size: 14px">
                    {{ item.exPectDateStr }}
                  </p>
                  <p class="exPectdate_tip">最迟交付时间</p>
                </div>
              </div> -->

              <div class="fixed_3">
                <p
                  class="quantity"
                  style="color: rgb(0, 0, 0); font-weight: bold"
                >
                  {{ item.exPectDateStr }}
                </p>
                <p class="quantity_txt">最迟交付时间</p>

                <div class="downfile_box" v-if="item.requireList.length > 0">
                  <div class="downfile">
                    <!-- <p class="downfile_bg">下载项目要求附件</p> -->
                    <el-popover
                      popper-class="tab_popover"
                      placement="right"
                      width="302"
                      trigger="click"
                    >
                      <div style="padding: 20px 20px" class="require_popper">
                        <p>项目要求</p>
                        <!-- <ul
                        v-for="(item, index) in item.requireList"
                        :key="index"
                      > -->
                        <ul>
                          <li
                            v-for="(item, index) in item.requireList"
                            :key="index"
                          >
                            {{ index + 1 }}、{{ item }}
                          </li>
                        </ul>
                      </div>

                      <p class="downfile_txt pointer" slot="reference">
                        <img
                          src="../../../assets/images/outsidepro/required.png"
                          alt=""
                        />
                        项目要求
                      </p>
                    </el-popover>
                  </div>
                </div>
                <div v-else class="norequired">
                  <img
                    src="../../../assets/images/outsidepro/norequired.png"
                    alt=""
                  />无项目要求
                </div>
              </div>

              <div class="fixed_3">
                <div class="unitrice" style="margin-top: 28px">
                  <span>￥{{ item.outsidePrice }}</span>
                </div>

                <p class="quantity_txt">预估收益</p>

                <div class="downfile_box">
                  <div class="downfile">
                    <!-- <p class="downfile_bg">下载项目要求附件</p> -->
                    <el-popover
                      popper-class="tab_popover"
                      placement="right"
                      width="600"
                      trigger="click"
                    >
                      <el-table :data="item.fileList" border>
                        <el-table-column
                          property="fileName"
                          label="名称"
                          width="200"
                        ></el-table-column>
                        <el-table-column
                          property="fileSizeStr"
                          label="大小"
                          width="100"
                        ></el-table-column>
                        <el-table-column
                          property="crtDateStr"
                          label="时间"
                          width="200"
                        ></el-table-column>
                        <el-table-column label="操作" width="">
                          <template slot-scope="scope">
                            <span
                              class="pointer down"
                              style="margin-left: 10px"
                              @click="download(scope.row)"
                              >下载</span
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                      <p class="downfile_txt pointer" slot="reference">
                        <img
                          src="../../../assets/images/outsidepro/file.png"
                          alt=""
                        />
                        项目附件
                      </p>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
            <div class="connect">
              <p
                class="interact pointer"
                @click="getProquestion(item.projectTaskPojo.projectTaskId)"
              >
                <img
                  src="../../../assets/images/outsidepro/interact.png"
                  alt=""
                />项目答疑
              </p>

              <p class="statestr">
                <span
                  v-bind:style="
                    getstateColor(item.projectTaskPojo.useFlag, item.state)
                  "
                  >·</span
                >
                <span
                  v-bind:style="
                    getstateColor(item.projectTaskPojo.useFlag, item.state)
                  "
                  v-text="getstateStr(item.projectTaskPojo.useFlag, item.state)"
                ></span>
              </p>
            </div>
          </div>

          <div
            class="myProblem_evolve"
            v-if="item.outsideEventEntities.length > 0"
          >
            <div class="evolve_box">
              <el-timeline>
                <el-timeline-item
                  placement="top"
                  v-for="(item2, index2) in item.outsideEventlist"
                  :key="index2"
                  :class="index2 == 0 ? 'timeline_act' : ''"
                >
                  <div class="timeline_con">
                    <div class="timeline_state">
                      <!-- <p class="timeline_state_icon">
                        <img
                            :src="
                            item2.eventType == 0
                              ? require('../../../assets/images/outsidepro/交付.png')
                              : item2.eventType == 1
                              ? require('../../../assets/images/outsidepro/change.png')
                              : item2.eventType == 2
                              ? require('../../../assets/images/outsidepro/xd.png')
                              : item2.eventType == 3
                              ? require('../../../assets/images/outsidepro/examine.png')
                              : ''
                          "
                            alt=""
                        />
                      </p> -->
                      <p class="timeline_state_txt">{{ item2.eventTypeStr }}</p>
                    </div>

                    <div class="timeline_infor">
                      <p class="timeline_infor_tit" v-text="item2.content"></p>
                      <p class="timeline_infor_con">
                        <span v-if="item2.eventType != 0"
                          >任务创建时间：{{ item2.crtDateStr }}</span
                        >
                        <span
                          v-if="
                            item2.commitDateStr != null &&
                            item2.commitDateStr != ''
                          "
                          ><span v-if="item2.eventType != 0"> |</span>
                          文件交付时间：{{ item2.commitDateStr }}</span
                        >
                      </p>
                    </div>
                    <div
                      class="timeline_infor"
                      v-if="item2.modelingPlotRequire !== null"
                    >
                      <el-tooltip
                        :disabled="isShowTooltip"
                        effect="light"
                        :content="item2.modelingPlotRequire"
                        placement="right"
                        popper-class="ques_popover"
                      >
                        <p class="timeline_infor_tit">
                          <span
                            :ref="item2.modelingPlotRequire"
                            @mouseover="onMouseOver(item2.modelingPlotRequire)"
                            v-text="item2.modelingPlotRequire"
                          ></span>
                        </p>
                      </el-tooltip>

                      <p class="timeline_infor_con">
                        <span>深化内容及要求</span>
                      </p>
                    </div>

                    <!-- <div
                      class="timeline_problem pointer"
                      @click="
                        getViewQualityList(item2.contactId, item2.eventState)
                      "
                      v-if="item2.eventType == 3"
                    >
                      <p class="problem_num">{{ item2.qualityDetailsCount }}</p>
                      <p class="problem_tit">问题条数</p>
                    </div> -->

<!--                    <div-->
<!--                      v-if="item2.eventState == 0"-->
<!--                      class="timeline_operate pointer"-->
<!--                      @click="-->
<!--                        secondSubexa(item2.contactId, item2.eventType, item2.id)-->
<!--                      "-->
<!--                    >-->
<!--                      {{ item2.eventType == 3 ? "再次提交审核" : "提交审核" }}-->
<!--                    </div>-->

                    <el-popover
                      popper-class="tab_popover"
                      placement="right"
                      width="600"
                      trigger="click"
                      v-if="item2.fileList.length > 0"
                    >
                      <el-table :data="item2.fileList" border>
                        <el-table-column
                          width="200"
                          property="fileName"
                          label="名称"
                        ></el-table-column>
                        <el-table-column
                          property="fileSizeStr"
                          label="大小"
                          width="100"
                        ></el-table-column>
                        <el-table-column
                          property="crtDateStr"
                          label="时间"
                          width="200"
                        ></el-table-column>
                        <el-table-column label="操作" width="">
                          <template slot-scope="scope">
                            <span
                              class="pointer down"
                              style="margin-left: 10px"
                              @click="download(scope.row)"
                              >下载</span
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                      <!-- <i
                      slot="reference"
                      class="el-icon-caret-bottom pointer"
                    ></i> -->

                      <div class="thisdelivery" slot="reference">
                        <img
                          src="../../../assets/images/outsidepro/wj-1.png"
                          alt=""
                        />文件
                      </div>

                      <!-- <el-button slot="reference">click 激活</el-button> -->
                    </el-popover>

                    <div v-else class="nodelivery">
                      <img
                        src="../../../assets/images/outsidepro/wj-2.png"
                        alt=""
                      />文件
                    </div>
                    <div class="problem_operate">
                      <div
                        class="viewQuality pointer"
                        @click="
                          getViewQualityList(item2.contactId, item2.eventState)
                        "
                        v-if="item2.eventType == 3"
                      >
                        查看
                      </div>
                    </div>
                  </div>
                  <!-- <el-card>
                  <el-table :data="item.rows" border>
                    <el-table-column
                      v-for="(item, index) in item.dictList"
                      :prop="item.indexCode"
                      :label="item.label"
                      :key="index"
                      align="center"
                    ></el-table-column>
                  </el-table>
                </el-card> -->
                </el-timeline-item>
              </el-timeline>
            </div>
            <p
              class="viewmore pointer"
              v-if="
                item.outsideEventEntities.length > 2 &&
                item.outsideEventEntities > item.outsideEventlist
              "
              @click="getmoredata(index)"
            >
              查看更多 <img src="../../../assets/images/more.png" alt="" />
            </p>
          </div>
        </li>
      </ul>

      <div class="pagingbox" style="margin-top: 20px; padding-bottom: 20px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.page"
          :page-sizes="[3]"
          :page-size="form.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else class="history_nodata">
      <p style="text-align: center; padding-top: 300px">
        <img src="../../../assets/images/nodata/hispro_nodata.png" alt="" />
      </p>
      <p style="text-align: center; font-size: 14px">暂无历史项目</p>
    </div>

    <el-dialog
      title="品控问题"
      :close-on-click-modal="false"
      :visible.sync="viewQualityProblem"
      width="490px"
      :before-close="handleClose"
    >
      <div class="problem_box" style="max-height: 620px">
        <div
          class="problem_list"
          v-for="(item, index) in viewQualitylist"
          :key="index"
        >
          <div class="problem_type">
            <p class="type_txt">
              <span></span>类型：{{ item.qualityTypeName }}
            </p>
            <p style="clear: both"></p>
          </div>

          <p class="problem_txt">标题：{{ item.title }}</p>
          <p class="problem_txt">内容：{{ item.content }}</p>

          <div class="problem_con">
            <p class="problem_icon">
              <el-image
                v-for="(item2, index) in item.imageArray"
                :key="index"
                :src="imageUrl + item2"
                :preview-src-list="getSrcList(index, item.imageArray)"
                alt=""
              />
            </p>
          </div>
        </div>
      </div>
    </el-dialog>
    <proQuestion
      ref="proques"
      v-if="proQuestionShow"
      :proQuestionShow="proQuestionShow"
      :projectTaskId="projectTaskId"
      :addFlag="0"
      @closeDialog="closeDialog"
    ></proQuestion>
  </div>
</template>

<script>
import proQuestion from "./proQuestion.vue";
import Global from "@/assets/js/global";
import streamSaver from "streamsaver";
export default {
  components: {
    proQuestion,
  },
  data() {
    return {
      pickerOptions0: {
        //设置选择今天以及今天之后的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      rangeDate: [],
      realmIds: [],
      imageUrl: Global.baseURL,
      form: {
        page: 1,
        limit: 3,
        searchText: "",
        realmId: "",
        date: "",
        sortType: "0",
        sortState: "",
      },
      options: [
        {
          value: "1",
          label: "收益最高",
        },
        {
          value: "0",
          label: "收益最低",
        },
      ],
      projectTaskId: 0,
      proQuestionShow: false,
      currentList2: [],
      currentList2len: 1,
      haveProlist: [],
      viewQualitylist: [],
      viewQualityProblem: false,
      realmlist: [],
      totalCount: 0,
      gridData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      content: "",
      loading: true,
      chunks: [],
      // 分片下载大小 5MB
      chunkSize: 1024 * 1024 * 5,
      // 文件总大小(需要请求后端获得)
      fileSize: 0,
      chunksNum: 0,
      isShowTooltip: false,
    };
  },

  created() {},

  methods: {
    getSrcList(index, fileList) {
      var urlList = [];
      fileList.forEach((item) => {
        let url = this.imageUrl + item;
        urlList.push(url);
      });
      return urlList.slice(index).concat(urlList.slice(0, index));
    },
    handleClose() {
      this.viewQualityProblem = false;
    },
    getViewQualityList(qualityId, state) {
      let flag = state == 1 ? -3 : 0;
      this.viewQualityProblem = true;
      this.$http
        .get(
          "/Api/yw/qualityDetails/queryByQualityId?qualityId=" +
            qualityId +
            "&flag=" +
            flag
        )
        .then((res) => {
          this.viewQualitylist = res.data.data;
        });
    },
    hisProMethod() {
      this.content = "";

      this.gethisprolist();
      this.getRealmCombox();
    },

    gethisprolist() {
      if (this.form.sortState != undefined && this.form.sortState != "") {
        this.form.sortType = 1;
      } else {
        this.form.sortType = 0;
      }
      this.$http
        .get("/Api/yw/projectTaskOutside/getMyProject", {
          params: this.form,
        })
        .then((res) => {
          this.currentList2 = res.data.data;
          this.currentList2len = this.currentList2.length;
          this.totalCount = res.data.count;
          this.loading = false;
          this.getEventlist();
        });
    },
    search() {
      if (this.realmIds != null && this.realmIds.length > 0) {
        this.form.realmId = this.realmIds[1];
      } else {
        this.form.realmId = "";
      }
      if (this.rangeDate != null && this.rangeDate.length > 0) {
        this.form.date = this.rangeDate[0] + " ~ " + this.rangeDate[1];
      } else {
        this.form.date = "";
      }
      this.form.page = 1;
      this.gethisprolist();
    },

    prosearch() {
      this.form.searchText = this.content;
      this.search();
    },
    onMouseOver(str) {
      // 内容超出，显示文字提示内容
      const tag = this.$refs[str][0];
      const parentWidth = tag.parentElement.offsetHeight; // 获取元素父级可视宽度
      const contentWidth = tag.offsetHeight; // 获取元素可视宽度
      this.isShowTooltip = contentWidth <= parentWidth;
    },
    getEventlist() {
      for (var i = 0; i < this.currentList2.length; i++) {
        this.currentList2[i].outsideEventlist = [];

        if (this.currentList2[i].outsideEventEntities.length > 2) {
          this.currentList2[i].outsideEventlist = this.currentList2[
            i
          ].outsideEventEntities.slice(0, 2);
        } else {
          this.currentList2[i].outsideEventlist =
            this.currentList2[i].outsideEventEntities;
        }
      }
    },

    getmoredata(index) {
      var outsidelist = this.currentList2[index].outsideEventEntities;
      this.currentList2[index].outsideEventlist = outsidelist;
      this.$forceUpdate();
    },
    getstateStr(useFlag, state) {
      if (useFlag == 0) {
        this.textclass = "spanclass";
        return "已下架";
      } else if (state == 3) {
        return "进行中";
      } else if (state == 4) {
        return "已完成";
      }
    },
    getstateColor(useFlag, state) {
      if (useFlag == 0) {
        return { color: "#E15A55" };
      } else {
        return "";
      }
    },
    getRealmCombox() {
      this.$http.get("/Api/yw/realm/getRealmCombox2").then((res) => {
        this.realmlist = res.data.data;
      });
    },

    handleSizeChange(val) {
      this.form.limit = val;
      this.gethisprolist();
    },
    // 点击第几页
    handleCurrentChange(val) {
      this.form.page = val;
      this.gethisprolist();
    },
    getProquestion(projectTaskId) {
      //打开“弹窗组件”
      this.projectTaskId = projectTaskId;
      this.proQuestionShow = true;
      this.$nextTick(() => {
        this.$refs.proques.getProliasionMethod();
      });
    },
    closeDialog() {
      //关闭“弹窗组件”
      this.proQuestionShow = false;
    },
    download(row) {
      // 定义 存储所有的分片的数组
      this.chunks = [];
      // 分片下载大小 5MB
      this.chunkSize = 1024 * 1024 * 5;
      // 文件总大小(需要请求后端获得)
      this.fileSize = row.fileSize;
      // 计算分片数量
      this.chunksNum = Math.ceil(this.fileSize / this.chunkSize);
      this.downloadChunkFile(0, row);
    },
    async downloadChunkFile(chunkIdx, row) {
      // if (chunkIdx >= this.chunksNum) {
      //   alert('分片索引不可超过分片数量')
      //   return
      // }
      //
      let start = chunkIdx * this.chunkSize;
      let end = Math.min(start + this.chunkSize - 1, this.fileSize - 1);
      const range = `bytes=${start}-${end}`;
      streamSaver.mitm = Global.serveUrl + "mitm.html?version=2.0.0";
      // 使用 Fetch API 请求文件
      fetch(
        Global.baseURL2 +
          "Api/yw/profile/download?rangeHeader=" +
          range +
          "&path=" +
          row.fileUrl +
          "&fileName=" +
          row.fileName
      ).then((res) => {
        this.chunks.push(res.data);
        // 创建写入流 filename为下载的文件名
        const fileStream = streamSaver.createWriteStream(row.fileName, {
          size: res.headers.get("content-length"),
        });
        const readableStream = res.body;
        if (window.WritableStream && readableStream.pipeTo) {
          return readableStream.pipeTo(fileStream).then(() => {});
        }
        window.writer = fileStream.getWriter();
        const reader = res.body.getReader();
        const pump = () =>
          reader
            .read()
            .then((res) =>
              res.done
                ? window.writer.close()
                : window.writer.write(res.value).then(pump)
            );
        pump();
      });

      // this.$http({
      //   url: "Api/yw/profile/download2",
      //   method: 'GET',
      //   params: {
      //     rangeHeader: range,
      //     path: row.fileUrl,
      //     fileName: row.fileName,
      //   },
      //   responseType: 'arraybuffer'
      // }).then(response => {
      //
      //   this.chunks.push(response.data)
      //   if (chunkIdx == this.chunksNum - 1) {
      //     // 下载好了
      //     console.log(this.chunks, 'chunks');
      //     // 组合chunks到单个文件
      //     const blob = new Blob(this.chunks);
      //     console.log(blob, 'blob');
      //     const link = document.createElement('a');
      //     link.href = window.URL.createObjectURL(blob);
      //     link.download = 'demo.mp4';
      //     link.click();
      //     return
      //   } else {
      //     ++chunkIdx
      //     this.downloadChunkFile(chunkIdx,row)
      //   }
      // })
    },
  },
};
</script>

<style  scoped>
.el-range-editor .el-range-input {
  background-color: RGB(245, 246, 250) !important;
  line-height: 1 !important;
}
.sel_left .el-input__inner {
  width: 220px;
}
.head_sel {
  width: 1120px;
  margin: 10px auto;
  height: 60px;
}
.sel_left {
  float: left;
}
</style>