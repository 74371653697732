<template>
  <div class="main-container">
    <TopAside ref="topaside" :unreadMessageNum="unreadMessageNum" />
    <Content ref="content" />
    <Bottom />
  </div>
</template>

<script>
import TopAside from "./top-aside";
import Content from "./content";
import Bottom from "./bottom";

export default {
  data() {
    return {
      unreadMessageNum: 0,
    };
  },
  created() {
    var token = window.sessionStorage.getItem("token");
    if (token) {
      this.unreadMessage();
    }
  },
  methods: {
    unreadMessage() {
      this.$http.get("Api/socket/getNotReadMsgCount").then((res) => {
        this.unreadMessageNum = res.data.data;
      });
    },
    changeuserimg() {
      this.$refs.topaside.getuserImg();
    },
    routeract() {
      this.$refs.content.routeract();
    },
  },
  computed: {},
  components: {
    TopAside,
    Content,
    Bottom,
  },
};
</script>
