<template>
  <div id="depart">
    <!-- 内容部分 -->
    <div style="margin-top: 20px" class="join_Project">
      <div class="filecenter_form head_sel">
        <el-form :inline="true" :model="queryLimit" class="demo-form-inline">
          <el-form-item style="">
            <el-date-picker
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              v-model="queryLimit.date"
              @change="changeState()"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="queryLimit.sourceType"
              placeholder="文件类型"
              @change="changeState()"
              style="width: 210px"
              clearable
            >
              <el-option
                v-for="(item, index) in fileSourceCombox"
                :key="index"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" style="width: 260px">
            <div>
              <el-input
                v-model="content"
                placeholder="根据项目编号查询"
                suffix-icon="el-icon-search"
                @input="changeState()"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="joinPro_con">
        <div class="joinPro_tab" v-if="fileListlen > 0">
          <template>
            <el-table
              border
              :data="fileList"
              :span-method="objectSpanMethod"
              style="width: 1120px"
            >
              <el-table-column prop="projectCode" label="项目编号" width="110">
              </el-table-column>
              <el-table-column prop="fileName" label="文件名称">
              </el-table-column>
              <el-table-column label="文件类型" width="100">
                <template slot-scope="scope">
                  <span
                    v-if="scope.row.fileSourceStr == '模型交付'"
                    style="color: #3d7eff"
                    >{{ scope.row.fileSourceStr }}</span
                  >
                  <span v-else>{{ scope.row.fileSourceStr }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="fileType" label="文件格式" width="100">
              </el-table-column>
              <el-table-column prop="fileSizeStr" label="文件大小" width="100">
              </el-table-column>
              <el-table-column prop="crtDateStr" label="上传时间" width="160">
              </el-table-column>
              <el-table-column label="操作" width="90">
                <template slot-scope="scope">
                  <span
                    class="pointer down"
                    style="margin-right: 10px"
                    @click="download(scope.row)"
                    >下载</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <div class="pagingbox" style="margin-top: 20px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryLimit.page"
              :page-sizes="pageSizes"
              :page-size="queryLimit.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>

        <div v-else class="history_nodata">
          <p style="text-align: center; padding-top: 300px">
            <img src="../../assets/images/nodata/file_nodata.png" alt="" />
          </p>
          <p style="text-align: center; font-size: 14px">暂无文件</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Global from "@/assets/js/global";
import streamSaver from "streamsaver";

export default {
  components: {},
  data() {
    return {
      pageSizes: [10],
      content: "",
      queryLimit: {
        page: 1,
        limit: 10,
        date: "",
        searchText: "",
        sourceType: "",
      },
      blurborder: "",
      fileList: [],
      fileListlen: 1,
      totalCount: 0,
      fileSourceCombox: [],
      usertype: 1,
      bodyclass: "bodyopen",
      imageUrl: Global.baseURL,
      chunks: [],
      // 分片下载大小 5MB
      chunkSize: 1024 * 1024 * 5,
      // 文件总大小(需要请求后端获得)
      fileSize: 0,
      chunksNum: 0,
    };
  },
  created() {
    this.getFileList();
    this.getFileListSourceCombox();
  },

  methods: {
    //表格合并
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let data = this.fileList; //拿到当前table中数据
      let cellValue = row[column.property]; //当前位置的值

      let hbArr = ["projectCode"]; //需要合并的字段（进行合并行的prop）
      if (cellValue && hbArr.includes(column.property)) {
        let prevRow = data[rowIndex - 1]; //获取到上一条数据
        let nextRow = data[rowIndex + 1]; //下一条数据
        if (prevRow && prevRow[column.property] === cellValue) {
          //当有上一条数据，并且和当前值相等时
          return { rowspan: 0, colspan: 0 };
        } else {
          let countRowspan = 1;
          while (nextRow && nextRow[column.property] === cellValue) {
            //当有下一条数据并且和当前值相等时,获取新的下一条
            nextRow = data[++countRowspan + rowIndex];
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 };
          }
        }
      }
    },
    //获取文件集合
    getFileList() {
      this.$http
        .get("Api/yw/profile/getProjectFilePage", { params: this.queryLimit })
        .then((res) => {
          this.fileList = res.data.data;
          this.fileListlen = res.data.data.length;
          this.totalCount = res.data.count;
        });
    },
    //撤销
    cancel(data) {
      this.$http
        .post("Api/yw/profile/cancel?id=" + data.projectFileId)
        .then((res) => {
          if (res.data.result == true) {
            this.getFileList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },

    // 下载方法  row为后台返回的url地址
    downfile(fileurl, fileName) {
      fetch(this.imageUrl + fileurl)
        .then((res) => res.blob())
        .catch(function () {
          // 出错了;等价于 then 的第二个参数,但这样更好用更直观 :
          this.$alert("未找到该图片", {
            confirmButtonText: "确定",
          });
        })
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          let link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName; // 下载文件的名字
          document.body.appendChild(link);
          link.click();
        });
    },

    //获取文件类型下拉框
    getFileListSourceCombox() {
      this.$http.get("Api/yw/profile/getFileSourceCombox").then((res) => {
        this.fileSourceCombox = res.data.data;
      });
    },
    handleClose(done) {
      this.$refs.modal.style.display = "none";
      done();
    },

    changeState() {
      this.queryLimit.searchText = this.content;
      this.queryLimit.page = 1;
      this.getFileList();
    },

    handleSizeChange(val) {
      this.queryLimit.limit = val;
      this.getFileList();
    },
    // 点击第几页
    handleCurrentChange(val) {
      this.queryLimit.page = val;
      this.getFileList();
    },

    focussearch() {
      this.blurborder = "searchblur";
    },

    blursearch() {
      this.blurborder = "";
    },
    getCollapse(val) {
      if (val == false) {
        this.bodyclass = "bodyopen";
      } else {
        this.bodyclass = "bodyclose";
      }
    },
    download(row) {
      // 定义 存储所有的分片的数组
      this.chunks = [];
      // 分片下载大小 5MB
      this.chunkSize = 1024 * 1024 * 5;
      // 文件总大小(需要请求后端获得)
      this.fileSize = row.fileSize;
      // 计算分片数量
      this.chunksNum = Math.ceil(this.fileSize / this.chunkSize);
      this.downloadChunkFile(0, row);
    },
    async downloadChunkFile(chunkIdx, row) {
      // if (chunkIdx >= this.chunksNum) {
      //   alert('分片索引不可超过分片数量')
      //   return
      // }
      //
      let start = chunkIdx * this.chunkSize;
      let end = Math.min(start + this.chunkSize - 1, this.fileSize - 1);
      const range = `bytes=${start}-${end}`;
      streamSaver.mitm = Global.serveUrl + "mitm.html?version=2.0.0";
      // 使用 Fetch API 请求文件
      fetch(
        Global.baseURL2 +
          "Api/yw/profile/download?rangeHeader=" +
          range +
          "&path=" +
          row.fileUrl +
          "&fileName=" +
          row.fileName
      ).then((res) => {
        this.chunks.push(res.data);
        // 创建写入流 filename为下载的文件名
        const fileStream = streamSaver.createWriteStream(row.fileName, {
          size: res.headers.get("content-length"),
        });
        const readableStream = res.body;
        if (window.WritableStream && readableStream.pipeTo) {
          return readableStream.pipeTo(fileStream).then(() => {});
        }
        window.writer = fileStream.getWriter();
        const reader = res.body.getReader();
        const pump = () =>
          reader
            .read()
            .then((res) =>
              res.done
                ? window.writer.close()
                : window.writer.write(res.value).then(pump)
            );
        pump();
      });

      // this.$http({
      //   url: "Api/yw/profile/download2",
      //   method: 'GET',
      //   params: {
      //     rangeHeader: range,
      //     path: row.fileUrl,
      //     fileName: row.fileName,
      //   },
      //   responseType: 'arraybuffer'
      // }).then(response => {
      //
      //   this.chunks.push(response.data)
      //   if (chunkIdx == this.chunksNum - 1) {
      //     // 下载好了
      //     console.log(this.chunks, 'chunks');
      //     // 组合chunks到单个文件
      //     const blob = new Blob(this.chunks);
      //     console.log(blob, 'blob');
      //     const link = document.createElement('a');
      //     link.href = window.URL.createObjectURL(blob);
      //     link.download = 'demo.mp4';
      //     link.click();
      //     return
      //   } else {
      //     ++chunkIdx
      //     this.downloadChunkFile(chunkIdx,row)
      //   }
      // })
    },
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      // let am = h < 12 ? "AM" : "PM";
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      return y + "-" + MM + "-" + d + "  " + h + ":" + m;
    },
    formatDay: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    },
  },
};
</script>

<style>
.searchblur {
  border: 1px solid #3d7eff !important;
}
.head_sel {
  height: 60px;
  /* background-color: #fff; */
}
.sel_left {
  padding-top: 10px;
  padding-bottom: 10px;
  float: left;
}
.filecenter_form {
  text-align: left;
  /* padding: 0px 20px; */
}
.joinPro_con {
  background: #fff;
  padding-bottom: 20px;
  /* padding: 0px 20px; */
}
.joinPro_tab {
  padding: 20px 20px;
  /* padding-top: 20px; */
}
</style>
