<template>
  <el-menu
    :collapse="isSidebarNavCollapse"
    background-color="#304156"
    text-color="#eee"
    active-text-color="#4dbcff"
    :default-active="currentMenu"
  >
    <!-- <DynamicMenu :menuList="sidebarMenu"></DynamicMenu> -->
    <el-submenu index="1">
      <template slot="title">
        <i class="el-icon-location"></i>
        <span slot="title">导航一</span>
      </template>
      <el-menu-item-group>
        <!-- <span slot="title">分组一</span> -->
        <el-menu-item index="1-1">选项1</el-menu-item>
        <el-menu-item index="1-2">选项2</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item index="2">
      <i class="el-icon-menu"></i>
      <span slot="title">导航二</span>
    </el-menu-item>

    <template v-for="v in sidebarMenu">
      <el-submenu
        :index="v.name"
        v-if="v.children && v.children.length > 0"
        :key="v.name"
      >
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>{{ v.meta.name }}</span>
        </template>
        <!-- <el-menu-item-group> -->
        <el-menu-item
          v-for="s in v.children"
          :index="s.name"
          :key="s.name"
          @click="gotoRoute(s.name)"
        >
          {{ s.meta.name }}
        </el-menu-item>
        <!-- </el-menu-item-group> -->
      </el-submenu>
      <el-menu-item
        :key="v.name"
        :index="v.name"
        @click="gotoRoute(v.name)"
        v-else
      >
        <i class="el-icon-location"></i>
        <span slot="title">{{ v.meta.name }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import DynamicMenu from "@/components/dynamic-menu";
import { mapState } from "vuex";

export default {
  data() {
    return {
      isCollapse: true,
    };
  },
  computed: {
    ...mapState(["isSidebarNavCollapse"]),
    ...mapState(["sidebarMenu", "currentMenu"]),
  },
  methods: {},
  components: {
    DynamicMenu,
  },
};
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>