<template>
  <div class="isAuth">
    <el-dialog
      custom-class="isAuthenticDialog"
      :visible.sync="dialogIdcardauth"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      append-to-body
    >
      <p class="dialog_tit">实名认证</p>
      <p class="dialog_tit2">请如实填写信息，平台承诺保障用户的信息安全</p>

      <div style="height: 200px">
        <el-form
          :inline="true"
          :model="ownerInfo"
          ref="ownerInfo"
          class=""
          style="text-align: left"
        >
          <div class="idcard_form">
            <el-form-item label="真实姓名:">
              <el-input v-model="ownerInfo.realName"></el-input>
            </el-form-item>
            <el-form-item label="证件号:">
              <el-input v-model="ownerInfo.idCard"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <p class="idcard_but pointer" @click="goIdcardauth()">认证</p>
      </div>
    </el-dialog>

    <el-dialog
      custom-class="isAuthenticDialog"
      :close-on-click-modal="false"
      :visible.sync="dialogbankCardauth"
      width="560px"
      :before-close="handleClose"
      :modal-append-to-body="false"
      append-to-body
    >
      <p class="dialog_tit">银行卡认证</p>
      <p class="dialog_tit2">请如实填写信息，平台承诺保障用户的信息安全</p>

      <div style="">
        <el-form
          :inline="true"
          :model="bankInfo"
          :rules="rules"
          ref="bankInfo"
          class=""
          style="text-align: left"
        >
          <div class="bankcard_form">
            <el-form-item label="银行卡号：" prop="bankCardNo">
              <el-input
                placeholder="请输入银行卡号"
                v-model="bankInfo.bankCardNo"
              ></el-input>
            </el-form-item>
            <el-form-item label="持卡人姓名：" prop="realName">
              <el-input
                  disabled
                placeholder="请输入持卡人姓名"
                v-model="bankInfo.realName"
              ></el-input>
            </el-form-item>
            <el-form-item label="持卡人身份证号：" prop="idCard">
              <el-input
                  disabled
                placeholder="请输入持卡人身份证号"
                v-model="bankInfo.idCard"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行预留手机号：" prop="mobile">
              <el-input
                placeholder="请输入银行预留手机号"
                v-model="bankInfo.mobile"
              ></el-input>
            </el-form-item>

            <!-- <p class="el-form-item__label" style="margin-top: 18px">验证码：</p>
            <el-form-item prop="code">
              <div class="mobile_box">
                <div class="auth_code_input" style="width: 200px; float: left">
                  <el-input
                    type="text"
                    placeholder="请输入验证码"
                    v-model="ownerInfo.code"
                    autocomplete="off"
                  >
                  </el-input>
                </div>
                <div class="sencode_but pointer" @click="sendCode()">
                  获取验证码
                </div>
              </div>
            </el-form-item> -->
          </div>
        </el-form>
        <p class="idcard_but pointer" @click="goBackcardauth()">认证</p>
      </div>
    </el-dialog>

    <el-dialog
      custom-class="isAuthenticDialog"
      :visible.sync="dialogchangephone"
      :close-on-click-modal="false"
      width="540px"
      :before-close="handleClose"
      :modal-append-to-body="false"
      append-to-body
    >
      <p class="dialog_tit">修改手机号</p>
      <div style="">
        <el-form
          :inline="true"
          :model="phonedata"
          ref="ownerInfo"
          class=""
          style="text-align: left"
        >
          <div class="userphone_form">
            <el-form-item label="当前手机号">
              <el-input v-model="phone" disabled></el-input>
            </el-form-item>

            <p class="el-form-item__label" style="margin-top: 18px">验证码：</p>
            <el-form-item prop="code">
              <div class="mobile_box">
                <div class="auth_code_input" style="width: 200px; float: left">
                  <el-input
                    type="text"
                    placeholder="请输入验证码"
                    v-model="phonedata.code"
                    autocomplete="off"
                    maxlength="6"
                  >
                  </el-input>
                </div>
                <div class="sencode_but pointer" @click="sendCode(phone, 1, 1)">
                  {{ codeStr }}
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>
        <Vcode :show="isShow" @success="success" @close="close" />
        <p class="idcard_but pointer" @click="changeNextstep(1)">下一步</p>
      </div>
    </el-dialog>

    <el-dialog
      custom-class="isAuthenticDialog"
      :visible.sync="dialognewphone"
      width="540px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      append-to-body
    >
      <p class="dialog_tit">新手机号</p>
      <div style="">
        <el-form
          :inline="true"
          :model="newphonedata"
          ref="ownerInfo"
          class=""
          style="text-align: left"
        >
          <div class="userphone_form">
            <el-form-item label="新的手机号">
              <el-input v-model="newphonedata.phone"></el-input>
            </el-form-item>

            <p class="el-form-item__label" style="margin-top: 18px">验证码：</p>
            <el-form-item prop="code">
              <div class="mobile_box">
                <div class="auth_code_input" style="width: 200px; float: left">
                  <el-input
                    type="text"
                    placeholder="请输入验证码"
                    v-model="newphonedata.code"
                    autocomplete="off"
                    maxlength="6"
                  >
                  </el-input>
                </div>
                <div
                  class="sencode_but pointer"
                  @click="sendCode(newphonedata.phone, 2, 0)"
                >
                  {{ codeStr2 }}
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>
        <p class="idcard_but pointer" @click="preservephone()">保存</p>
      </div>
    </el-dialog>

    <el-dialog
      custom-class="isAuthenticDialog"
      :visible.sync="dialogchangebankCard"
      width="540px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      append-to-body
    >
      <p class="dialog_tit">修改银行卡号</p>
      <div style="">
        <el-form
          :inline="true"
          :model="phonedata"
          ref="ownerInfo"
          class=""
          style="text-align: left"
        >
          <div class="userphone_form">
            <el-form-item label="当前手机号">
              <el-input v-model="phone" disabled></el-input>
            </el-form-item>

            <p class="el-form-item__label" style="margin-top: 18px">验证码：</p>
            <el-form-item prop="code">
              <div class="mobile_box">
                <div class="auth_code_input" style="width: 200px; float: left">
                  <el-input
                    type="text"
                    placeholder="请输入验证码"
                    v-model="phonedata.code"
                    autocomplete="off"
                    maxlength="6"
                  >
                  </el-input>
                </div>
                <div class="sencode_but pointer" @click="sendCode(phone, 1, 1)">
                  {{ codeStr }}
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form>
        <Vcode :show="isShow" @success="success" @close="close" />
        <p class="idcard_but pointer" @click="changeNextstep(2)">下一步</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Global from "@/assets/js/global";
import Vcode from "vue-puzzle-vcode";

export default {
  name: "Home",
  components: { Vcode },
  props: {
    phone: {
      type: String,
    },
    usercard: {
      type: Object,
    },
  },

  data() {
    return {
      dialogIdcardauth: false,
      dialogbankCardauth: false,
      dialogchangephone: false,
      dialognewphone: false,
      dialogchangebankCard: false,
      codeStr: "获取验证码",
      codeStr2: "获取验证码",
      isMyself: 0,
      sendSmsPhone: 0,
      sendSmsType: 0,
      phonedata: {
        phone: "",
        code: "",
      },

      newphonedata: {
        phone: "",
        code: "",
      },
      ownerInfo: {
        realName: "",
        idCard: "",
      },
      bankInfo: {
        realName: "",
        idCard: "",
        bankCardNo: "",
        mobile: "",
      },
      isCode: true,
      isShow: false,
      count: 0,
      rules: {
        bankCardNo: [
          { required: true, message: "请输入银行卡号", trigger: "blur" },
        ],
        realName: [
          { required: true, message: "请输入持卡人姓名", trigger: "blur" },
        ],

        idCard: [
          { required: true, message: "请输入持卡人身份证号", trigger: "blur" },
        ],

        mobile: [
          { required: true, message: "请输入银行预留手机号", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {},
  computed: {},
  created() {},

  methods: {
    handleClose() {
      this.dialogIdcardauth = false;
      this.dialogbankCardauth = false;
      this.dialogchangephone = false;
      this.dialognewphone = false;
      this.dialogchangebankCard = false;
    },
    idcardauthMethod() {
      this.ownerInfo.realName = "";
      this.ownerInfo.idCard = "";
      this.dialogIdcardauth = true;
    },
    bankcardauthMethod() {
      this.dialogbankCardauth = true;
      this.bankInfo.realName = this.usercard.bankUserName;
      this.bankInfo.idCard = this.usercard.bankUserCard;
      // this.bankInfo = this.usercard;
    },
    goIdcardauth() {
      const idRegex =
        /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$/;
      if (this.ownerInfo.realName == "") {
        this.$message.error("请输入真实姓名！");
        return false;
      }
      var isidCard = idRegex.test(this.ownerInfo.idCard);
      if (!isidCard) {
        this.$message.error("请输入正确的身份证号！");
        return false;
      }

      this.$http.post("Api/user/user/IdCheck", this.ownerInfo).then((res) => {
        if (res.data.result == true) {
          this.dialogIdcardauth = false;
          this.$message.success("认证成功");
          window.sessionStorage.setItem("isCredentials", res.data.data);
          this.$emit("getUserCredinfo");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    goBackcardauth() {
      this.$refs.bankInfo.validate(async (valid) => {
        if (!valid) return;
        this.$http
          .post("Api/user/user/bankCardCheck", this.bankInfo)
          .then((res) => {
            if (res.data.result == true) {
              this.$message.success("绑定成功");
              this.dialogbankCardauth = false;
              window.sessionStorage.setItem("isCredentials", res.data.data);
              this.$emit("getUserCredinfo");
            } else {
              this.$message.error(res.data.msg);
            }
          });
      });
    },

    // 修改手机号

    changeUserPhoneMethod() {
      this.dialogchangephone = true;
      this.phonedata.code = "";
      this.codeStr = "获取验证码";
    },
    editbankcardMethod() {
      this.dialogchangebankCard = true;
      this.phonedata.code = "";
      this.codeStr = "获取验证码";
      this.bankInfo.realName = this.usercard.bankUserName;
      this.bankInfo.idCard = this.usercard.bankUserCard;
    },
    //发送短信
    sendCode(phone, type, isMyself) {
      this.isMyself = isMyself;
      this.sendSmsPhone = phone;
      this.sendSmsType = type;
      let _this = this;
      if (_this.isCode && isMyself == 0 && _this.count == 0) {
        _this.count = 1;
        _this.newSendCode(phone, type);
      } else if (_this.isCode && isMyself == 1 && _this.count == 0) {
        _this.count = 1;
        _this.SendMyCode(); //token
      } else if (_this.isCode && _this.count == 1) {
        _this.isShow = true;
      }
    },

    newSendCode(phone, type) {
      let _this = this;
      let num = 60;
      _this.isCode = false;
      if (phone == "") {
        this.$message.error("请输入手机号码");
        return false;
      }

      const regMobile =
        /^(0|86|17951)?(13[0-9]|14[0-9]|16[0-9]|15[0-9]|17[0-9]|18[0-9]|19[0-9]|)[0-9]{8}$/;

      if (!regMobile.test(phone)) {
        _this.isCode = true;
        this.$message.error("请输入正确的手机号码");
        return false;
      }
      _this.count = 1;
      // _this.isCode = false;
      _this.errorStr = "";
      _this
        .$http({
          method: "post",
          url: "Api/user/user/sendSms?phone=" + phone + "&flag=" + type,
        })
        .then((res) => {
          if (res.data.result) {
            let dsq = setInterval(function () {
              _this.codeStr2 = num + "s";
              if (num == 0) {
                window.clearInterval(dsq);
                _this.codeStr2 = "重新获取验证码";
                _this.isCode = true;
                return;
              }
              num--;
            }, 1000);
          } else {
            if (
              res.data.code == 100 ||
              res.data.code == 101 ||
              res.data.code == 102
            ) {
              this.$message.error(res.data.msg);
              _this.isCode = true;
              _this.errorStr = res.data.msg;
            }
          }
        });
    },

    SendMyCode(phone, type) {
      let _this = this;
      let num = 60;
      _this.isCode = false;
      _this.count = 1;
      _this.errorStr = "";
      _this
        .$http({
          method: "post",
          url: "Api/user/user/sendUserSms",
        })
        .then((res) => {
          if (res.data.result) {
            let dsq = setInterval(function () {
              _this.codeStr = num + "s";
              if (num == 0) {
                window.clearInterval(dsq);
                _this.codeStr = "重新获取验证码";
                _this.isCode = true;
                return;
              }
              num--;
            }, 1000);
          } else {
            if (
              res.data.code == 100 ||
              res.data.code == 101 ||
              res.data.code == 102
            ) {
              this.$message.error(res.data.msg);
              _this.isCode = true;
              _this.errorStr = res.data.msg;
            }
          }
        });
    },

    changeNextstep(type) {
      this.isCode = true;
      this.phonedata.phone = this.phone;
      this.$http.post("/Api/user/user/isPhone", this.phonedata).then((res) => {
        if (res.data.result == true) {
          this.$message.success("验证成功");

          if (type == 1) {
            this.dialogchangephone = false;
            this.dialognewphone = true;
          } else {
            this.dialogchangebankCard = false;
            this.dialogbankCardauth = true;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    preservephone() {
      this.$http
        .post("/Api/user/user/changePhone", this.newphonedata)
        .then((res) => {
          if (res.data.result == true) {
            this.$message.success("更换成功");
            var data = res.data.data;

            window.sessionStorage.setItem("isCredentials", data.isCredentials);
            this.dialognewphone = false;

            window.sessionStorage.setItem(
              "token",
              "Bearer " + data.accessToken
            );

            window.sessionStorage.setItem("userImg", data.imageUrl);
            window.sessionStorage.setItem("isCredentials", data.isCredentials);

            this.$emit("getUserCredinfo");
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    success(msg) {
      this.isShow = false; // 通过验证后，需要手动隐藏模态框
      let _this = this;
      if (_this.isCode && this.isMyself == 0 && _this.count > 0) {
        _this.count = 1;
        _this.newSendCode(this.sendSmsPhone, this.sendSmsType);
      } else if (_this.isCode && this.isMyself == 1 && _this.count > 0) {
        _this.count = 1;
        _this.SendMyCode(); //token
      }
    },
    // 用户点击遮罩层，应该关闭模态框
    close() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped>
</style>
