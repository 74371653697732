<template>
  <div style="width: 100%; background: #fff">
    <div class="useterm_box useterm_box2" style="min-height: 600px">
      <div class="useterm_con">
        <div class="orderNotice_list">
          <p class="shouldknow_tit">
            1.构件前缀的编写（每个构件，构件中仅允许其中一个零件有构件号，构件前缀加“-”）
          </p>
          <div style="margin-top: 30px">
            <el-table :data="tableData" border style="width: 718px">
              <el-table-column prop="name" label="构件名称" width="140">
              </el-table-column>
              <el-table-column
                prop="prefix"
                label="构件前缀（后加“-”）"

              >
              </el-table-column>
              <el-table-column prop="name2" label="构建名称" width="140"> </el-table-column>
              <el-table-column prop="prefix2" label="构件前缀（后加“-”）">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="orderNotice_list" style="margin-left: 20px">
          <p class="shouldknow_tit">2.零件前缀的编写</p>
          <div style="margin-top: 30px">
            <el-table :data="tableData2" border style="width: 600px">
              <el-table-column prop="name" label="截面型材" width="180">
              </el-table-column>
              <el-table-column prop="prefix" label="零件前缀" width="">
              </el-table-column>
              <el-table-column prop="name2" label="截面型材" width="180">
              </el-table-column>
              <el-table-column prop="prefix2" label="零件前缀" width="">
              </el-table-column>
            </el-table>
          </div>
        </div>

        <p style="clear: both"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          name: "钢柱",
          prefix: "GZ",
          name2: "天沟",
          prefix2: "TG",
        },
        {
          name: "钢梁",
          prefix: "GL",
          name2: "天沟托架",
          prefix2: "TJ",
        },
        {
          name: "抗风柱",
          prefix: "KFZ",
          name2: "雨棚",
          prefix2: "YP/YPL",
        },
        {
          name: "吊车梁",
          prefix: "DCL",
          name2: "门柱",
          prefix2: "MZ",
        },

        {
          name: "柱间支撑",
          prefix: "ZC",
          name2: "门梁",
          prefix2: "ML",
        },
        {
          name: "水平支撑",
          prefix: "SC",
          name2: "窗柱",
          prefix2: "CZ",
        },
        {
          name: "系杆",
          prefix: "XG",
          name2: "预埋件/埋件",
          prefix2: "YMJ/MJ",
        },
        {
          name: "屋面/墙面檀条",
          prefix: "(W/Q)L",
          name2: "地脚锚栓",
          prefix2: "DJMS",
        },
        {
          name: "屋面/墙面直拉条",
          prefix: "(W/Q)T",
          name2: "爬梯",
          prefix2: "PT",
        },
        {
          name: "屋面/墙面斜拉条",
          prefix: "(W/Q)XT",
          name2: "平台梁/钢平台",
          prefix2: "PTL/GPT",
        },
        {
          name: "屋面/墙面撑杆",
          prefix: "(W/Q)CG",
          name2: "楼梯",
          prefix2: "LT",
        },
        {
          name: "屋面/墙面隅撑",
          prefix: "(W/Q)YC",
          name2: "散件",
          prefix2: "SJ",
        },
        {
          name: "封边角钢",
          prefix: "FB",
          name2: "垫板",
          prefix2: "DB",
        },
      ],

      tableData2: [
        {
          name: "H型钢",
          prefix: "H*(HN窄翼缘,HM中翼缘,HW宽翼缘)",
          name2: "圆钢",
          prefix2: "D",
        },
        {
          name: "焊接H型钢",
          prefix: "BH",
          name2: "圆管",
          prefix2: "Y",
        },
        {
          name: "高频焊H型钢",
          prefix: "LH",
          name2: "圆锥管",
          prefix2: "BY",
        },
        {
          name: "板拼H型钢翼缘板",
          prefix: "BHY",
          name2: "方管",
          prefix2: "F",
        },
        {
          name: "板拼H型钢腹板",
          prefix: "BHF",
          name2: "方钢",
          prefix2: "FD",
        },

        {
          name: "板材",
          prefix: "P",
          name2: "C型钢",
          prefix2: "CC",
        },
        {
          name: "板材散件",
          prefix: "SP",
          name2: "Z型钢",
          prefix2: "ZZ",
        },
        {
          name: "与构件为整体的散发件",
          prefix: "XP",
          name2: "垫板",
          prefix2: "DB",
        },
        {
          name: "角钢",
          prefix: "L",
          name2: "工字钢",
          prefix2: "I",
        },
        {
          name: "槽钢",
          prefix: "C",
          name2: "",
          prefix2: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
@import "@/assets/css/webUseterm.css";
.orderNotice_tip {
  height: 60px;
  width: 100%;
  line-height: 60px;
  border-bottom: 2px solid #e7eaee;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  text-align: left;
}
.useterm_box2 .orderNotice_list {
  width: calc((100% - 20px) / 2);
  float: left;
}
.orderNotice_list {
  margin-top: 36px;
}
.shouldknow_tit {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
}
.shouldknow_txt {
  font-size: 14px;
  color: #5a5a5a;
  margin-top: 10px;
}
</style>