<template>
  <div class="login_box" style="position: fixed; height: 100%">
    <div class="register_bg">
      <!-- <img src="../../assets/images/bg-111.png" alt="" />
    </div> -->
      <div class="login_con" style="top: 110px; right: 140px; height: 684px">
        <div class="login_con_right">
          <div class="right_con">
            <div class="login_title">
              <div class="log_tit_left">注册账号</div>
            </div>

            <el-form
              :model="registerForm"
              status-icon
              :rules="rules"
              ref="registerFormRes"
              label-width="100px"
              class="demo-ruleForm"
            >
              <p class="input_label">手机号</p>
              <el-form-item label="" prop="phone">
                <div
                  class="pass_input user_input pointer"
                  style="margin-top: 10px"
                  :class="phoneclass"
                  @click="changebor('phoneclass')"
                >
                  <img
                    src="../../assets/images/phone.png"
                    alt=""
                    class="passicon"
                  />
                  <el-input
                    type=""
                    v-model="registerForm.phone"
                    autocomplete="off"
                    placeholder="请输入您的手机号"
                  ></el-input>
                </div>
              </el-form-item>
              <p class="input_label">验证码</p>
              <el-form-item prop="code">
                <div style="margin-top: 10px">
                  <div
                    class="code_input"
                    :class="vercodeclass"
                    style="width: 200px; float: left"
                    v-on:click="clickfun"
                  >
                    <el-input
                      type="text"
                      placeholder="请输入验证码"
                      v-model="registerForm.code"
                      maxlength="6"
                    >
                    </el-input>
                  </div>
                  <div
                    slot="suffix"
                    style="color: #7856fd"
                    class="code pointer"
                    v-on:click="sendCode()"
                  >
                    {{ codeStr }}
                  </div>
                </div>
              </el-form-item>
              <p class="input_label">输入密码</p>
              <!-- <input name="a" style="" type="password" placeholder="密码" /> -->
              <el-form-item label="" prop="password" class="el_pass">
                <div
                  class="pass_input user_input pointer"
                  :class="borclass"
                  @click="changebor('passclass')"
                  style="margin-top: 10px"
                >
                  <img
                    src="../../assets/images/mm.png"
                    alt=""
                    class="passicon"
                  />

                  <!-- :class="isautofill ? 'no-autofill-pwd' : ''"
                  autocomplete="new-password" -->
                  <el-input
                    :type="passwordType"
                    v-model="registerForm.password"
                    placeholder="请输入密码"
                    autocomplete="new-password"
                    @input="changeType(1)"
                  ></el-input>
                  <img
                    :src="imgsrc"
                    alt=""
                    class="eyeicon"
                    @mouseover="showPassword"
                    @mouseleave="mouseLeave"
                  />
                </div>
              </el-form-item>

              <div class="grade">
                <div>
                  <p class="default" :class="ruoclass"></p>
                  <span>弱</span>
                </div>
                <div>
                  <p class="default" :class="zhongclass"></p>
                  <span>中</span>
                </div>
                <div>
                  <p class="default" :class="qiangclass"></p>
                  <span>强</span>
                </div>
              </div>
              <p class="input_label">确认密码</p>
              <el-form-item label="" prop="password2">
                <div
                  class="pass_input user_input pointer"
                  style="margin-top: 10px"
                  :class="pass2class"
                  @click="changebor('pass2class')"
                >
                  <img
                    src="../../assets/images/mm.png"
                    alt=""
                    class="passicon"
                  />
                  <el-input
                    :type="passwordType2"
                    v-model="registerForm.password2"
                    autocomplete="off"
                    @input="changeType(2)"
                    placeholder="请确认密码（6-16个字符）"
                  ></el-input>
                  <img
                    :src="imgsrc2"
                    alt=""
                    class="eyeicon"
                    @mouseover="mousePass"
                    @mouseleave="leavePass"
                  />
                </div>
              </el-form-item>

              <div class="verify">
                <div
                  class="verify_input pointer"
                  :class="verifyclass"
                  @click="changebor('verifyclass')"
                >
                  <el-form-item prop="verifycode">
                    <el-input
                      v-model="registerForm.verifycode"
                      placeholder="请输入图形验证码"
                      class="identifyinput"
                      maxlength="4"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="identifybox">
                  <el-form-item>
                    <div @click="refreshCode">
                      <s-identify :identifyCode="identifyCode"></s-identify>
                    </div>
                    <!-- <el-button @click="refreshCode" type="text" class="textbtn"
                    >看不清，换一张</el-button
                  > -->
                  </el-form-item>
                </div>
              </div>
              <p class="protoAgree" style="margin-top: 30px">
                <el-checkbox v-model="checked"></el-checkbox>
                我已阅读并同意<span class="pointer" @click="goprotoAgree(1)"
                  >《用户服务协议》</span
                >和<span class="pointer" @click="goprotoAgree(2)"
                  >《个人信息保护政策》</span
                >
              </p>
              <el-form-item style="">
                <div class="login_but">
                  <Vcode :show="isShow" @success="success" @close="close" />
                  <el-button
                    :disabled="registerabled"
                    type="primary"
                    @click="register()"
                    >注册</el-button
                  >
                </div>
              </el-form-item>
              <div class="register_but">
                已有帐号,<span class="pointer" @click="gotologin()"
                  >去登录</span
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <div class="login_bottom">
      <div class="recordNo">
        <div class="login_bottom_text">
          <span
            ><img
              src="../../assets/images/bottom/copyright.png"
              alt=""
              style="vertical-align: middle"
          /></span>
          <span>zzyjzkj 2021 - {{ year }} 山西众之翼建筑科技有限公司</span>
          <span>|</span>
          <span style="margin-top: -1px"
            ><img
              src="../../assets/images/bottom/record_icon.png"
              alt=""
              style="vertical-align: middle"
          /></span>
          <span
            ><a
              target="_blank"
              class="icphref"
              style="text-decoration-line: none"
              href="https://beian.miit.gov.cn/"
              >晋ICP备2022000048号</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SIdentify from "@/components/canvas.vue";
export default {
  components: {
    SIdentify,
  },
  data() {
    var validateUser = (rule, value, callback) => {
      if (!value) {
        this.userclass = "borclass";
        return callback(new Error("账号不能为空"));
      } else if (!value.match(/(wth-)+\d/)) {
        return callback(new Error("正确格式需要以wth-开头"));
      } else if (value.match(/(wth-)+\d+\D/)) {
        return callback(new Error("数字后不能加其他字符"));
      } else if (!value.match(/(wth-)+\d{4,6}$/)) {
        return callback(new Error("数字限制长度4-6位"));
      } else {
        this.userclass = "";
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      const reg =
        /^(?=.{2,5}$)(((?![\u3000-\u303F])[\u2E80-\uFE4F]|\·)*(?![\u3000-\u303F])[\u2E80-\uFE4F](\·)*)$/;
      if (!value) {
        this.nameclass = "borclass";
        return callback(new Error("姓名不能为空"));
      } else if (!value.match(/^[\u2E80-\u9FFF]+$/)) {
        return callback(new Error("姓名必须是汉字"));
      } else if (!value.match(reg)) {
        return callback(new Error("姓名限制长度2-5位"));
      } else {
        this.nameclass = "";
        callback();
      }
    };

    // var checkMobile = (rule, value, cb) => {
    //   const regMobile =
    //     /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
    //   if (regMobile.test(value)) {
    //     // 合法的手机号码
    //     return cb();
    //   }
    //   cb(new Error("手机号码格式不正确"));
    // };

    var validatePhone = (rule, value, callback) => {
      const regMobile =
        /^(0|86|17951)?(13[0-9]|14[0-9]|16[0-9]|15[0-9]|17[0-9]|18[0-9]|19[0-9]|)[0-9]{8}$/;
      if (!value) {
        this.phoneclass = "borclass";
        return callback(new Error("手机号不能为空"));
      } else if (!regMobile.test(value)) {
        this.phoneclass = "borclass";
        return callback(new Error("请输入正确的手机号"));
      } else {
        this.phoneclass = "";
        callback();
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === "") {
        this.borclass = "borclass";
        this.ruoclass = "";
        this.zhongclass = "";
        this.qiangclass = "";
        return callback(new Error("请输入密码"));
      } else {
        var ls = 0;

        if (this.registerForm.password !== "") {
          if (this.registerForm.password.match(/([a-z])+/)) {
            ls++;
          }

          if (this.registerForm.password.match(/([0-9])+/)) {
            ls++;
          }

          if (this.registerForm.password.match(/([A-Z])+/)) {
            ls++;
          }
          if (
            this.registerForm.password.match(/([\W])+/) &&
            !this.registerForm.password.match(/(![\u4E00-\u9FA5])+/)
          ) {
            ls++;
          }
          if (
            this.registerForm.password.length < 6 ||
            this.registerForm.password.length > 16
          ) {
            this.borclass = "borclass";
            this.ruoclass = "";
            this.zhongclass = "";
            this.qiangclass = "";
            callback(new Error("要求6-16位字符"));
            ls = 0;
          }
          if (this.registerForm.password.match(/([\u4E00-\u9FA5])+/)) {
            this.borclass = "borclass";
            this.ruoclass = "";
            this.zhongclass = "";
            this.qiangclass = "";
            callback(new Error("不能包含中文字符"));
            ls = 0;
          }
          switch (ls) {
            case 0:
              this.passwordPercent = 0;
              this.borclass = "borclass";
              this.ruoclass = "";
              this.zhongclass = "";
              this.qiangclass = "";
              callback(new Error("密码至少是字母（不区分大小写）+数字,6-16位"));
              break;
            case 1:
              // this.passwordPercent = 33;
              this.borclass = "borclass";
              this.ruoclass = "";
              this.zhongclass = "";
              this.qiangclass = "";
              callback(new Error("密码至少是字母（不区分大小写）+数字,6-16位"));
              break;
            case 2:
              this.ruoclass = "red";
              this.zhongclass = "";
              this.qiangclass = "";
              this.borclass = "";
              break;
            case 3:
              this.zhongclass = "yellow";
              this.qiangclass = "";
              this.ruoclass = "";
              this.borclass = "";
              break;
            case 4:
              this.qiangclass = "blue";
              this.ruoclass = "";
              this.zhongclass = "";
              this.borclass = "";
              break;
            default:
              this.passwordPercent = 0;
              break;
          }
        }

        callback();
        // this.borclass = "";
      }
    };

    var validateconPass = (rule, value, callback) => {
      if (value === "") {
        this.pass2class = "borclass";
        return callback(new Error("请输入确认密码"));
      } else if (value !== this.registerForm.password) {
        this.pass2class = "borclass";
        return callback(new Error("两次输入密码不一致"));
      } else {
        this.pass2class = "";
        callback();
      }
    };

    const validateVerifycode = (rule, value, callback) => {
      if (value === "") {
        this.verifyclass = "borclass";
        callback(new Error("请输入图形码"));
      } else if (value !== this.identifyCode) {
        this.verifyclass = "borclass";
        callback(new Error("图形码验证错误!"));
      } else {
        this.verifyclass = "";
        callback();
      }
    };

    const validatecode = (rule, value, callback) => {
      if (value === "") {
        this.vercodeclass = "borclass";
        callback(new Error("请输入手机验证码"));
      } else {
        this.vercodeclass = "";
        callback();
      }
    };

    return {
      options: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],

      borclass: "",
      userclass: "",
      nameclass: "",
      phoneclass: "",
      pass2class: "",
      vercodeclass: "",
      verifyclass: "",
      isautofill: true,
      codeStr: "获取验证码",
      isCode: true,
      errorStr: "",
      isShow: false,
      count: 0, //发送验证码次数
      identifyCodes: "1234567890",
      passwordPercent: 0,
      ruoclass: "",
      zhongclass: "",
      qiangclass: "",
      identifyCode: "",
      value: "",
      rolevalue: "",
      registerForm: {
        password: "",
        password2: "",
        phone: "",
        code: "",
        userNickName: "",
      },
      checked: false,
      rules: {
        userNickName: [{ validator: validateName, trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "change" }],
        password2: [{ validator: validateconPass, trigger: "blur" }],
        verifycode: [{ validator: validateVerifycode, trigger: "blur" }],
        code: [{ validator: validatecode, trigger: "blur" }],
      },
      passwordPercentFormat(percentage) {
        return percentage === 100 ? "符合" : `不符`;
      },
      imgsrc: require("@/assets/images/c-eye.png"),
      passwordType: "text",
      imgsrc2: require("@/assets/images/c-eye.png"),
      passwordType2: "text",
      userbor: "",
      year: "",
      registerabled: false,
    };
  },
  mounted() {
    // 验证码初始化
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  created() {
    const timeOne = new Date();
    this.year = timeOne.getFullYear();
  },
  methods: {
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    changeType(type) {
      if (type == 1) {
        this.passwordType = "password";
      } else {
        this.passwordType2 = "password";
      }
    },

    register() {
      this.registerabled = true;
      this.$refs.registerFormRes.validate(async (valid) => {
        this.registerabled = false;
        if (!valid) return;

        if (!this.checked) {
          this.$message.error(
            " 请先阅读并同意《用户服务协议》和《个人信息保护政策》"
          );
          this.registerabled = false;
          return false;
        }

        // const res = this.$http.post('Api/ht/login',{username:this.loginForm.username});
        const { data: res } = await this.$http({
          method: "post",
          url: "/Api/user/user/register",
          data: this.registerForm,
        });
        if (!res.result) return this.$message.error(res.msg);
        this.$message.success("注册成功！");
        this.$router.push("/login");
        this.registerabled = false;
      });
    },
    clickfun() {
      this.borclass = "inputclass";
    },
    clickpassfun() {
      this.passborclass = "inputclass";
    },
    inputFunc() {
      this.borclass = "";
    },
    passinputFunc() {
      this.passborclass = "";
    },
    //发送短信
    sendCode() {
      let _this = this;

      if (_this.isCode) {
        _this.newSendCode();
      }
    },
    success() {
      this.isShow = false; // 通过验证后，需要手动隐藏模态框
      let _this = this;
      if (_this.isCode && _this.count > 0) {
        _this.count = 1;
        _this.newSendCode();
      }
    },
    close() {
      this.isShow = false;
    },
    newSendCode() {
      let _this = this;
      let num = 60;
      if (_this.registerForm.phone == "") {
        this.$message.error("请输入手机号码");
        return false;
      }
      const regMobile =
        /^(0|86|17951)?(13[0-9]|14[0-9]|16[0-9]|15[0-9]|17[0-9]|18[0-9]|19[0-9]|)[0-9]{8}$/;
      if (!regMobile.test(_this.registerForm.phone)) {
        this.$message.error("请输入正确的手机号码");
        return false;
      }
      // _this.count = 1;
      _this.isCode = false;
      _this.errorStr = "";
      _this
        .$http({
          method: "post",
          url: "Api/user/user/sendSms?phone=" + _this.registerForm.phone,
        })
        .then((res) => {
          if (res.data.result) {
            let dsq = setInterval(function () {
              _this.codeStr = num + "s";
              if (num == 0) {
                window.clearInterval(dsq);
                _this.codeStr = "重新获取验证码";
                _this.isCode = true;
                return;
              }
              num--;
            }, 1000);
          } else {
            if (
              res.data.code == 100 ||
              res.data.code == 101 ||
              res.data.code == 102
            ) {
              this.$message.error(res.data.msg);
              _this.isCode = true;
              _this.errorStr = res.data.msg;
            }
          }
        });
    },

    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    showPassword() {
      this.passwordType = "text";
      this.isautofill = false;
      this.imgsrc = require("@/assets/images/eye.png");
    },

    mouseLeave() {
      this.passwordType = "password";
      this.isautofill = true;
      this.imgsrc = require("@/assets/images/c-eye.png");
    },
    mousePass() {
      this.passwordType2 = "text";
      this.imgsrc2 = require("@/assets/images/eye.png");
    },
    leavePass() {
      this.passwordType2 = "password";
      this.imgsrc2 = require("@/assets/images/c-eye.png");
    },
    changebor(border) {
      if (border == "userclass") {
        this.userclass = "inputclass";
      } else if (border == "nameclass") {
        this.nameclass = "inputclass";
      } else if (border == "phoneclass") {
        this.phoneclass = "inputclass";
      } else if (border == "passclass") {
        this.borclass = "inputclass";
      } else if (border == "pass2class") {
        this.pass2class = "inputclass";
      } else if (border == "verifyclass") {
        this.verifyclass = "inputclass";
      }
    },
    goprotoAgree(type) {
      const { href } = this.$router.resolve({
        path: `/webUseterm`,
        query: {
          type: type,
        },
      });
      window.open(href, "_blank");
    },
    gotologin() {
      this.$router.push("/login");
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // },
  },
};
</script>

<style>
.input:focus {
  outline: none;
  border: 1px solid red;
}
.grade {
  width: 100%;
  height: 24px;
  /* border: 1px solid red; */
  margin-top: 20px;
}
.grade div {
  width: 45px;
  height: 24px;
  float: left;
  margin-right: 10px;
}
.grade div p.default {
  width: 45px;
  height: 4px;
  background-color: #ced2d6;
}
.grade div span {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #303133;
  line-height: 20px;
}

.red {
  background: #fc423f !important;
}
.yellow {
  background: #fdd462 !important;
}
.blue {
  background: #3fbb23 !important;
}
.identifybox {
  position: absolute;
  left: 190px;
  top: 5;
}
.iconstyle {
  color: #409eff;
}
.sel_box {
  width: 100%;
  height: 44px;
  margin-top: 30px;
}
.verify {
  width: 100%;
  height: 44px;
  position: relative;
  margin-top: 20px;
}

.verify_input {
  width: 198px;
  height: 44px;
  position: absolute;
  border-radius: 4px;
  border: 1px solid #d6dae2;
  top: 0;
  left: 0;
}

.identifyinput {
  width: 198px;
  height: 44px;
}
.verify .el-form-item__content {
  width: 190px;
  height: 44px;
}

.gender_text {
  width: 60px;
  height: 44px;
  line-height: 44px;
  background: #d6dae2;
  border: 1px solid #d6dae2;
  box-sizing: border-box;
  border-radius: 4px 0px 0px 4px;
  font-size: 14px;
  float: left;
}
.right_con .code {
  font-size: 14px;
  color: #7856fd;
  line-height: 44px;
  width: 145px;
  height: 44px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e7eaee;
  float: right;
}
.captcha_code {
  width: 120px;
  height: 44px;
  float: right;
}
.gender_sel {
  width: 100px;
  height: 44px;
  line-height: 44px;
  float: right;
  /* border: 1px solid red; */
}

.gender {
  width: 169px;
  height: 44px;
  border: 1px solid #d6dae2;
  border-radius: 4px;
  float: left;
}

.el-form-item__content {
  margin-left: 0 !important;
}
.el-input {
  height: 40px;
}

/* .el-input__suffix {
  display: none;
} */
.el-form-item {
  margin-bottom: 10px !important;
}
#dia_liaison .el-form-item {
  margin-bottom: 26px !important;
}
/* .login_box .el-form-item__error {
  padding-top: 10px !important;
} */

#dia_liaison .el-form-item__error {
  left: 90px !important;
}

/* .login_box {
  width: 100%;
  height: 938px;
  position: relative;
} */
.register_left {
  height: 684px !important;
  background: #222338;
}

.login_title {
  height: 30px;
}

.log_tit_left {
  /* width: 200px; */
  height: 26px;
  font-size: 26px;
  font-weight: bold;
  color: #303133;
  line-height: 30px;
  text-align: center;
}
.log_tit_right {
  width: 135px;
  height: 26px;
  float: right;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}
.log_tit_right span {
  color: #0b3ea6;
}
.user_input {
  width: 360px;
  height: 44px;
  border: 1px solid #d6dae2;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
}
.user_input input {
  position: absolute;
  top: 2px;
  left: 40px;
  width: 280px;
  height: 40px;
  border: none;
  outline: none;
}

.address_input input {
  position: absolute;
  top: 2px;
  left: 15px;
  width: 240px;
  height: 40px;
  border: none;
  outline: none;
  /* background: #fafafa; */
}
.per_pass_input {
  background: #fafafa;
  float: left;
  height: 40px;
}
.per_pass_input input {
  width: 130px;
  left: 10px;
  height: 38px;
  background: #fafafa;
}
.orpass_input input {
  background: #fff;
}
.remember_left {
  width: 100px;
  height: 30px;
  float: left;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}

.remember_right {
  width: 89px;
  height: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #0b3ea6;
  line-height: 30px;
  float: right;
}
.login_but {
  width: 100%;
  height: 42px;
  margin-top: 12px;
}
.login_but .el-button--primary {
  width: 100%;
  height: 42px;
  background: #3d7eff;
  border-radius: 4px;
}
.eyeicon {
  position: absolute;
  top: 14px;
  right: 15px;
}

.login_bottom ul li {
  float: left;
  color: #62636b;
  font-size: 12px;
  /* position: relative; */
}
.icon {
  width: 16px;
  height: 16px;
  position: absolute;
}
.login_bottom ul li span {
  padding: 0 0 0 20px;
  /* position: absolute;
  left: 20px; */
}
.borclass {
  height: 46px !important;
  background: #ffd8d7 !important;
  border: 1px solid #e15a55 !important;
  border-left: 2px solid #e15a55;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1000px #ffd8d7 !important;
}
.borclass .el-input__inner {
  box-shadow: inset 0 0 0 1000px #ffd8d7 !important;
}

.distpicker-address-wrapper select {
  padding: 10px !important;
  width: 120px !important;
  float: left;
  font-size: 14px !important;
  height: 40px !important;
  background: #fafafa !important;
  border: none !important;
  border-radius: 2px !important;
  appearance: -5px;
}
.distpicker-address-wrapper label:nth-child(2) {
  float: right;
}
.distpicker-address-wrapper label:last-child {
  display: none;
}
.el-upload--picture-card {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
  background: #3d7eff !important;
  border-radius: 50% !important;
  text-align: center !important;
}
.el-upload--picture-card i {
  font-size: 14px !important;
  color: #fff !important;
  display: inline !important;
}
.group_con .el-collapse-item__header {
  font-size: 16px !important;
  font-weight: bold;
  border-bottom: none !important;
}
.el-collapse-item__wrap {
  border-bottom: none !important;
}
.el-collapse-item__content {
  padding-bottom: 5px !important;
}

.group_con .el-collapse-item__content {
  padding-bottom: 18px !important;
}
.select .el-input--suffix .el-input__inner {
  /* width: 80px !important;
  padding: 0 !important; */
}
/* 制作图片 */
.make_con .el-input__inner {
  padding: 0 !important;
}
.el-form-item .el-input__validateIcon {
  display: none !important;
}
.el-calendar-table .el-calendar-day {
  height: 26px !important;
}
.el-calendar .el-button-group {
  width: 276px;
}
.el-calendar__title {
  padding-top: 20px;
}
.el-calendar__header {
  display: block !important;
  justify-content: space-between;
  padding: 0 !important;
  border-bottom: 1px solid #ebeef5;
  width: 276px;
}

.el-calendar__button-group .el-button-group > .el-button:first-child {
  margin-left: 30px;
}
.el-calendar-table td.is-selected {
  background: #3d7eff !important;
  width: 23px !important;
  height: 23px !important;
  border-radius: 7px !important;
}
/* .el-backtop,
.el-calendar-table td.is-today {
  color: #fff !important;
  background-color: #3d7eff;
  width: 23px !important;
  height: 23px !important;
  border-radius: 7px !important;
} */
.is-selected span {
  color: #fff;
}
.login_box .el-input--mini .el-input__inner {
  height: 38px !important;
  line-height: 38px !important;
}
.depar_table .el-table__row {
  width: 837px !important;
}

.depa_table .el-table td,
.depa_table .el-table th {
  width: 167px;
}
input:-internal-autofill-selected {
  box-shadow: inset 0 0 0 1000px #fff !important;
}

.input:focus {
  outline: none;
  border: 1px solid red;
}
.gender_sel .el-input--suffix .el-input__inner {
  padding-right: 0px !important;
}
.el-table th > .cell {
  font-weight: lighter;
}

/* .right_con .el-form-item {
  margin-bottom: 0px !important;
} */
.register_left_bg {
  width: 322px;
  height: 640px;
  background: url("../../assets/images/register_bg.png") 100% 100% no-repeat;
  margin-left: 18px;
  margin-top: 20px;
}
/* .register_bg img {
  width: 100%;
  height: 100%;
} */
/*::v-deep .applySelect .el-icon-arrow-up:before { content: '' }*/
.applySelect .el-input__inner {
  padding: 0;
}
.el-input--small .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
}

.el-form-item__content {
  margin-left: 0 !important;
}

.el-input {
  height: 40px;
}

.login_box {
  width: 1920px;
  height: 100%;
  position: fixed;
}

.register_bg {
  width: 100%;
  height: calc(100% - 40px);
  position: absolute;
  z-index: 1;
  background: url("../../assets/images/bg-222.png");
}

.login_con {
  width: 486px;
  height: 718px;
  background: #fff;
  position: absolute;
  z-index: 2;
  /* left: 458px; */
  top: 120px;
  border-radius: 8px;
}

.login_con_left {
  width: 359px;
  height: 580px;
  background: #222338;
  border-radius: 8px 0px 0px 8px;
  float: left;
}

.enter_name {
  height: 24px;
  font-size: 24px;
  font-family: STJTBZ;
  font-weight: 400;
  color: #d5b493;
  text-align: left;
  margin-left: 13px;
}

.enter_line {
  width: 28px;
  height: 4px;
  background: #d1a982;
  opacity: 0.8;
  margin-top: 23px;
  margin-left: 13px;
}

.enter_text {
  width: 284px;
  color: #a5a5af;
  font-size: 12px;
  line-height: 23px;
  text-align: left;
  margin: 0 auto;
  margin-top: 15px;
}

.login_con_right {
  width: 486px;
  height: 718px;
  float: left;
  background: #fff;
}

.right_con {
  width: 360px;
  height: 500px;
  /* border: 1px solid red; */
  margin: 0 auto;
  margin-top: 40px;
}

.login_box .login_title {
}

.log_tit_right {
  width: 135px;
  height: 26px;
  float: right;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}

.log_tit_right span {
  color: #0b3ea6;
}

.user_input {
  width: 360px;
  height: 44px;
  border: 1px solid #d6dae2;
  border-radius: 4px;
  position: relative;
}

.user_input img.passicon {
  position: absolute;
  top: 12px;
  left: 11px;
}

.user_input input {
  position: absolute;
  top: 2px;
  left: 40px;
  width: 280px;
  height: 40px;
  border: none;
  outline: none;
}

.login_remember {
  width: 100%;
  height: 30px;
  margin-top: 15px;
}

.remember_left {
  width: 100px;
  height: 30px;
  float: left;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  text-align: left;
}

.remember_right {
  width: 89px;
  height: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #0b3ea6;
  line-height: 30px;
  float: right;
}

.login_but .el-button--primary {
  width: 100%;
  height: 42px;
  background: #3d7eff;
  margin-top: 0px;
  border-radius: 4px;
}

.forgetpass {
  width: 100%;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: 14px;
  margin-top: 20px;
}

.forgetpass span {
  color: #3d7eff;
  margin-left: 5px;
}

.eyeicon {
  position: absolute;
  top: 13px;
  right: 15px;
}

.login_bottom {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 4%;
  line-height: 38px;
  bottom: 0px;
}

.login_bottom ul li {
  float: left;
  color: #62636b;
  font-size: 12px;
  /* position: relative; */
}

.icon {
  width: 16px;
  height: 16px;
  position: absolute;
}

.login_bottom ul li span {
  padding: 0 0 0 20px;
  /* position: absolute;
  left: 20px; */
}

.inputclass {
  border: 1px solid #3d7eff !important;
}

.el-checkbox__label {
  color: #333333 !important;
}

.login_left_bg {
  width: 322px;
  height: 566px;
  background: url("../../assets/images/login_bg.png") 100% 100% no-repeat;
  margin-left: 18px;
  margin-top: 20px;
}

.login_bottom_text {
  font-size: 12px;
  width: 460px;
  height: 38px;
  margin: 0 auto;
  color: #62636b;
}

.login_bottom_text span {
  margin-left: 6px;
  display: block;
  float: left;
  color: #62636b;
}

.login_bottom_text span a {
  color: #62636b;
}

.icphref:hover {
  color: #62636b;
  text-decoration: underline !important;
}
.inputclass {
  border: 1px solid #7856fd !important;
}
.protoAgree {
  color: #303133;
  font-size: 12px;
  margin-top: 6px;
}
.protoAgree span {
  color: #7856fd;
}
.input_label {
  font-size: 14px;
  color: #303133;
  margin-top: 16px;
}
.code_input {
  width: 200px;
  height: 44px;
  border: 1px solid #d6dae2;
  border-radius: 4px;
  position: relative;
}
.register_but {
  font-size: 14px;
  color: #303133;
  line-height: 30px;
}

.register_but span {
  color: #7856fd;
}
.no-autofill-pwd .el-input__inner {
  -webkit-text-security: disc !important;
}
</style>

