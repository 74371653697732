<template>
  <div class="hello" style="width: 100%; background: #fff">
    <div class="useterm_box">
      <ul class="useterm_head">
        <li
          :class="usetermtype == 1 ? 'active pointer' : 'pointer'"
          @click="getuserAgtype(1)"
        >
          项目须知
        </li>
        <li
          :class="usetermtype == 2 ? 'active pointer' : 'pointer'"
          @click="getuserAgtype(2)"
        >
          构件零件编号规范
        </li>
      </ul>

      <div
        class="useterm_con"
        style="padding: 20px 0px"
        v-if="usetermtype == 1"
      >
        <proNotice></proNotice>
      </div>

      <div
        class="useterm_con"
        style="padding: 20px 0px"
        v-if="usetermtype == 2"
      >
        <partSpe></partSpe>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/http";
import proNotice from "./component/projectNotice.vue";
import partSpe from "./component/partSpecifications.vue";
export default {
  components: {
    proNotice,
    partSpe,
  },
  name: "Home",
  props: {
    msg: String,
  },
  data() {
    return {
      usetermtype: 1,
    };
  },
  created() {
    if (this.$route.query.noticeType) {
      this.usetermtype = this.$route.query.noticeType;
    } else {
      this.usetermtype = 1;
    }
  },
  methods: {
    getuserAgtype(type) {
      this.usetermtype = type;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "@/assets/css/webUseterm.css";
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
