import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import store from "./store/index.js";
// import "./router/permission"
import ElementUI from 'element-ui'
import 'default-passive-events'
import 'element-ui/lib/theme-chalk/index.css'
import dateUtils from "./assets/js/dateUtils";
// cnpm install v-viewer@1.6.4
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
// import qs from 'qs'
// import 'es6-promise/auto'
// import 'es6-object-assign/auto'
// import 'url-search-params-polyfill';
// import 'babel-polyfill';
// Vue.forceUpdate();
Vue.use(ElementUI)
Vue.use(Viewer)

Vue.config.productionTip = false

axios.defaults.baseURL = "https://zzyjzkj.com:7004/";
Vue.prototype.$http = axios;
Vue.prototype.$date = dateUtils;

// 请求拦截器
axios.interceptors.request.use((config) => {
  config.headers.Authorization = window.sessionStorage.getItem("token");
  return config;
});

new Vue({
  router,
  store,
  render: h => h(App),
  // mounted () {
  //   (function (z, h, i, m, a, j, s) {
  //     z[m] = z[m] || function () {
  //       (z[m].a = z[m].a || []).push(arguments)
  //     };
  //     j = h.createElement(i),
  //       s = h.getElementsByTagName(i)[0];
  //     j.async = true;
  //     j.charset = 'UTF-8';
  //     j.setAttribute("data-json", JSON.stringify({
  //       id: 6109,
  //       token: '3c915a5924b258656eac00eefcf94a9e',
  //       customer: 0, // 控制是否显示客服图标，0为显示，1为不显示
  //       language: 'ZH' // 控制显示语言，ZH为中文，EN为英文
  //     }));
  //     j.id = "xiaokefu_js";
  //     j.src = 'https://pcjs.xiaokefu.com.cn/static/pc_Access/dist/pcAccess.js?version=' + parseInt(new Date().getTime() / 3600000);
  //     s.parentNode.insertBefore(j, s);
  //   })(window, document, 'script', '_xiaokefu');
  // }
}).$mount('#app')
