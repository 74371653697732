<template>
  <div class="isAuth">
    <el-dialog
      custom-class="isAuthenticDialog"
      :visible.sync="dialogIsauthentic"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="isAuth_icon"></div>
      <div class="isAuth_close" @click="handleClose()"></div>
      <div class="isAuth_bg" style=""></div>

      <div class="isAuth_box">
        <p class="isAuth_tit">请尽快完成认证并绑定银行卡</p>
        <p class="isAuth_txt">
          您尚未补全身份信息或已过期，根据国家相关法律法规要求，需要尽快完成有效身份信息认证，避免影响您的后续使用。
        </p>
        <div class="authBut pointer" @click="promptlyAuth()">立即认证</div>
        <p class="nexttime pointer" @click="handleClose()">下次再说</p>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogAuthvocation"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="isAuth_icon"></div>
      <div class="isAuth_close" @click="handleClose()"></div>
      <div class="isAuth_bg" style=""></div>

      <div class="isAuth_box">
        <p class="isAuth_tit">请完成职业能力认证</p>
        <p class="isAuth_txt">
          您尚未完成职业能力认证或认证已过期，需要您完成钢结构深化职业能力认证，避免影响您的后续使用。
        </p>
        <div class="authBut pointer" @click="engineerAuth()">立即认证</div>
        <p class="nexttime pointer" @click="handleClose()">下次再说</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Global from "@/assets/js/global";
export default {
  name: "Home",
  props: {
    isAuthenticShow: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      dialogIsauthentic: false,
      dialogAuthvocation: false,
    };
  },

  mounted() {},
  computed: {},
  created() {},

  methods: {
    handleClose() {
      this.dialogIsauthentic = false;
      this.dialogAuthvocation = false;
      // window.sessionStorage.removeItem("showCredentialsFlag");
    },

    isauthMethod() {
      this.dialogIsauthentic = true;
    },

    isAuthvocation() {
      this.dialogAuthvocation = true;
    },

    promptlyAuth() {
      this.$router.push({
        path: "/leftNavigation/authenticat",
        query: { authenType: 2 },
      });
    },

    engineerAuth() {
      this.$router.push({
        path: "/engineerAuth",
      });
    },
  },
};
</script>

<style scoped>
.isAuth .el-dialog {
  background: transparent;
}
.isAuth_bg {
  position: absolute;
  z-index: 1;
  width: 400px;
  height: 394px;
  background: #ffffff;
  border-radius: 8px;
  top: 50px;
  background: url("../../../assets/images/outsidepro/auth_bg.png");
}

.announce_bg {
  position: absolute;
  z-index: 1;
  width: 595px;
  /* height: 358px; */
  background: #ffffff;
  border-radius: 8px;
  top: 50px;
  background: url("../../../assets/images/outsidepro/announce_bg.png");
}

.isAuth_icon {
  width: 234px;
  height: 168px;
  position: absolute;
  top: 20px;
  z-index: 2;
  background: url("../../../assets/images/outsidepro/auth_icon.png");
  left: 82px;
}

.isAuth_close {
  width: 20px;
  height: 50px;
  position: absolute;
  top: 0px;
  right: 22px;
  z-index: 2;
  background: url("../../../assets/images/outsidepro/auth_close.png");
}
.anno_close {
  top: 10px;
  right: -24px;
}
.isAuth_box {
  position: absolute;
  z-index: 3;
  width: 400px;
  border-radius: 8px;
  top: 160px;
}
.announce_box {
  position: absolute;
  z-index: 3;
  width: 595px;
  border-radius: 8px;
  left: 40px;
  top: 60px;
  background: url("../../../assets/images/outsidepro/announce_bg.png") no-repeat;
  background-size: 100% 100%;
  padding-bottom: 32px;
}
.isAuth_tit {
  font-size: 26px;
  color: #000000;
  line-height: 30px;
  text-align: center;
}
.isAuth_txt {
  width: 333px;
  font-size: 16px;
  color: #636977;
  line-height: 28px;
  margin: 0 auto;
  margin-top: 20px;
}
.authBut {
  width: 240px;
  height: 42px;
  line-height: 42px;
  background: #7856fd;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
  margin-top: 40px;
}
.nexttime {
  font-size: 14px;
  color: #7856fd;
  line-height: 30px;
  text-align: center;
  margin-top: 4px;
}
.announce_tit {
  margin-top: 52px;
}
.announce_txt {
  width: 516px;
  margin: 0 auto;
  font-size: 16px;
  color: #636977;
  line-height: 28px;
  margin-top: 10px;
  min-height: 120px;
}
</style>
