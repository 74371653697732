<template>
  <div class="viewnewpro">
    <div class="newpro_cen">
      <div class="block">
        <el-carousel trigger="click" height="200px" :interval="6000">
          <!--          <el-carousel-item>-->
          <!--            <p class="banner">-->
          <!--              <img-->
          <!--                src="../../../assets/images/outsidepro/banner_4.png"-->
          <!--                alt=""-->
          <!--              />-->
          <!--            </p>-->
          <!--          </el-carousel-item>-->
          <el-carousel-item>
            <p class="banner" @click="goWintech()">
              <img
                src="../../../assets/images/outsidepro/banner_3.png"
                alt=""
              />
            </p>
          </el-carousel-item>

          <el-carousel-item>
            <p class="banner" @click="goatlasAnima()">
              <img
                src="../../../assets/images/outsidepro/banner_2.png"
                alt=""
              />
            </p>
          </el-carousel-item>
          <el-carousel-item>
            <p class="banner" @click="viewcode()">
              <img
                src="../../../assets/images/outsidepro/banner_1.png"
                alt=""
              />
            </p>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="head_sel" style="margin-top: 0px">
        <div class="sel_left">
          <el-cascader
            v-model="realmIds"
            :options="realmlist"
            :show-all-levels="false"
            placeholder="结构类型"
            style=""
            ref="cascaderHandle"
            filterable
            clearable
            @change="changeRealm"
          >
          </el-cascader>
          <el-select
            v-model="selectExDateIndex"
            placeholder="工期"
            @change="searchExDate"
            clearable
          >
            <el-option
              v-for="item in exdateOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
              v-model="form.sortState"
              placeholder="收益"
              @change="search()"
              clearable
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="sel_right">
<!--          <el-checkbox v-model="form.stateFlag" @change="search()" >待抢单</el-checkbox>-->
          <el-select
              v-model="form.stateFlag"
              placeholder="状态"
              @change="search()"
              clearable
          >
            <el-option
                v-for="item in stateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        v-if="newProlistlen > 0"
        v-loading="loading"
        style="min-height: 340px; margin-top: 2px"
      >
        <div class="prolist_con">
          <div class="prolist" v-for="(item, index) in newProlist" :key="index">
            <div class="prolist_info">
              <div class="infor_con">
                <div class="fixed_4">
                  <p class="infor_con_name">
                    <img
                      src="../../../assets/images/outsidepro/pro_icon.png"
                      alt=""
                      v-if="item.projectTaskPojo.stepState == 1"
                    />
                    <img
                      src="../../../assets/images/outsidepro/pro_icon2.png"
                      alt=""
                      v-else
                    />
                    {{ item.projectTaskPojo.projectCode }}
                  </p>
                  <div class="infor_type" >
                    <p>仅建模</p>
                  </div>
                  <div
                    class="infor_type"
                    v-if="item.estimateTime != null && item.estimateTime != ''"
                    style="width: 130px;margin-left: -40px;"
                  >
                    <p>预计工作时长：{{ item.estimateTime }}h</p>
                  </div>
                  <div class="infor_type"
                       style="width: 130px;margin-left: -40px;"
                       v-else>
                    <p>预计工作时长：暂无</p>
                  </div>
                  <div class="infor_type"
                       style="margin-left: -50px;"
                  >
                    <p>
                      结构类型：{{ item.parentRealmStr + "-" + item.realmStr }}
                    </p>
                  </div>

                  <p class="release_time">
                    <span
                      v-text="
                        $date.Djs_timeList2(item.notarizeDate, serverTime)
                      "
                    ></span
                    >发布
                  </p>
                </div>

                <div class="fixed_3">
                  <div class="dateStr">
                    <p
                      style="color: #000000; font-weight: bold; font-size: 14px"
                    >
                      {{ item.exPectDateStr }}
                    </p>
                    <p class="exPectdate_tip">最迟交付时间</p>
                  </div>

                  <div
                    class="downfile_box"
                    style="margin-top: 20px"
                    v-if="item.requireList.length > 0"
                  >
                    <div class="downfile">
                      <!-- <p class="downfile_bg">下载项目要求附件</p> -->
                      <el-popover
                        popper-class="tab_popover"
                        placement="right"
                        width="302"
                        trigger="click"
                      >
                        <div style="padding: 20px 20px" class="require_popper">
                          <p>项目要求</p>
                          <!-- <ul
                            v-for="(item, index) in item.requireList"
                            :key="index"
                          > -->
                          <ul>
                            <li
                              v-for="(item, index) in item.requireList"
                              :key="index"
                            >
                              {{ index + 1 }}、{{ item }}
                            </li>
                          </ul>
                        </div>

                        <p class="downfile_txt pointer" slot="reference">
                          <img
                            src="../../../assets/images/outsidepro/required.png"
                            alt=""
                          />
                          项目要求
                        </p>
                      </el-popover>
                    </div>
                  </div>
                  <div
                    v-else
                    class="norequired"
                    style="text-align: left; margin-top: 23px !important"
                  >
                    <img
                      src="../../../assets/images/outsidepro/norequired.png"
                      alt=""
                    />无项目要求
                  </div>
                </div>

                <div class="fixed_3">
                  <div class="downfile_box">
                    <div class="downfile" style="margin-top: 90px">
                      <!-- <p class="downfile_bg">下载项目要求附件</p> -->
                      <el-popover
                        popper-class="tab_popover"
                        placement="right"
                        width="600"
                        trigger="click"
                      >
                        <el-table :data="item.fileList" border>
                          <el-table-column
                            property="fileName"
                            label="名称"
                            width="200"
                          ></el-table-column>
                          <el-table-column
                            property="fileSizeStr"
                            label="大小"
                            width="100"
                          ></el-table-column>
                          <el-table-column
                            property="crtDateStr"
                            label="时间"
                            width="200"
                          ></el-table-column>
                          <el-table-column label="操作" width="">
                            <template slot-scope="scope">
                              <span
                                class="pointer down"
                                style="margin-left: 10px"
                                @click="download(scope.row)"
                                >下载</span
                              >
                            </template>
                          </el-table-column>
                        </el-table>
                        <p class="downfile_txt pointer" slot="reference">
                          <img
                            src="../../../assets/images/outsidepro/file.png"
                            alt=""
                          />
                          项目附件
                        </p>
                      </el-popover>
                    </div>
                  </div>
                </div>
              </div>
              <div class="connect">
                <p class="estprice">￥{{ item.outsidePrice }}</p>
                <p class="esttit">预估收益</p>
                <span v-if="item.state == 1">
                  <p v-if="skillCertState == 0" class="connectbut conbutabled">
                    抢需求
                  </p>
                  <p v-else class="connectbut" @click="isconnectOrder(item)">
                    抢需求
                  </p>
                </span>
                <span v-else>
                  <p class="connectbut conbutabled">已签约</p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style="">
          <el-pagination
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.page"
            :page-sizes="[3, 10, 20, 50, 100]"
            :page-size="form.limit"
            layout="total, prev, pager, next, jumper"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </div>

      <div v-else class="nodata" v-loading="loading">
        <p style="text-align: center; padding-top: 240px">
          <img src="../../../assets/images/nodata/newpro_nodata.png" alt="" />
        </p>
        <p style="text-align: center; font-size: 14px">暂无项目</p>
      </div>
    </div>
    <div style="clear: both"></div>

    <el-dialog
      :title="problemtit"
      custom-class="addquestionlog"
      :visible.sync="orderStatusDialog"
      :close-on-click-modal="false"
      width="375px"
      :before-close="handleClose"
    >
      <div style="height: 280px">
        <div v-if="orderstate == 1">
          <p class="order_success_tit">
            <img
              src="../../../assets/images/outsidepro/check.png"
              alt=""
            />抢单成功
          </p>
          <div class="order_success">
            <p>接单项目：{{ takeorderData.projectTaskPojo.projectCode }}</p>
            <!--            <p>-->
            <!--              工程量：{{ takeorderData.projectTaskPojo.projectTaskQuantity }}t-->
            <!--            </p>-->
            <p>最迟交付时间：{{ takeorderData.exPectDateStr }}</p>
            <p>预估收益：￥{{ takeorderData.outsidePrice }}</p>
          </div>
          <div class="order_opera">
            <p class="back_but pointer" @click="search()">返回</p>
            <p class="gomyPro_but pointer" @click="gomyPropage()">
              进入我的项目
            </p>
          </div>
        </div>

        <div v-else-if="orderstate == 2">
          <p class="error_icon">
            <img
              src="../../../assets/images/outsidepro/ordererror_1.png"
              alt=""
            />
          </p>
          <p class="error_txt">您选择的项目刚刚被接走</p>
          <p class="error_txt_tip">去接其他项目</p>
          <p class="error_back_but pointer" @click="search()">返回</p>
        </div>

        <div v-else-if="orderstate == 3">
          <p class="error_icon">
            <img
              src="../../../assets/images/outsidepro/ordererror_2.png"
              alt=""
            />
          </p>
          <p class="error_txt">抢单出现未知错误,请</p>
          <p class="error_txt_tip">联系客户经理</p>
          <p class="error_back_but pointer" @click="search()">返回</p>
        </div>

        <div v-else-if="orderstate == 4">
          <p class="error_icon">
            <img
              src="../../../assets/images/outsidepro/ordererror_2.png"
              alt=""
            />
          </p>
          <p class="error_txt">您的接单已达到上线，</p>
          <p class="error_txt">请完成项目等待审核通过后重试</p>
          <p class="error_back_but pointer" @click="search()">返回</p>
        </div>
      </div>

      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="addQuesDialog = false">取 消</el-button>
        <el-button class="confirm_but" type="primary" @click="comproblem()"
          >完成</el-button
        >
      </span> -->
    </el-dialog>
    <div class="ordertip_box">
      <el-dialog
        custom-class="orderdialog"
        :visible.sync="takeorderDialog"
        width="600px"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div style="padding-bottom: 20px">
          <p class="ordertip_tit">
            <img
              src="../../../assets/images/outsidepro/pro_icon.png"
              alt=""
            />{{ takeorderData.projectTaskPojo.projectCode }}
          </p>

          <div class="orderinfor_box">
            <!-- <div class="orderinfor orderinfor_1">
              <p class="infor_icon">
                <img
                  src="../../../assets/images/outsidepro/dialog_icon1.png"
                  alt=""
                />
              </p>
              <div class="infor_con">
                <p class="infor_num">
                  {{ takeorderData.projectTaskPojo.projectTaskQuantity }}t
                </p>
                <p class="infor_txt">工程量</p>
              </div>
            </div> -->

            <div class="orderinfor orderinfor_2">
              <p class="infor_icon">
                <img
                  src="../../../assets/images/outsidepro/dialog_icon2.png"
                  alt=""
                />
              </p>
              <div class="infor_con">
                <p class="infor_num">￥{{ takeorderData.outsidePrice }}</p>
                <p class="infor_txt">预估收益</p>
              </div>
            </div>

            <div class="orderinfor orderinfor_3">
              <p class="infor_icon">
                <img
                  src="../../../assets/images/outsidepro/dialog_icon3.png"
                  alt=""
                />
              </p>
              <div class="infor_con">
                <p class="infor_num">{{ takeorderData.exPectDateStr }}</p>
                <p class="infor_txt">最迟交付时间</p>
              </div>
            </div>
          </div>

          <div
            class="orderrequired"
            v-if="takeorderData.requireList.length > 0"
          >
            <p class="title">项目要求</p>
            <ul>
              <li
                v-for="(item, index) in takeorderData.requireList"
                :key="index"
              >
                <p><span>·</span>{{ item }}</p>
              </li>
            </ul>
          </div>

          <!-- <div class="ordertip_txt">
            <p>接单项目：{{ takeorderData.projectAlias }}</p>
            <p>最迟交付：{{ takeorderData.exPectDateStr }}</p>
            <p>预估收益：￥{{ takeorderData.outsidePrice }}</p>
          </div> -->
          <div style="width: 100%; height: 36px; margin-top: 20px">
            <p class="protoAgree pointer" style="text-align: center">
              <el-checkbox v-model="checked"></el-checkbox>
              请阅读并同意<span @click="goorderNotice('1')">《项目须知》</span
              >和<span @click="goorderNotice('2')">《构件零件编号规范》</span>
            </p>
          </div>
          <div style="text-align: center">
            <el-button @click="handleClose()">取消</el-button>
            <el-button
              :disabled="condisabled"
              type="primary"
              @click="confirmorder()"
              >确认</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>

    <el-dialog
      custom-class="codedialog"
      :visible.sync="codeDialog"
      width="375px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="code_infor">
        <p class="code_tit">蓝途管控云客户端小程序</p>
        <p class="code_tit2">扫描下方二维码，下载蓝途管控云平台</p>
        <p class="dialogcode_img">
          <img src="../../../assets/images/home/vscode.png" alt="" />
        </p>
      </div>
    </el-dialog>

    <isAuth
      ref="auth"
      v-if="isAuthenticShow"
      :isAuthenticShow="isAuthenticShow"
    ></isAuth>
  </div>
</template>

<script>
import Global from "@/assets/js/global";
import isAuth from "./isAuthentic.vue";
import DateUtils from "@/assets/js/dateUtils";
import streamSaver from "streamsaver";

export default {
  components: {
    isAuth,
  },
  data() {
    return {
      pickerOptions: {
        //设置选择今天以及今天之后的日期
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      realmIds: [],
      realmlist: [],
      selectExDateIndex: "",
      exdateOption: [
        {
          value: "0",
          label: "3天以内",
          type: 0,
        },
        {
          value: "1",
          label: "3天以上",
          type: 1,
        },
        {
          value: "2",
          label: "7天以上",
          type: 1,
        },
        {
          value: "3",
          label: "10天以上",
          type: 1,
        },
      ],
      stateOptions: [
        {
          value: "1",
          label: "待抢单",
        },
      ],
      form: {
        page: 1,
        limit: 3,
        searchText: "",
        realmId: "",
        day: "",
        dayType: "0",
        sortType: "0",
        sortState: "",
        stateFlag:""

      },
      checked: false,
      downloadUrl: Global.downloadUrl,
      imageUrl: Global.baseURL,
      inCount: 0,
      residueCount: 0,
      newProlist: [],
      newProlistlen: 0,
      haveProlist: [],

      totalCount: 0,
      loading: true,
      options: [
        {
          value: "1",
          label: "收益最高",
        },
        {
          value: "0",
          label: "收益最低",
        },
      ],

      problemtit: "",
      orderStatusDialog: false,
      codeDialog: false,
      isAuthenticShow: false,
      orderstate: 1,
      isCredentials: window.sessionStorage.getItem("isCredentials"),
      takeorderDialog: false,
      condisabled: false,
      takeorderData: {
        projectTaskPojo: {
          projectCode: "",
          projectTaskQuantity: 0,
        },
        requireList: [],
        exPectDateStr: "",
        outsidePrice: 0,
      },
      chunks: [],
      // 分片下载大小 5MB
      chunkSize: 1024 * 1024 * 5,
      // 文件总大小(需要请求后端获得)
      fileSize: 0,
      chunksNum: 0,
      skillCertState: 0,
    };
  },

  created() {
    this.skillCertState = window.sessionStorage.getItem("skillCertState");
    // console.log(this.skillCertState);

    // var showCredentialsFlag = window.sessionStorage.getItem(
    //   "showCredentialsFlag"
    // );
    // if (this.isCredentials !== "1" && showCredentialsFlag) {
    //   this.isAuthenticShow = true;
    //   this.$nextTick(() => {
    //     this.$refs.auth.isauthMethod();
    //   });
    // }
    this.serverTime = new Date().getTime();
    // this.getMyCount();
    this.getNewprolist();
    // this.gethaveProlist();
    this.getRealmCombox();
  },
  methods: {
    newProMethod() {
      // this.getMyCount();
      this.getNewprolist();
      // this.gethaveProlist();
      this.getRealmCombox();
    },

    changeRealm(e) {
      if (this.$refs.cascaderHandle.checkedValue.length == 0) {
        this.form.realmId = "";
      } else {
        this.form.realmId = e[1];
      }
      this.search();
    },
    download(row) {
      // 定义 存储所有的分片的数组
      this.chunks = [];
      // 分片下载大小 5MB
      this.chunkSize = 1024 * 1024 * 5;
      // 文件总大小(需要请求后端获得)
      this.fileSize = row.fileSize;
      // 计算分片数量
      this.chunksNum = Math.ceil(this.fileSize / this.chunkSize);
      this.downloadChunkFile(0, row);
    },
    async downloadChunkFile(chunkIdx, row) {
      // if (chunkIdx >= this.chunksNum) {
      //   alert('分片索引不可超过分片数量')
      //   return
      // }
      //
      let start = chunkIdx * this.chunkSize;
      let end = Math.min(start + this.chunkSize - 1, this.fileSize - 1);
      const range = `bytes=${start}-${end}`;
      streamSaver.mitm = Global.serveUrl + "mitm.html?version=2.0.0";
      // 使用 Fetch API 请求文件
      fetch(
        Global.baseURL2 +
          "Api/yw/profile/download?rangeHeader=" +
          range +
          "&path=" +
          row.fileUrl +
          "&fileName=" +
          row.fileName
      ).then((res) => {
        this.chunks.push(res.data);
        // 创建写入流 filename为下载的文件名
        const fileStream = streamSaver.createWriteStream(row.fileName, {
          size: res.headers.get("content-length"),
        });
        const readableStream = res.body;
        if (window.WritableStream && readableStream.pipeTo) {
          return readableStream.pipeTo(fileStream).then(() => {});
        }
        window.writer = fileStream.getWriter();
        const reader = res.body.getReader();
        const pump = () =>
          reader
            .read()
            .then((res) =>
              res.done
                ? window.writer.close()
                : window.writer.write(res.value).then(pump)
            );
        pump();
      });

      // this.$http({
      //   url: "Api/yw/profile/download2",
      //   method: 'GET',
      //   params: {
      //     rangeHeader: range,
      //     path: row.fileUrl,
      //     fileName: row.fileName,
      //   },
      //   responseType: 'arraybuffer'
      // }).then(response => {
      //
      //   this.chunks.push(response.data)
      //   if (chunkIdx == this.chunksNum - 1) {
      //     // 下载好了
      //     console.log(this.chunks, 'chunks');
      //     // 组合chunks到单个文件
      //     const blob = new Blob(this.chunks);
      //     console.log(blob, 'blob');
      //     const link = document.createElement('a');
      //     link.href = window.URL.createObjectURL(blob);
      //     link.download = 'demo.mp4';
      //     link.click();
      //     return
      //   } else {
      //     ++chunkIdx
      //     this.downloadChunkFile(chunkIdx,row)
      //   }
      // })
    },
    searchExDate(e) {
      if (e == "0") {
        this.form.day = 3;
        this.form.dayType = 0;
      } else if (e == "1") {
        this.form.day = 3;
        this.form.dayType = 1;
      } else if (e == "2") {
        this.form.day = 7;
        this.form.dayType = 1;
      } else if (e == "3") {
        this.form.day = 10;
        this.form.dayType = 1;
      } else {
        this.form.day = "";
        this.form.dayType = "";
      }
      this.search();
    },
    search() {
      this.orderStatusDialog = false;
      this.form.page = 1;
      this.getNewprolist();
    },
    getNewprolist() {
      if (this.form.sortState != undefined && this.form.sortState != "") {
        this.form.sortType = 1;
      } else {
        this.form.sortType = 0;
      }
      // this.form.stateFlag = this.form.stateFlag?1:0;

      this.$http
        .get("/Api/yw/projectTaskOutside/getOrder", {
          params: this.form,
        })
        .then((res) => {
          this.newProlist = res.data.data;
          this.newProlistlen = res.data.data.length;
          this.loading = false;
          this.totalCount = res.data.count;
          this.Djs_time();
        });
    },
    Djs_time: function () {
      var _this = this;
      this.timer = setInterval(() => {
        _this.serverTime = _this.serverTime + 1000;
      }, 1000);
    },
    gethaveProlist() {
      this.$http
        .get("/Api/yw/projectTaskOutside/getOnLoadingProject")
        .then((res) => {
          this.haveProlist = res.data.data;
        });
    },
    getMyCount() {
      this.$http.get("/Api/yw/projectTaskOutside/getMyCount").then((res) => {
        this.inCount = res.data.data.inCount;
        this.residueCount = res.data.data.residueCount;
      });
    },

    getRealmCombox() {
      this.$http.get("/Api/yw/realm/getRealmCombox2").then((res) => {
        this.realmlist = res.data.data;
      });
    },
    isconnectOrder(data) {
      this.checked = false;
      this.takeorderData = data;
      this.takeorderDialog = true;
    },
    confirmorder() {
      this.condisabled = true;
      if (!this.checked) {
        this.$message.error("请阅读并同意《项目须知》和《构件零件编号规范》");
        this.condisabled = false;
        return false;
      }
      this.takeorderDialog = false;
      this.connectOrder();
    },
    connectOrder() {
      if (this.isCredentials == 0) {
        this.isAuthenticShow = true;
        this.condisabled = false;
        this.$nextTick(() => {
          this.$refs.auth.isauthMethod();
        });
      } else {
        this.$http
          .post(
            "/Api/yw/projectTaskOutside/order?id=" +
              this.takeorderData.projectTaskPojo.projectTaskId
          )
          .then((res) => {
            if (res.data.result == true) {
              this.$message.success(res.data.data);
              this.orderstate = 1;
              this.getNewprolist();
              this.condisabled = false;
            } else {
              if (res.data.code == 201) {
                this.orderstate = 2;
              } else if (res.data.code == 202) {
                this.orderstate = 3;
              } else if (res.data.code == 203) {
                this.orderstate = 4;
              } else {
                this.$message.error(res.data.msg);
                return false;
              }
              this.condisabled = false;
            }
            this.orderStatusDialog = true;
          });
      }
    },
    handleClose() {
      this.orderStatusDialog = false;
      this.takeorderDialog = false;
      this.codeDialog = false;
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.getNewprolist();
    },
    // 点击第几页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getNewprolist();
    },
    gomyPropage() {
      this.orderStatusDialog = false;
      this.$router.push("/leftNavigation/myProblem");
    },
    goorderNotice(type) {
      const { href } = this.$router.resolve({
        path: `/orderNotice`,
        query: { noticeType: type },
      });

      window.open(href, "_blank");
    },
    gopathauthenticat() {
      this.$router.push({
        path: "/leftNavigation/authenticat",
        query: { authenType: 2 },
      });
    },
    goatlasAnima() {
      window.open("https://space.bilibili.com/1176654026", "_blank");
    },
    goWintech() {
      window.open("https://zzyjzkj.com:8080/aboutus.html#introduce", "_blank");
    },
    viewcode() {
      this.codeDialog = true;
    },
  },
};
</script>

<style  scoped>
.viewnewpro {
  width: 1120px;
  margin: 0 auto;
  margin-top: 20px;
  /* padding-bottom: 20px; */
}
.newpro_left {
  width: 352px;
  float: left;
}
.receorder {
  width: 352px;
  background: #ffffff;
  padding-bottom: 20px;
  min-height: 510px;
  border-radius: 8px;
}

.newpro_cen {
  width: 1120px;
  min-height: 100px;
  float: left;
  /* margin-left: 18px; */
  /* border: 1px solid red; */
}
.head_sel {
  height: 60px;
  width: 1120px;
  /* //border-bottom: 3px solid #303133; */
  margin-top: 20px;
}

.head_sel .el-input__inner {
  background: transparent;
}
.ordertip_tit {
  text-align: center;
  font-size: 20px;
  color: #5643fe;
  line-height: 30px;
  margin-top: 10px;
}
.ordertip_tit img {
  margin-right: 10px;
}
.ordertip_txt {
  margin-top: 16px;
  padding-bottom: 20px;
}
.ordertip_txt p {
  font-size: 12px;
  color: #91959f;
  line-height: 24px;
}
</style>