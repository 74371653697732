<template>
  <div class="outsidePro" style="background: #f5f6fa">
    <div id="outsidecon">
      <div>
        <hisPro ref="historychild" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/http";
import hisPro from "./component/historyPro";
export default {
  components: {
    hisPro,
  },
  name: "Home",
  data() {
    return {
      proshow: 1,
      residueCount: 0,
      inCount: 0,
    };
  },
  created() {
    this.gohisproMethod();
  },
  methods: {
    gohisproMethod() {
      this.$nextTick(() => {
        this.$refs.historychild.hisProMethod();
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "@/assets/css/outsidePro.css";
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  list-style-type: none;
  /* margin: 0 10px; */
}
</style>
o