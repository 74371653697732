<template>
  <div class="home">
    <div class="home_head">
      <p class="home_banner">
        <img src="../../assets/images/home/banner.png" alt="" />
      </p>
      <p class="banner_opacity"></p>
      <p class="banner_txt"></p>
      <div class="home_head_con">
        <p class="settledBut pointer" @click="goRegister()">工程师入驻</p>
        <p class="contact">联系我们：0359-2560707</p>
      </div>
    </div>

    <div class="home_second">
      <p class="second_tit">新时代工作方式</p>
      <p class="second_tit2">为技能型人才，打造新时代工作方式</p>
      <div class="home_second_con">
        <div class="second_con_left">
          <p class="seccon_left_tit">专有定制</p>
          <p class="seccon_left_txt">针对钢结构详图深化设计专有订制</p>
          <p class="seccon_left_txt2">
            整体工作流程专注钢结构详图深化设计领域，持续升级优化
          </p>

          <div class="seccon_left_con">
            <div class="custom">
              <p class="custom_icon">
                <img src="../../assets/images/home/work-jd.png" alt="" />
              </p>
              <p class="custom_tit">线上接单</p>
              <p class="custom_txt">省时省力</p>
            </div>
            <div class="custom">
              <p class="custom_icon">
                <img src="../../assets/images/home/work-jf.png" alt="" />
              </p>
              <p class="custom_tit">数字化交付</p>
              <p class="custom_txt">便捷办公</p>
            </div>
            <div class="custom">
              <p class="custom_icon">
                <img src="../../assets/images/home/work-dk.png" alt="" />
              </p>
              <p class="custom_tit" style="margin-top: 9px">审核打款</p>
              <p class="custom_txt">清晰透明</p>
            </div>
          </div>
        </div>
        <div class="second_con_right">
          <div class="seccon_list">
            <p class="seccon_tit">专业沉淀</p>
            <p class="seccon_txt">多年行业沉淀，让工作流程简洁流畅</p>
            <p class="seccon_txt2">
              持续多年专注钢结构详图深化设计领域，了解最真实的工作场景
            </p>
            <p class="seccon_icon">
              <img src="../../assets/images/home/icon_1.png" alt="" />
            </p>
          </div>
          <div class="seccon_list">
            <p class="seccon_tit">专注设计</p>
            <p class="seccon_txt">无需和多方沟通，专注设计模型</p>
            <p class="seccon_txt2">
              问题沟通专人响应，无需投入精力沟通专注设计
            </p>
            <p class="seccon_icon">
              <img src="../../assets/images/home/icon_2.png" alt="" />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="home_third">
      <p class="second_tit">简单4步，加入蓝途云工厂</p>
      <p class="second_tit2">真实项目、专注设计、交付简单、收益清晰透明</p>

      <ul class="home_third_con">
        <li>
          <p class="third_icon">
            <img src="../../assets/images/home/third_icon_1.png" alt="" />
          </p>
          <p class="third_tit">身份认证，技能评定</p>
          <p class="third_txt">真实可靠，贴近真实设计场景。</p>
        </li>
        <li>
          <p class="third_icon">
            <img src="../../assets/images/home/third_icon_2.png" alt="" />
          </p>
          <p class="third_tit">参与设计</p>
          <p class="third_txt">足不出户，专注设计。</p>
        </li>
        <li>
          <p class="third_icon">
            <img src="../../assets/images/home/third_icon_3.png" alt="" />
          </p>
          <p class="third_tit">沟通交付</p>
          <p class="third_txt">文件交付，对接专业人员。</p>
        </li>
        <li>
          <p class="third_icon">
            <img src="../../assets/images/home/third_icon_4.png" alt="" />
          </p>
          <p class="third_tit">工作结算</p>
          <p class="third_txt">结算内容透明清晰，及时高效。</p>
        </li>
      </ul>
      <div class="settle_but pointer" @click="goRegister()">立即入驻</div>
    </div>

    <div class="home_fourth">
      <p class="home_fourth_tit">用户心语</p>

      <div class="home_fourth_con">
        <div class="carousel_box">
          <div class="carousel_bg">
            <p class="carousel_tit">我们正在使用蓝途云工厂</p>
            <p class="carousel_txt">来自使用者的真实评价</p>
          </div>
          <div style="position: absolute; z-index: 2; width: 100%">
            <el-carousel indicator-position="outside" :autoplay="false">
              <el-carousel-item>
                <ul class="carousel_ul">
                  <li class="carousel_li">
                    <p class="carousel_li_txt">
                      蓝途云工厂极大的丰富了工作场景。让
                      拥有技能的我们可以专注设计，整体流程也
                      简洁高效。愿未来不忘初心，为技能人才打 造持续。
                    </p>
                    <div class="userrole">
                      <p class="role_img">
                        <img src="../../assets/images/home/user_1.png" alt="" />
                      </p>
                      <p class="role_txt">资深从业者</p>
                    </div>
                  </li>
                  <li class="carousel_li">
                    <p class="carousel_li_txt">
                      优秀的平台！页面简洁大方，非常美观。整个流程也有专业人员答疑，让建模工作变得高效简单。
                    </p>
                    <div class="userrole">
                      <p class="role_img">
                        <img src="../../assets/images/home/user_2.png" alt="" />
                      </p>
                      <p class="role_txt">资深从业者</p>
                    </div>
                  </li>
                  <li class="carousel_li">
                    <p class="carousel_li_txt">
                      蓝途云工厂的流程很简洁，只需要简单的认证，和一个项目测评就可以让我们参与项目生产。而且完成后的打款也是清晰透明的，是一个非常不错的平台。
                    </p>
                    <div class="userrole">
                      <p class="role_img">
                        <img src="../../assets/images/home/user_3.png" alt="" />
                      </p>
                      <p class="role_txt">资深从业者</p>
                    </div>
                  </li>
                </ul>
              </el-carousel-item>
              <el-carousel-item>
                <ul class="carousel_ul">
                  <li class="carousel_li">
                    <p class="carousel_li_txt">
                      我喜欢这个平台简洁清晰的界面设计，让我可以轻松快速地浏览和筛选订单。我会毫不犹豫地推荐给大家，让更多人能够享受到这样优质的服务。
                    </p>
                    <div class="userrole">
                      <p class="role_img">
                        <img src="../../assets/images/home/user_4.png" alt="" />
                      </p>
                      <p class="role_txt">资深从业者</p>
                    </div>
                  </li>
                  <li class="carousel_li">
                    <p class="carousel_li_txt">
                      这平台简直如获珍宝！接单简单，订单匹配精准，客服贴心。感谢让我更高效地处理钢结构设计任务。
                    </p>
                    <div class="userrole">
                      <p class="role_img">
                        <img src="../../assets/images/home/user_5.png" alt="" />
                      </p>
                      <p class="role_txt">资深从业者</p>
                    </div>
                  </li>
                  <li class="carousel_li">
                    <p class="carousel_li_txt">
                      平台的客户服务支持也非常到位。每当我遇到问题时，客服都能够及时响应并解决，让我感受到他们的用心服务。
                    </p>
                    <div class="userrole">
                      <p class="role_img">
                        <img src="../../assets/images/home/user_6.png" alt="" />
                      </p>
                      <p class="role_txt">资深从业者</p>
                    </div>
                  </li>
                </ul>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>

    <div class="home_bottom">
      <p class="home_bottom_tit">专业、优质、省心</p>
      <p class="home_bottom_tit2">让每一项建设精准高效</p>
      <p class="resident pointer" @click="goRegister()">即刻入驻</p>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/http";
export default {
  name: "Home",
  props: {
    msg: String,
  },
  created() {
    // axios.get("Api/exam/rankAssessment/getList?type=2").then((res) => {
    //   this.tableData = res.data.data;
    //   this.totalCount = res.data.count;
    // });
  },
  methods: {
    goRegister() {
      this.$router.push("register");
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/home.css";
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
}
</style>
