<template>
  <div class="outsidePro" style="background: #f5f6fa">
    <div class="engineerAuth_box" v-if="true">
      <div class="engAuth_head">
        <p class="engAuth_tit">工程师认证</p>
        <div class="engAuth_infor">
          <p class="warning_icon">
            <img src="../../assets/images/auth/warning.png" alt="" />
          </p>
          <p>1. 为了方便您职业能力认证后的打款，请先完成实名认证</p>
          <p>2. 完成完整职业能力认证流程后，审核通过即可报名项目、获得收益</p>
        </div>
      </div>
      <p class="process_tit">接单流程</p>
      <div class="process_box">
        <p class="process_bg"></p>
<!--        <ul>-->
<!--          <li>-->
<!--            <p><img src="../../assets/images/auth/process_1.png" alt="" /></p>-->
<!--            <p>01查看项目基本信息</p>-->
<!--            <p>查看项目要求，下载附件</p>-->
<!--          </li>-->
<!--          <li>-->
<!--            <p><img src="../../assets/images/auth/process_2.png" alt="" /></p>-->
<!--            <p>02获取项目</p>-->
<!--            <p>-->
<!--              查看<span @click="goorderNotice('1')">《项目须知》</span>和<span-->
<!--                @click="goorderNotice('2')"-->
<!--                >《构件零件编号规范》</span-->
<!--              >-->
<!--            </p>-->
<!--          </li>-->
<!--          <li>-->
<!--            <p><img src="../../assets/images/auth/process_3.png" alt="" /></p>-->
<!--            <p>03完成模型</p>-->
<!--            <p>可以通过项目答疑提问问题</p>-->
<!--          </li>-->
<!--          <li>-->
<!--            <p><img src="../../assets/images/auth/process_4.png" alt="" /></p>-->
<!--            <p>04模型交付</p>-->
<!--            <p>对完成的模型交付</p>-->
<!--          </li>-->
<!--          <li>-->
<!--            <p><img src="../../assets/images/auth/process_5.png" alt="" /></p>-->
<!--            <p>05模型品控</p>-->
<!--            <p>模型如果品控不通过需要修改</p>-->
<!--          </li>-->
<!--          <li>-->
<!--            <p><img src="../../assets/images/auth/process_6.png" alt="" /></p>-->
<!--            <p>06获得收益</p>-->
<!--            <p>所有流程完成后收益会在3个<br />工作日内到账，请注意查收</p>-->
<!--          </li>-->
<!--        </ul>-->
        <ul>
          <li>
            <p><img src="../../assets/images/auth/process_1.png" alt="" /></p>
            <p>01查看项目</p>
            <p>查看项目要求，下载项目附件<br />了解项目结构</p>
          </li>
          <li>
            <p><img src="../../assets/images/auth/process_2.png" alt="" /></p>
            <p>02获取项目</p>
            <p>查看<span @click="goorderNotice('1')">《项目须知》</span></p>
          </li>
          <li>
            <p><img src="../../assets/images/auth/process_3.png" alt="" /></p>
            <p>03项目深化</p>
            <p>项目工程问题可通过项目答疑<br />进行实时反馈</p>
          </li>
          <li>
            <p><img src="../../assets/images/auth/process_4.png" alt="" /></p>
            <p>04项目交付</p>
            <p>线上交付已完成的项目文件</p>
          </li>
          <li>
            <p><img src="../../assets/images/auth/process_5.png" alt="" /></p>
            <p>05项目验收</p>
            <p>将项目问题修改后，重新上传<br />项目文件</p>
          </li>
          <li>
            <p><img src="../../assets/images/auth/process_6.png" alt="" /></p>
            <p>06收益到账</p>
            <p>项目验收完成后，收益会在3个工作日内到账，请注意查收</p>
          </li>
        </ul>
      </div>
      <p class="process_tit">认证项目</p>
      <div class="prolist_con">
        <div class="prolist" style="width: 1320px; margin: 18px auto 0px">
          <div class="prolist_info">
            <div class="infor_con">
              <div class="fixed_4">
                <p class="infor_con_name">
                  <img
                    src="../../assets/images/outsidepro/pro_icon.png"
                    alt=""
                    v-if="deafultData.stepState == 1"
                  />
                  <img
                    src="../../assets/images/outsidepro/pro_icon2.png"
                    alt=""
                    v-else
                  />
                  {{ deafultData.projectCode }}
                </p>
                <div
                  class="infor_type"
                  v-if="
                    deafultData.estimateTime != null &&
                    deafultData.estimateTime != ''
                  "
                  style="width: 130px"
                >
                  <p>预计工作时长：{{ deafultData.estimateTime }}h</p>
                </div>
                <div class="infor_type" v-else>
                  <p>预计工作时长：暂无</p>
                </div>
                <div class="infor_type" style="padding-left: 18px">
                  <p>结构类型：{{ deafultData.realmStr }}</p>
                </div>

                <!-- <p class="release_time">
                  <span
                    v-text="
                      $date.Djs_timeList2(deafultData.notarizeDate, serverTime)
                    "
                  ></span
                  >发布
                </p> -->
              </div>

              <div class="fixed_3" style="width: 300px">
                <div class="dateStr">
                  <p style="color: #000000; font-weight: bold; font-size: 14px">
                    {{ deafultData.exPectDateStr }}
                  </p>
                  <p class="exPectdate_tip">最迟交付时间</p>
                </div>

                <div
                  class="downfile_box"
                  style="margin-top: 20px"
                  v-if="requirelength > 0"
                >
                  <div class="downfile">
                    <!-- <p class="downfile_bg">下载项目要求附件</p> -->
                    <el-popover
                      popper-class="tab_popover"
                      placement="right"
                      width="302"
                      trigger="click"
                    >
                      <div style="padding: 20px 20px" class="require_popper">
                        <p>项目要求</p>
                        <!-- <ul
                            v-for="(item, index) in item.requireList"
                            :key="index"
                          > -->
                        <ul>
                          <li
                            v-for="(item, index) in deafultData.requireList"
                            :key="index"
                          >
                            {{ index + 1 }}、{{ item }}
                          </li>
                        </ul>
                      </div>

                      <p class="downfile_txt pointer" slot="reference">
                        <img
                          src="../../assets/images/outsidepro/required.png"
                          alt=""
                        />
                        项目要求
                      </p>
                    </el-popover>
                  </div>
                </div>
                <div
                  v-else
                  class="norequired"
                  style="text-align: left; margin-top: 23px !important"
                >
                  <img
                    src="../../assets/images/outsidepro/norequired.png"
                    alt=""
                  />无项目要求
                </div>
              </div>

              <div class="fixed_3" style="width: 300px">
                <div class="downfile_box">
                  <div class="downfile" style="margin-top: 90px">
                    <!-- <p class="downfile_bg">下载项目要求附件</p> -->
                    <el-popover
                      popper-class="tab_popover"
                      placement="right"
                      width="600"
                      trigger="click"
                    >
                      <el-table :data="deafultData.projectFileEntities" border>
                        <el-table-column
                          property="fileName"
                          label="名称"
                          width="200"
                        ></el-table-column>
                        <el-table-column
                          property="fileSizeStr"
                          label="大小"
                          width="100"
                        ></el-table-column>
                        <el-table-column
                          property="crtDateStr"
                          label="时间"
                          width="200"
                        ></el-table-column>
                        <el-table-column label="操作" width="">
                          <template slot-scope="scope">
                            <span
                              class="pointer down"
                              style="margin-left: 10px"
                              @click="download(scope.row)"
                              >下载</span
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                      <p class="downfile_txt pointer" slot="reference">
                        <img
                          src="../../assets/images/outsidepro/file.png"
                          alt=""
                        />
                        项目附件
                      </p>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
            <div class="connect">
              <p class="estprice">￥{{ deafultData.outsidePrice }}</p>
              <p class="esttit">预估收益</p>
              <p
                class="connectbut"
                v-if="deafultData.stateFlag == 0"
                @click="isconnectOrder()"
              >
                抢需求
              </p>
              <p class="connectbut" v-else @click="goMyProject()">我的项目</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="engineerAuth_box" style="height: 820px">
      <div class="engAuth_head">
        <p class="engAuth_tit">工程师认证</p>
      </div>

      <div class="engAuth_nodata">
        <p><img src="../../assets/images/auth/nodata.png" alt="" /></p>
        <p>认证功能暂未开放</p>
        <p>敬请期待</p>
      </div>
    </div>
  </div>
</template>

<script>
import Global from "@/assets/js/global";
import streamSaver from "streamsaver";
export default {
  components: {},
  name: "Home",
  data() {
    return {
      proshow: 1,
      prolistlength: 1,
      residueCount: 0,
      inCount: 0,
      deafultData: {},
      chunks: [],
      // 分片下载大小 5MB
      chunkSize: 1024 * 1024 * 5,
      // 文件总大小(需要请求后端获得)
      fileSize: 0,
      chunksNum: 0,
      requirelength: 0,
    };
  },
  created() {
    this.getenginerrPro();
  },
  methods: {
    getenginerrPro() {
      this.$http
        .get("/Api/yw/outsideProjectStandard/getDeafultData")
        .then((res) => {
          this.deafultData = res.data.data;

          this.requirelength = res.data.data.requireList.length;
          console.log(this.requirelength);
        });
    },

    download(row) {
      // 定义 存储所有的分片的数组
      this.chunks = [];
      // 分片下载大小 5MB
      this.chunkSize = 1024 * 1024 * 5;
      // 文件总大小(需要请求后端获得)
      this.fileSize = row.fileSize;
      // 计算分片数量
      this.chunksNum = Math.ceil(this.fileSize / this.chunkSize);
      this.downloadChunkFile(0, row);
    },
    async downloadChunkFile(chunkIdx, row) {
      let start = chunkIdx * this.chunkSize;
      let end = Math.min(start + this.chunkSize - 1, this.fileSize - 1);
      const range = `bytes=${start}-${end}`;
      streamSaver.mitm = Global.serveUrl + "mitm.html?version=2.0.0";
      // 使用 Fetch API 请求文件
      fetch(
        Global.baseURL2 +
          "Api/yw/profile/download?rangeHeader=" +
          range +
          "&path=" +
          row.fileUrl +
          "&fileName=" +
          row.fileName
      ).then((res) => {
        this.chunks.push(res.data);
        // 创建写入流 filename为下载的文件名
        const fileStream = streamSaver.createWriteStream(row.fileName, {
          size: res.headers.get("content-length"),
        });
        const readableStream = res.body;
        if (window.WritableStream && readableStream.pipeTo) {
          return readableStream.pipeTo(fileStream).then(() => {});
        }
        window.writer = fileStream.getWriter();
        const reader = res.body.getReader();
        const pump = () =>
          reader
            .read()
            .then((res) =>
              res.done
                ? window.writer.close()
                : window.writer.write(res.value).then(pump)
            );
        pump();
      });
    },

    isconnectOrder() {
      this.$http
        .post(
          "/Api/yw/outsideProjectStandard/GenerateProject?id=" +
            this.deafultData.id
        )
        .then((res) => {
          if (res.data.result == true) {
            this.$message.success(res.data.data);
            this.getenginerrPro();
            this.goMyProject();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    goorderNotice(type) {
      const { href } = this.$router.resolve({
        path: `/orderNotice`,
        query: { noticeType: type },
      });

      window.open(href, "_blank");
    },
    goMyProject() {
      if(this.deafultData.stateFlag==1){
        this.$router.push("leftNavigation/myProblem");
      }else if(this.deafultData.stateFlag==2){
        this.$router.push("leftNavigation/historyProblem");
      }
    },
  },
};
</script>
<style scoped>
@import "@/assets/css/outsidePro.css";
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  list-style-type: none;
}
.engineerAuth_box {
  width: 1400px;
  height: 726px;
  background: #ffffff;
  margin: 20px auto;
  margin-top: 80px;
}
.engAuth_head {
  padding: 30px 40px;
}
.engAuth_tit {
  width: 107px;
  height: 20px;
  font-size: 20px;
  color: #333333;
  line-height: 30px;
}
.engAuth_infor {
  background: #fffae8;
  border-radius: 4px;
  margin-top: 30px;
  padding: 18px 0px 18px 50px;
  position: relative;
}

.warning_icon {
  position: absolute;
  top: 20px;
  left: 22px;
}

.engAuth_infor p {
  font-size: 14px;
  color: #c8831d;
  line-height: 30px;
}
.process_tit {
  font-weight: bold;
  font-size: 16px;
  color: #7856fd;
  line-height: 30px;
  margin-left: 40px;
  margin-top: 20px;
}
.process_box {
  width: 100%;
  height: 200px;
  margin-top: 20px;
  position: relative;
}

.process_bg {
  width: 1172px;
  height: 6px;
  background: #eaeeff;
  margin: 0 auto;
  position: absolute;
  z-index: 1;
  top: 50px;
  left: calc((100% - 1172px) / 2);
}
.process_box ul {
  width: 100%;
  position: absolute;
  z-index: 2;
}
.process_box ul li {
  width: 16.5%;
  height: 200px;
  float: left;
  text-align: center;
}
.process_box ul li p {
  text-align: center;
}
.process_box ul li p span {
  color: #7856fd;
  cursor: pointer;
}
.process_box ul li p:first-child {
  margin-top: 22px;
}
.process_box ul li p:nth-child(2) {
  font-size: 14px;
  color: #333333;
  margin-top: 32px;
}
.process_box ul li p:nth-child(3) {
  width: 158px;
  font-size: 12px;
  color: #7d8599;
  line-height: 18px;
  margin: 0 auto;
  margin-top: 6px;
}

.engAuth_nodata {
  padding-top: 300px;
}
.engAuth_nodata p {
  text-align: center;
}
.engAuth_nodata p:nth-child(2) {
  font-size: 14px;
  color: #333333;
  line-height: 30px;
}
.engAuth_nodata p:nth-child(3) {
  font-size: 12px;
  color: #7d8599;
}
</style>
