<template>
  <div style="background: #e9e9eb; margin-top: 20px">
    <div class="cliennew_box">
      <div class="cliennew_head">
        <ul class="cliennew_tab">
          <li @click="messageHeader(2)" :class="headerClass1">
            项目消息 <span v-if="noreadlength > 0" class="noreadicon"></span>
          </li>
          <li @click="messageHeader(1)" :class="headerClass2">系统消息</li>
        </ul>

        <div class="cliennew_search">
          <el-input
            class="lia_proname"
            v-model="queryLimit.searchText"
            @input="changestuType()"
            placeholder="根据项目编号查询"
            suffix-icon="el-icon-search"
            style=""
          >
          </el-input>
        </div>
        <div class="cliennew_but">
          <div class="allread pointer" @click="allreadbut">
            <img src="../../assets/images/home/allread.png" alt="" />全部已读
          </div>
          <div class="newsclear pointer" @click="delallnotice">
            <img src="../../assets/images/home/clear.png" alt="" />清空消息
          </div>
        </div>
      </div>

      <div v-if="noticelist.length > 0">
        <div class="cliennew_con">
          <div
            class="cliennew_list"
            v-for="(item, index) in noticelist"
            :key="index"
          >
            <!-- <p class="unread_icon" v-if="item.readStatus == 0"></p> -->
            <p class="cliennew_icon">
              <img
                :src="
                  item.msgType == 1
                    ? require('../../assets/images/home/notice_icon_3.png')
                    : item.msgType == 2
                    ? require('../../assets/images/home/notice_icon_1.png')
                    : item.msgType == 3
                    ? require('../../assets/images/home/notice_icon_2.png')
                    : ''
                "
                alt=""
              />
            </p>

            <div
              class="cliennew_time_box pointer"
              @click="read_but(item.offlineId)"
              v-if="item.readStatus == 0"
            >
              <p class="cliennew_state">{{ item.pushTypeText }}</p>
              <p class="cliennew_txt">
                {{ item.pushContent }}
              </p>
              <p class="cliennew_time">{{ item.crtDateStr }}</p>
            </div>

            <div class="cliennew_time_box clienne_read" v-else>
              <p class="cliennew_state">{{ item.pushTypeText }}</p>
              <p class="cliennew_txt">
                {{ item.pushContent }}
              </p>
              <p class="cliennew_time">{{ item.crtDateStr }}</p>
            </div>

            <p style="clear: both"></p>

            <!-- <div class="new_butbox pointer">
              <div
                @click="read_but(item.offlineId)"
                class="new_but_sign"
                v-if="item.readStatus == 0"
              >
                标记已读
              </div>
              <div
                class="new_but_del pointer"
                @click="delnoticebut(item.offlineId)"
              >
                删除
              </div>
            </div> -->
          </div>
        </div>
        <div class="pagingbox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryLimit.page"
            :page-sizes="pageSizes"
            :page-size="queryLimit.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </div>

      <div v-else>
        <div class="cliennew_con">
          <p style="padding-top: 320px; text-align: center">
            <img src="../../assets/images/home/nonotice_data.png" alt="" />
          </p>
          <p class="nonotice" style="">暂无消息</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      queryLimit: {
        page: 1,
        limit: 6,
        searchText: "",
        type: 2,
      },
      totalCount: 0,
      noticelist: [],
      pageSizes: [6],
      unreadMessageNum: 0,
      headerClass1: "active",
      headerClass2: "",
      type: 2,
      noreadlength: 0,
    };
  },
  created() {
    this.getnoticelist();
    this.getnoreadlen();
  },

  methods: {
    messageHeader(type) {
      if (type == 2) {
        this.headerClass1 = "active";
        this.headerClass2 = "";
        this.type = type;
      } else {
        this.headerClass2 = "active";
        this.headerClass1 = "";
        this.type = type;
      }
      this.queryLimit.type = type;
      this.getnoticelist();
    },
    allreadbut() {
      // this.$router.go(0);

      this.$confirm("是否全部已读", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Api/socket/allRead?type=" + this.type)
            .then((res) => {
              this.getnoticelist();
              this.getnoreadlen();
              this.$parent.unreadMessage();
            });
        })
        .catch(() => {});
      this.$parent.unreadMessage();
    },

    delnoticebut(id) {
      this.$http.delete("/Api/socket/del?id=" + id).then((res) => {
        this.getnoticelist();
        // this.$refs.child.unreadMessage();
      });
    },

    delallnotice() {
      this.$confirm("是否清空消息", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("/Api/socket/clear").then((res) => {
            this.getnoticelist();
            this.getnoreadlen();
          });
        })
        .catch(() => {});
    },

    read_but(id) {
      let ids = [];
      ids.push(id);
      this.$http.post("/Api/socket", ids).then((res) => {
        // this.$refs.child.unreadMessage();
        this.getnoticelist();
        this.getnoreadlen();
      });
    },
    getnoticelist() {
      this.$http.get("/Api/socket", { params: this.queryLimit }).then((res) => {
        this.noticelist = res.data.data.items;
        this.totalCount = res.data.data.total;
        this.$parent.unreadMessage();
      });
    },

    getnoreadlen() {
      this.$http.get("/Api/socket/getNotReadMsgCount?type=1").then((res) => {
        this.noreadlength = res.data.data;
      });
    },

    changestuType() {
      this.queryLimit.page = 1;
      this.getnoticelist();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    handleSizeChange(val) {
      this.queryLimit.limit = val;
      this.getnoticelist();
    },
    // 点击第几页
    handleCurrentChange(val) {
      this.queryLimit.page = val;
      this.getnoticelist();
    },
  },
};
</script>
<style src="@/assets/css/message.css" scoped></style>
