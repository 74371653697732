<template>
  <div>
    <!-- <sidebarNav class="sidebar" /> -->
    <mainContent />
    <!-- <bottom /> -->
  </div>
</template>

<script>
import sidebarNav from "./component/sidebar-nav";
import mainContent from "./component/main-content/index";

// import bottom from "./component/bottom";
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["isSidebarNavCollapse"]),
  },
  components: {
    sidebarNav,
    mainContent,
  },
  methods: {
    unreadMessage() {
      conolse.log("222");
    },
  },
};
</script>

<style  scoped>
* {
  margin: 0px;
  padding: 0px;
}
.navCollapsed .sidebar {
  width: 70px;
}
.navCollapsed .main-container {
  width: calc(100% - 80px);
}

.sidebar {
  width: 200px;
  float: left;
  margin-top: 60px;
}
.main-container {
  width: 100%;
  float: left;
}
</style>
