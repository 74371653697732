<template>
  <div class="viewnewpro">
    <div style="padding-bottom: 20px">
      <div class="newpro_left" style="margin-top: 20px">
        <div class="newpro_left_head">
          <div class="rolecon">
            <img
              class="rolecon_icon"
              id="avatar-img"
              :src="userImg + '?t=' + new Date().getTime()"
              alt=""
            />
            <p class="rolecon_name">{{ userStageName }}</p>

            <p class="rolecon_authen" v-if="isCredentials == 1">已完成认证</p>
            <p class="continue_authen pointer" @click="goRenZheng()" v-else>
              未完成认证
            </p>
          </div>
          <div
            class="rolenav_list"
            v-for="(item, index) in navData"
            :key="index"
            :class="[item.path == currpath ? 'nav_active' : '']"
            @click="navigationpath(item.path, index, item.type)"
          >
            <p class="rolenav_name">
              <img :src="item.img" alt="" />{{ item.name }}
            </p>
            <p class="rolenav_path">
              <img src="../../assets/images/outsidepro/path.png" alt="" />
            </p>
          </div>
          <!-- <div class="rolenav_con">
            <div class="rolenav_list" @click="navigationpathauth('')">
              <p class="rolenav_name">
                <img
                  src="../../assets/images/outsidepro/user.png"
                  alt=""
                />个人信息
              </p>
              <p class="rolenav_path">
                <img src="../../assets/images/outsidepro/path.png" alt="" />
              </p>
            </div>
          </div> -->
        </div>
      </div>
      <div class="left_content">
        <!-- <router-view class="content"></router-view> -->
        <!-- :key="$route.fullPath" -->
        <!-- <keep-alive>
          <router-view></router-view>
        </keep-alive> -->
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
      <p style="clear: both"></p>
    </div>
  </div>
</template>

<script>
import Global from "@/assets/js/global";
export default {
  data() {
    return {
      isCredentials: 0,
      userImg: Global.baseuserURL + window.sessionStorage.getItem("userImg"),
      userStageName: window.sessionStorage.getItem("userStageName"),
      navData: [
        {
          path: "projectDetail",
          name: "首页",
          img: require("@/assets/images/home/leftnav_home.png"),
          type: "",
        },
        {
          path: "OutsideProcard",
          name: "项目大厅",
          img: require("@/assets/images/home/leftnav_home.png"),
          type: "2",
        },
        {
          path: "myProblem",
          name: "我的项目",
          img: require("@/assets/images/home/leftnav_mypro.png"),
          type: "",
        },
        {
          path: "historyProblem",
          name: "历史项目",
          img: require("@/assets/images/home/leftnav_his.png"),
          type: "",
        },
        {
          path: "projectQuestion",
          name: "项目答疑",
          img: require("@/assets/images/home/leftnav_que.png"),
          type: "",
        },
        {
          path: "fileCenter",
          name: "文件中心",
          img: require("@/assets/images/home/leftnav_file.png"),
          type: "",
        },
        {
          path: "earningsView",
          name: "收益详情",
          img: require("@/assets/images/home/leftnav_detail.png"),
          type: "",
        },
        {
          path: "authenticat",
          name: "个人信息",
          img: require("@/assets/images/home/leftnav_person.png"),
          type: "1",
        },
      ],
      navactiveindex: 0,
      currpath: "projectDetail",
    };
  },

  created() {
    this.isCredentials = window.sessionStorage.getItem("isCredentials");

    this.currpath = this.$route.path.split("/")[2];
  },

  methods: {
    navigationpath(path, index, type) {
      this.navactiveindex = index;
      this.currpath = path;
      if (type == "") {
        this.$router.push(path);
      } else if (type == "2") {
        console.log("2222");
        this.$router.push({ path: "/OutsideProcard" });
      } else {
        this.$router.push({
          path: "authenticat",
          query: { authenType: type },
        });
      }
    },

    changeuserinfor() {
      this.userImg = "";
      this.userImg =
        Global.baseuserURL + window.sessionStorage.getItem("userImg");
      this.userStageName = window.sessionStorage.getItem("userStageName");
      this.isCredentials = window.sessionStorage.getItem("isCredentials");
      this.$parent.changeuserimg();
    },

    changenavact() {
      // this.$router.push("/leftNavigation/outsidePro");
      this.currpath = this.$route.path.split("/")[2];
    },

    goRenZheng() {
      this.$router.push({
        path: "/leftNavigation/authenticat",
        query: { authenType: 2 },
      });
    },
  },
};
</script>

<style  scoped>
@import "@/assets/css/projectQuestion.css";
.newpro_left {
  width: 352px;
  background: #ffffff;
  float: left;
  padding-bottom: 20px;
  margin-left: 200px;
  border-radius: 8px;
  /* position: fixed;
  min-height: 600px;
  left: 200px;
  top: 80px; */
}
.el-range-editor .el-range-input {
  background-color: RGB(245, 246, 250) !important;
  line-height: 1 !important;
}
.head_sel {
  width: 1120px;
  margin: 10px auto;
  height: 60px;
}
.sel_left {
  float: left;
}
.left_content {
  /* width: calc(100% - 582px); */
  float: left;
  width: 1120px;
  margin-left: 20px;
  /*margin-left: 580px;
  margin-top: 100px; */
}
.rolenav_list:hover {
  background: #fbfaff;
}
.nav_active {
  background: #fbfaff;
}
.nav_active .rolenav_name {
  color: #7856fd;
}
</style>