const baseURL = "https://zzyjzkj.com:7004/images/";
const baseURL2 = "https://zzyjzkj.com:7004/";
const baseuserURL = "https://zzyjzkj.com:7004/images/inside/perlogo/";
const downloadUrl = "https://zzyjzkj.com:7004/Api/yw/profile/download";
const serveUrl = "https://zzyjzkj.com:443/";

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    let fileResult = ''
    reader.readAsDataURL(file)
    // 开始转
    reader.onload = () => { fileResult = reader.result }
    // 转 失败
    reader.onerror = error => { reject(error) }
    // 转 结束  咱就 resolve 出去
    reader.onloadend = () => { resolve(fileResult) }
  })
};

export default {
  baseURL,
  baseURL2,
  baseuserURL,
  getBase64,
  downloadUrl,
  serveUrl
};
