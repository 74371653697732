<template>
  <div class="hello" style="width: 100%; background: #fff">
    <div class="useterm_box">
      <ul class="useterm_head">
        <li :class="usetermtype == 1 ? 'active pointer' : 'pointer'" @click="getuserAgtype(1)">
          用户服务协议
        </li>
        <li :class="usetermtype == 2 ? 'active pointer' : 'pointer'" @click="getuserAgtype(2)">
          个人信息保护
        </li>
      </ul>

      <div
        class="useterm_con"
        style="padding: 20px 0px"
        v-if="usetermtype == 1"
      >
        <userAg></userAg>
      </div>

      <div
        class="useterm_con"
        style="padding: 20px 0px"
        v-if="usetermtype == 2"
      >
        <inforProtect></inforProtect>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../utils/http";
import userAg from "./component/userAgreement";
import inforProtect from "./component/personinforprotect";
export default {
  components: {
    userAg,
    inforProtect,
  },
  name: "Home",
  props: {
    msg: String,
  },
  data() {
    return {
      usetermtype: 1,
    };
  },
  created() {
    this.usetermtype = this.$route.query.type;
  },
  methods: {
    getuserAgtype(type) {
      this.usetermtype = type;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "@/assets/css/webUseterm.css";
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
