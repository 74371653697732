let serverTime = new Date().getTime();
export default {
  Djs_time (time) {
    let _this = this;
    return setInterval(() => {
      time = time + 1000;
    }, 1000);
  },
  Djs_timeList (time, endItem, state) {
    //  此处 itemEnd 的日期是年月日时分秒
    var rightTime = endItem - time; //截止时间减去当前时间
    if (rightTime > 0) {
      //判断剩余倒计时时间如果大于0就执行倒计时否则就结束
      var dd = Math.floor(rightTime / 1000 / 60 / 60 / 24);
      var hh = Math.floor((rightTime / 1000 / 60 / 60) % 24);
      var mm = Math.floor((rightTime / 1000 / 60) % 60);
      var ss = Math.floor((rightTime / 1000) % 60);

      if (state == 1) {
        var dtime = dd * 24
        var htime = dtime + hh
        var showTime = htime + "小时" + mm + "分" + ss + "秒";
      } else {
        var showTime = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
      }
    } else {
      var showTime = "";
    }
    return showTime;
  },


  formatDate: function (value, args) {
    var dt = new Date(value);
    if (args == 'yyyy-M-d') {// yyyy-M-d2 
      let year = dt.getFullYear();
      let month = dt.getMonth() + 1;
      let date = dt.getDate();
      return `${year}-${month}-${date}`;
    } else if (args == 'yyyy-M-d H:m:s') {// yyyy-M-d H:m:s
      let year = dt.getFullYear();
      let month = dt.getMonth() + 1;
      let date = dt.getDate();
      let hour = dt.getHours();
      let minute = dt.getMinutes();
      let second = dt.getSeconds();
      return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
    } else if (args == 'HH:mm:ss') {
      let hour = dt.getHours().toString().padStart(2, '0');
      let minute = dt.getMinutes().toString().padStart(2, '0');
      let second = dt.getSeconds().toString().padStart(2, '0');
      return `${hour}:${minute}:${second}`;

    } else if (args == 'yyyy-MM-dd') {// yyyy-MM-dd
      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString().padStart(2, '0');
      let date = dt.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${date}`;
    } else {// yyyy-MM-dd HH:mm:ss







      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString().padStart(2, '0');
      let date = dt.getDate().toString().padStart(2, '0');
      let hour = dt.getHours().toString().padStart(2, '0');
      let minute = dt.getMinutes().toString().padStart(2, '0');
      let second = dt.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
    }
  },

  Djs_timeList2 (time, newtime, state) {
    //  此处 itemEnd 的日期是年月日时分秒
    var rightTime = newtime - time; //截止时间减去当前时间
    if (rightTime > 0) {
      //判断剩余倒计时时间如果大于0就执行倒计时否则就结束
      var dd = Math.floor(rightTime / 1000 / 60 / 60 / 24);
      var hh = Math.floor((rightTime / 1000 / 60 / 60) % 24);
      var mm = Math.floor((rightTime / 1000 / 60) % 60);
      // var ss = Math.floor((rightTime / 1000) % 60);
      if (dd == 0) {
        if (hh == 0) {
          var showTime = mm + "分钟前"
        } else {
          var showTime = hh + "小时前";
        }
      } else if (dd == 1) {
        var showTime = "昨天";
      } else if (dd == 2) {
        var showTime = "前天"
      } else if (dd > 2) {
        var showTime = dd + "天前"
      }
    } else {
      var showTime = "";
    }
    return showTime;
  },

  Djs_timeList3 (time, endtime,) {
    //  此处 itemEnd 的日期是年月日时分秒
    var realJian = endtime - time; //截止时间减去当前时间

    var rightTime = new Date(this.formatDate(endtime, 'yyyy-M-d')).getTime() - new Date(this.formatDate(time, 'yyyy-M-d')).getTime(); //截止时间减去当前时间
    if (realJian > 0) {
      //判断剩余倒计时时间如果大于0就执行倒计时否则就结束
      var dd = Math.floor(rightTime / 1000 / 60 / 60 / 24);
      var hh = Math.floor((rightTime / 1000 / 60 / 60) % 24);
      var mm = Math.floor((rightTime / 1000 / 60) % 60);
      // var ss = Math.floor((rightTime / 1000) % 60);
      if (dd == 0) {
        var showTime = "今天";
      } else if (dd == 1) {
        var showTime = "明天";
      } else if (dd == 2) {
        var showTime = "后天"
      } else if (dd > 2) {
        var showTime = dd + "天后"
      }
    } else {
      var showTime = "尽快";
    }
    return showTime;
  },


  getDjs (project, time) {

    var hour = 0;
    var startime = 0;
    project.str = "";
    if (project.projectTaskState === -4) {
      // 结束时间
      hour = project.crtDate + 3600 * 1000 * 12;
      startime = project.crtDate;
      if (hour > time && startime < time) {
        var wxdTime = hour - time;
        if (wxdTime > 0) {
          project.str = "后订单将会被取消";
          project.endDate = hour;
          project.overtime = 0
        } else {
          project.overtime = 1
        }
      }
    } else if (project.projectTaskState === 1) {
      // 结束时间
      hour = project.projectTaskFinishDate + 3600 * 1000 * 24 * 7;
      startime = project.projectTaskFinishDate;
      if (hour > time && startime < time) {
        var hourtime = hour - time;
        if (hourtime > 0) {
          project.str = "评价有效期还剩";
          project.endDate = hour;
        }
      }
    }
    return project;
  },
  getFileType (fileName) {
    // 根据后缀判断文件类型
    let fileSuffix = "";
    // 结果
    let result = "";
    try {
      let flieArr = fileName.split(".");
      fileSuffix = flieArr[flieArr.length - 1];
    } catch (err) {
      fileSuffix = "";
    }
    // fileName无后缀返回 false
    if (!fileSuffix) {
      result = false;
      return result;
    }
    // 图片格式
    let imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
    // 进行图片匹配
    result = imglist.some(function (item) {
      return item == fileSuffix;
    });
    if (result) {
      result = "image";
      return result;
    }
    // 匹配txt
    let txtlist = ["txt"];
    result = txtlist.some(function (item) {
      return item == fileSuffix;
    });
    if (result) {
      result = "txt";
      return result;
    }
    // 匹配 excel
    let excelist = ["xls", "xlsx"];
    result = excelist.some(function (item) {
      return item == fileSuffix;
    });
    if (result) {
      result = "excel";
      return result;
    }
    // 匹配 word
    let wordlist = ["doc", "docx"];
    result = wordlist.some(function (item) {
      return item == fileSuffix;
    });
    if (result) {
      result = "word";
      return result;
    }
    // 匹配 pdf
    let pdflist = ["pdf"];
    result = pdflist.some(function (item) {
      return item == fileSuffix;
    });
    if (result) {
      result = "pdf";
      return result;
    }
    // 匹配 ppt
    let pptlist = ["ppt"];
    result = pptlist.some(function (item) {
      return item == fileSuffix;
    });
    if (result) {
      result = "ppt";
      return result;
    }
    // 匹配 视频
    let videolist = ["mp4", "m2v", "mkv"];
    result = videolist.some(function (item) {
      return item == fileSuffix;
    });
    if (result) {
      result = "video";
      return result; 看
    }
    // 匹配 音频
    let radiolist = ["mp3", "wav", "wmv"];
    result = radiolist.some(function (item) {
      return item == fileSuffix;
    });
    if (result) {
      result = "radio";
      return result;
    }
    // 其他 文件类型
    result = "other";
    return result;
  },
};
