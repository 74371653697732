<template>
  <div style="margin-top: 60px">
    <router-view class="content" ref="Childmain"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    unreadMessage() {
      this.$parent.unreadMessage();
    },
    changeuserimg() {
      this.$parent.changeuserimg();
    },
    routeract() {
      this.$nextTick(() => {
        this.$refs["Childmain"].changenavact();
      });
    },
  },
};
</script>

<style  scoped>
</style>
