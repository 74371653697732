<template>
  <div id="depart">
    <!-- 内容部分 -->
    <div style="margin-top: 20px" class="join_Project">
      <div class="filecenter_form head_sel">
        <el-form :inline="true" :model="queryLimit" class="demo-form-inline">
          <el-form-item style="">
            <el-date-picker
              type="daterange"
              range-separator="至"
              v-model="rangeDate"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions0"
              @change="changeState()"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="" style="width: 260px">
            <div class="pro_search" :class="blurborder">
              <el-input
                v-model="content"
                placeholder="根据项目编号查询"
                suffix-icon="el-icon-search"
                @input="changeState()"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="joinPro_con">
        <div class="joinPro_tab" v-if="fileListlen > 0">
          <template>
            <el-table
              :data="fileList"
              border
              style="width: 1120px"
              show-summary
              :summary-method="getSummaries"
            >
              <el-table-column
                prop="projectTaskPojo.projectCode"
                label="项目编号"
                width="150"
              >
              </el-table-column>
              <el-table-column
                prop="projectInfoEndDateStr"
                label="项目确认时间"
                width="190"
              >
              </el-table-column>
              <el-table-column prop="outsidePrice" label="金额" width="120">
              </el-table-column>
              <el-table-column prop="isPay" label="状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.isPay == 1">已到账</span>
                  <span v-else style="color: red">待入账</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="payDateStr"
                label="到账记录时间"
                width="190"
              >
              </el-table-column>
            </el-table>
          </template>

          <div class="pagingbox" style="margin-top: 30px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryLimit.page"
              :page-sizes="pageSizes"
              :page-size="queryLimit.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
        <div v-else class="history_nodata">
          <p style="text-align: center; padding-top: 300px">
            <img src="../../assets/images/nodata/sy_nodata.png" alt="" />
          </p>
          <p style="text-align: center; font-size: 14px">暂无收益数据</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      pickerOptions0: {
        //设置选择今天以及今天之后的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      content: "",
      pageSizes: [10, 50, 100],
      queryLimit: {
        page: 1,
        limit: 10,
        date: "",
        searchText: "",
      },
      rangeDate: "",
      blurborder: "",
      fileList: [],
      totalCount: 0,
      fileSourceCombox: [],
      usertype: 1,
      bodyclass: "bodyopen",
      fileListlen: 1,
    };
  },
  created() {
    this.getList();
  },

  methods: {
    //获取集合
    getList() {
      this.$http
        .get("Api/yw/outsideOrder/getMyEarnings", { params: this.queryLimit })
        .then((res) => {
          this.fileList = res.data.data;
          this.fileListlen = res.data.data.length;
          this.totalCount = res.data.count;
        });
    },

    handleClose(done) {
      this.$refs.modal.style.display = "none";
      done();
    },

    changeState() {
      this.queryLimit.page = 1;
      this.queryLimit.searchText = this.content;
      if (this.rangeDate != null && this.rangeDate.length > 0) {
        this.queryLimit.date = this.rangeDate[0] + " ~ " + this.rangeDate[1];
      } else {
        this.queryLimit.date = "";
      }
      this.getList();
    },

    handleSizeChange(val) {
      this.queryLimit.limit = val;
      this.getList();
    },
    // 点击第几页
    handleCurrentChange(val) {
      this.queryLimit.page = val;
      this.getList();
    },

    focussearch() {
      this.blurborder = "searchblur";
    },

    blursearch() {
      this.blurborder = "";
    },
    getCollapse(val) {
      if (val == false) {
        this.bodyclass = "bodyopen";
      } else {
        this.bodyclass = "bodyclose";
      }
    },
    //指定列求和
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (column.property === "outsidePrice") {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index];
        }
      });
      return sums;
    },
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      // let am = h < 12 ? "AM" : "PM";
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      return y + "-" + MM + "-" + d + "  " + h + ":" + m;
    },
    formatDay: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    },
  },
};
</script>

<style>
.searchblur {
  border: 1px solid #3d7eff !important;
}

.head_sel {
  height: 60px;
  /* background-color: #fff; */
}

.sel_left {
  padding-top: 10px;
  padding-bottom: 10px;
  float: left;
}
</style>
